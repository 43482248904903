<template>
    <div class="lcright_1">
      <div class="lcright-01_1" v-for="(e, i) of entranceList" :key="e.title">
          <div class="lcright-02_1">
              <div class="main-04_1 linknav nav-z_1">
                  <router-link to="/homeguide">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">{{e.title}}</font>
              </div>
          </div>
          <div class="lcright-03_1">
                	<div class="zy_about_1 nr-z2_1">
                    	<p><p v-html="e.content" router-link :to="map[i]">
	<!-- <a href="http://www.csia.org.cn/">中国软件行业协会</a> -->
</p>
<!-- <p>
	<a href="http://www.ouchn.edu.cn/">国家开放大学</a></p>
<p>
	<a href="http://101.201.223.183/">国家开放大学</a><a href="http://101.201.223.183/">软件学院学习平台</a></p>
<p>
	<a href="http://ouchn.cjnep.net/">国家开放大学学习平台</a></p> -->
</p>
                    </div>
                </div>
            </div>
      </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            entranceList:[
               {
                 title: '1',
                 content: '',
                 id: '',
               },
            ],
            map:[
                'http://www.csia.org.cn/',
                'http://www.ouchn.edu.cn/',
                'http://101.201.223.183/',
                'http://ouchn.cjnep.net/',
            ] 
       }
    },
  methods: {
    async getEntList(){
      const res = await consult.getEntList(5, 1)
      console.log(res,'entrance')
      const list = res.data.data.list.records
      this.entranceList = list.map(entrance => {
        const {entranceTitle, entranceId, entranceContent} = entrance
        return {
          title: entranceTitle.substring(0,4),
          id: entranceId,
          content: entranceContent,
        }
      })
    },
  },
  mounted() {
    this.getEntList()
  }
  }
</script>

<style>
.lcright_1 {
    float: left; 
    width:1000px;
    margin-top: 200px;
   }
.lcright-01_1 {margin-right:10px;}
.lcright-02_1 {border-bottom: 1px solid #dadada;
       height: 54px;
       position: relative;
       width: 1000px;
      }
.main-04_1 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z_1 {color: #898989;font-size: 12px;}
.lcright-03_1 {margin: 10px 10px 81px 0;
       /* width: 1000px;
       position: relative; */
      }
.zy_about_1 {line-height: 2em;
     }
.zy_about_1 p{ 
     margin-bottom:10px;
     margin-left: auto;
     }
.nr-z2_1 {
  color: #353535;
  font-size: 13px;
  width: 1000px;
  }
</style>