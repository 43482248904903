<template>
  <div class="certer">
    <div class="lcright-02">
      <div class="main-04 linknav nav-z">
        <router-link to="/index">首页</router-link
        >&nbsp;&gt;&nbsp;非学历教育&nbsp;&gt;&nbsp;<font color="blue"
          >授权培训中心</font
        >
      </div>
    </div>
    <div class="lcright-03">
      <div class="zy_news2 nr-z2">
        <ul>
          <li v-for="sq of SqList" :key="sq.id">
            <div class="newspic2 fl2">
              <router-link class="Zzl" :to="sqpxUrl + sq.id">
                <img :src="sq.img" width="93" height="75" />
              </router-link>
            </div>
            <div class="newstext2 fr2">
              <h2>
                <router-link class="Zzl" :to="sqpxUrl + sq.id"
                  >{{ sq.title }}...</router-link
                >
              </h2>
              <p>
                <router-link
                  class="Zzl"
                  :to="sqpxUrl + sq.id"
                  v-html="sq.content"
                ></router-link>
              </p>
              <p class="newstextmore2">
                <router-link class="Zzl" :to="sqpxUrl + sq.id">{{
                  sq.date
                }}</router-link>
              </p>
            </div>
          </li>
          <!-- <li>
            <div class="newspic2 fl2">
              <router-link class="Zzl2" to="Zzl2">
                <img src="" width="93" height="75" />
              </router-link>
            </div>
            <div class="newstext2 fr2">
              <h2><router-link class="Zzl2" to="Zzl2">正在整理中...</router-link></h2>
              <p><router-link class="Zzl2" to="Zzl2"></router-link></p>
              <p class="newstextmore2">
                <router-link class="Zzl2" to="Zzl2">2014-12-11</router-link>
              </p>
            </div>
         </li> -->
        </ul>
      </div>
      <Paning :total="total" :count="count"></Paning>
    </div>
  </div>
</template>

<script>
import Paning from '@/components/Paning.vue'
import consult from '@/api/yssz/consult'
export default {
  components: {
    Paning,
  },
  data() {
    return {
      SqList: [
        {
          title: '1',
          content: '2022',
          id: '',
          date: '',
          img: '',
        },
      ],
      count: '15',
      page: '1',
      sqpxUrl: '/center/',
    }
  },
  computed: {
    pageList() {
      return this.SqList.slice(
        this.count * (this.page - 1),
        this.count * this.page
      )
    },
    total() {
      return this.SqList.length
    },
  },
  watch: {
    '$route.query.page'() {
      this.page = this.$route.query.page
      if (!this.page) {
        this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      }
    },
  },
  methods: {
    idToRouter(id) {
      return '/center/' + id
    },
    async getSqpxList() {
      const res = await consult.getSqpxList(1, 3)

      const list = res.data.data.list.records
      if (!this.$route.query.page)
        this.$router.replace({ query: { ...this.$route.query, page: 1 } })

      this.SqList = list.map((it) => {
        const {
          alwaysTitle,
          alwaysContent,
          alwaysId,
          createTime,
          alwaysCover,
        } = it
        let el = document.createElement('div')
        el.innerHTML = alwaysContent
        const content = Array.from(el.getElementsByTagName('p'))
          .map((it) => it.innerText)
          .join(' ')

        return {
          title: alwaysTitle,
          content,
          id: alwaysId,
          date: createTime.split('T')[0],
          img: alwaysCover,
        }
      })
    },
  },
  mounted() {
    this.getSqpxList()
  },
}
</script>

<style lang="scss" scoped>
.lcright-02 {
  border-bottom: 1px solid #dadada;
  height: 54px;
  position: relative;
  width: 725px;
}
.main-04 {
  height: 22px;
  line-height: 22px;
  position: absolute;
  right: 4px;
  text-align: right;
  top: 25px;
  width: 500px;
  z-index: 1;
}
.nav-z {
  color: #898989;
  font-size: 12px;
}
.lcright-03 {
  margin: 10px 10px 81px 0;
  width: 715px;
}
.zy_news2 {
  line-height: 2em;
  width: 715px;
}
.zy_news2 li {
  height: 120px;
  padding-top: 20px;
  border-bottom: 1px solid #ebebeb;
}
.zy_news2 li .newspic2 {
  width: 93px;
}
.zy_news2 li .newspic2 img {
  width: 93px;
  height: 75px;
}
.zy_news2 li .newstext2 {
  width: 605px;
  line-height: 23px;
  margin: -87px 0;
}
.zy_news2 li .newstext2 a {
  color: #888;
}
.zy_news2 li .newstext2 h2 {
  line-height: 24px;
}
.zy_news2 li .newstext2 h2 a {
  font-size: 13px;
  font-weight: bold;
  color: #666;
}
.zy_news2 li .newstextmore2 a {
  color: blue;
}
.zy_news2 li .newstextmore2 a:hover {
  text-decoration: underline;
  //  border-bottom: 1px solid #666;
  //  padding-bottom: 100px;
}
.nr-z2 {
  color: #353535;
  font-size: 13px;
}
.fr2 {
  float: right;
}
DIV.fanye {
  clear: both;
  margin: 35px 0px 18px 0px;
  *margin: 25px 0px 35px 0px;
  display: block;
  overflow: hidden;
  _zoom: 1;
  height: 23px;
  line-height: 23px;
  text-align: center;
  width: 100%;
}
// DIV.fanye A {background: #e6e6e6;color: #333;margin-right: 8px;padding:8px 10px;text-decoration: none;}
// DIV.fanye A:hover {background:#c80000;color: #fff;margin-right: 8px;}
// DIV.fanye A:active {background:#c80000;color: #fff;margin-right: 8px;}
DIV.fanye SPAN.current {
  background: blue;
  color: #fff;
  font-weight: bold;
  margin-right: 8px;
  padding: 8px 10px;
}
DIV.fanye SPAN.disabled {
  color: black;
  margin-right: 8px;
  padding: 8px 10px;
}
</style>
