<template>
  <div class="yuanxiao">
    <div class="lcright-02">
      <div class="main-04 linknav nav-z">
        <router-link to="/index">首页</router-link
        >&nbsp;&gt;&nbsp;新闻中心&nbsp;&gt;&nbsp;<font color="blue"
          >院校新闻</font
        >
      </div>
    </div>
    <div class="lcright-03">
      <div class="zy_news2 nr-z2">
        <ul>
          <li v-for="i of NewsList" :key="i.id">
            <div class="newspic2 fl2">
              <router-link class="Zzl" :to="newsUrl + i.id">
                <img :src="i.img" width="93" height="75" />
              </router-link>
            </div>
            <div class="newstext2 fr2">
              <h2>
                <router-link class="Zzl" :to="newsUrl + i.id">{{
                  i.title
                }}</router-link>
              </h2>
              <p>
                <router-link
                  :to="newsUrl + i.id"
                  v-html="i.content"
                  style="color: #666"
                ></router-link>
              </p>
              <p class="newstextmore2">
                <router-link class="Zzl" :to="newsUrl + i.id">{{
                  i.date
                }}</router-link>
              </p>
            </div>
          </li>
          <!-- <li>
            <div class="newspic2 fl2">
              <router-link class="Zzl2" to="Zzl2">
                <img src="" width="93" height="75" />
              </router-link>
            </div>
            <div class="newstext2 fr2">
              <h2>
                <router-link class="Zzl2" to="Zzl2">正在整理中...</router-link>
              </h2>
              <p><router-link class="Zzl2" to="Zzl2"></router-link></p>
              <p class="newstextmore2">
                <router-link class="Zzl2" to="Zzl2">2014-12-11</router-link>
              </p>
            </div>
          </li> -->
        </ul>
      </div>
      <div class="fanye">
        <span class="disabled"> < </span>
        <span class="current">1</span>
        <span class="disabled">></span>
      </div>
    </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
export default {
  data() {
    return {
      NewsList: [
        {
          title: '1',
          content: '2022',
          id: '',
          date: '',
          img: '',
        },
      ],
      newsUrl: '/yuanxiao/',
    }
  },
  methods: {
    idToRouter(id) {
      return '/yuanxiao/' + id
    },
    async getNewsList() {
      const res = await consult.getNewsList(1, 3)
      console.log(res, '999')
      const list = res.data.data.list.records
      this.NewsList = list.map((it) => {
        const { pressTitle, pressContent, pressId, createTime, pressCover } = it
        let el = document.createElement('div')
        el.innerHTML = pressContent
        const content = Array.from(el.getElementsByTagName('p'))
          .map((it) => it.innerText)
          .join(' ')
        console.log(el.getElementsByTagName('p'), 'sjdf', pressContent)
        return {
          title: pressTitle,
          content,
          id: pressId,
          date: createTime.split('T')[0],
          img: pressCover,
        }
      })
    },
  },
  mounted() {
    this.getNewsList()
  },
}
</script>

<style lang="scss" scoped>
.lcright-02 {
  border-bottom: 1px solid #dadada;
  height: 54px;
  position: relative;
  width: 725px;
}
.main-04 {
  height: 22px;
  line-height: 22px;
  position: absolute;
  right: 4px;
  text-align: right;
  top: 25px;
  width: 500px;
  z-index: 1;
}
.nav-z {
  color: #898989;
  font-size: 12px;
}
.lcright-03 {
  margin: 10px 10px 81px 0;
  width: 715px;
}
.zy_news2 {
  line-height: 2em;
  width: 715px;
}
.zy_news2 li {
  height: 120px;
  padding-top: 20px;
  border-bottom: 1px solid #ebebeb;
}
.zy_news2 li .newspic2 {
  width: 93px;
}
.zy_news2 li .newspic2 img {
  width: 93px;
  height: 75px;
}
.zy_news2 li .newstext2 {
  width: 605px;
  line-height: 23px;
  margin: -87px 0;
}
.zy_news2 li .newstext2 p {
  color: #888;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.zy_news2 li .newstext2 h2 {
  line-height: 24px;
}
.zy_news2 li .newstext2 h2 a {
  font-size: 13px;
  font-weight: bold;
  color: #666;
}
.zy_news2 li .newstextmore2 a {
  color: blue;
}
.zy_news2 li .newstextmore2 a:hover {
  text-decoration: underline;
  //  border-bottom: 1px solid #666;
  //  padding-bottom: 100px;
}
.nr-z2 {
  color: #353535;
  font-size: 13px;
}
.fr2 {
  float: right;
}
DIV.fanye {
  clear: both;
  margin: 35px 0px 18px 0px;
  *margin: 25px 0px 35px 0px;
  display: block;
  overflow: hidden;
  _zoom: 1;
  height: 23px;
  line-height: 23px;
  text-align: center;
  width: 100%;
}
// DIV.fanye A {background: #e6e6e6;color: #333;margin-right: 8px;padding:8px 10px;text-decoration: none;}
// DIV.fanye A:hover {background:#c80000;color: #fff;margin-right: 8px;}
// DIV.fanye A:active {background:#c80000;color: #fff;margin-right: 8px;}
DIV.fanye SPAN.current {
  background: blue;
  color: #fff;
  font-weight: bold;
  margin-right: 8px;
  padding: 8px 10px;
}
DIV.fanye SPAN.disabled {
  color: black;
  margin-right: 8px;
  padding: 8px 10px;
}
</style>
