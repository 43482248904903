<template>
    <div class="lcright9">
      <div class="lcright-019" v-for="e of entranceList" :key="e.title">
          <div class="lcright-029">
              <div class="main-049 linknav nav-z9">
                  <router-link to="/homeguide">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">{{e.title}}</font>
              </div>
          </div>
          <div class="lcright-039">
                	<div class="zy_about9 nr-z29">
                    	<p><p>
	<span v-html="e.content" style="font-size:16px;"></span>
</p>
</p>
<!-- <p>
	&nbsp;</p>
<table border="0" cellpadding="0" cellspacing="0" style="width:755px;" width="755">
	<tbody>
		<tr>
			<td style="width:30px;height:43px;">
				<p>
					<strong>序号</strong></p>
			</td>
			<td style="width:150px;height:43px;">
				<p>
					<strong>授权学习中心</strong></p>
			</td>
			<td style="width:111px;height:43px;">
				<p>
					<strong>报名咨询电话</strong></p>
			</td>
			<td style="width:291px;height:43px;">
				<p>
					<strong>报名地址</strong></p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					&nbsp; 1</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					泉州南洋学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0595-22112029</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					福建省泉州市南俊路承天巷85号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					&nbsp; 2</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					武汉弘立学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					13886122239</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					湖北省武汉市洪山区武汉理工大学升升公寓</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					&nbsp; 3</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					合肥新航学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0551-68998953</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					安徽省合肥市金寨南路翡翠商业街建海大厦B座21楼</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					&nbsp; 4</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					贵阳奥太孚格学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0851-85951660</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					贵州省贵阳市贵安新区大学城数字经济产业园2号楼17层</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					&nbsp; 5</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					福州蓝桥学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0591-83180888</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					福建省福州市仓山区潘墩路188号闽江世纪城创富中心1701室</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					&nbsp; 6</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					广西学中学学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0771-2376623</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					广西南宁市良庆区金龙路2号南宁万科大厦3栋4栋429号-434号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					&nbsp; 7</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					苏州高盛学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0512-66396404</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					江苏省苏州市姑苏区人民路708号苏大南区2号楼</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:34px;">
				<p>
					&nbsp; 8</p>
			</td>
			<td style="width:123px;height:34px;">
				<p>
					内蒙北方联合学习中心</p>
			</td>
			<td style="width:111px;height:34px;">
				<p>
					0471-2555518</p>
			</td>
			<td style="width:291px;height:34px;">
				<p>
					内蒙古呼和浩特市锡林南路闻都城市广场17F座1312</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					&nbsp; 9</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					河北青年学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0311-85853307</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					河北省桥西区红旗大街398号青年学校3楼</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					10</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					德州方圆学心中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0534-2715959</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					山东省德州市德城区中原街28号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					11</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					厦门哈德学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0592-2121030</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					福建省厦门市思明区同安路2号鑫明大厦8层</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					12</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					汕头圆梦学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0754-88625294</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					广东省汕头市龙湖区丽水庄西区40栋401</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					13</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					青岛新视点学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0532-85828509</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					山东省青岛市黄岛区青岛市西海岸海坛岛路7号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					14</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					吉安博乐学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0796-7063391</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					江西省吉安市吉州区安居路5号三楼</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					15</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					长春思学学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					13911088489</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					吉林省长春市卫星路远创国际1403室</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					16</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					深圳跨世纪学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0755-27406763</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					深圳市福田区八卦岭汉富中心大厦541栋二楼盛世明德教育&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:19px;">
				<p>
					17</p>
			</td>
			<td style="width:123px;height:19px;">
				<p>
					广安世纪学习中心</p>
			</td>
			<td style="width:111px;height:19px;">
				<p>
					0817-2569333</p>
			</td>
			<td style="width:291px;height:19px;">
				<p>
					四川省广安市广安区前锋区工业六路1号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					18</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					四川协和学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0817-3315788</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					四川省南充市高坪区祥和街85号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					19</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					惠州孚澳学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0752-7825567</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					广东省惠州市惠城区花边北路3号宏城商务大厦401</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					20</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					珠海英迪学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0756-2258340</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					珠海市香洲区紫荆路333号狮山大厦六楼</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					21</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					上海房地产学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					021-64753273</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					上海市徐汇区漕宝路320号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					22</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					无锡新世纪学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0510-82206043</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					无锡市新区太湖花园二期245号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					23</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					南京中蔺学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					025-52814926</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					江苏省南京市雨花台区花神大道10号花神科技园2栋501</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:34px;">
				<p>
					24</p>
			</td>
			<td style="width:123px;height:34px;">
				<p>
					哈尔滨东方龙育学习中心</p>
			</td>
			<td style="width:111px;height:34px;">
				<p>
					0451&mdash;88590011</p>
			</td>
			<td style="width:291px;height:34px;">
				<p>
					哈尔滨市香坊区松花路中国云谷A1栋412室</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					25</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					连云港华普学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0518-83022738</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					江苏省连云港市赣榆区黄海东路310号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					26</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					南通上元学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0513-82222505</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					江苏省南通市海门镇贵都之星8号楼707室</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					27</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					湖南培源学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0731-84868888</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					湖南省长沙市开福区蔡锷北路司马里38号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:34px;">
				<p>
					28</p>
			</td>
			<td style="width:123px;height:34px;">
				<p>
					毕节银海学习中心</p>
			</td>
			<td style="width:111px;height:34px;">
				<p>
					0857-8257012</p>
			</td>
			<td style="width:291px;height:34px;">
				<p>
					贵州省毕节市七星关区宋伍村坝口组</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					29</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					泰州上元学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0523-86216083</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					江苏省泰州市海陵区一路86号1幢</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					30</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					阜阳信息工程学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0558-2162617</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					安徽省阜阳市颍州区临泉路888号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					31</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					重庆华绣学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					023-62398561</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					重庆市永川区竹海路20号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					32</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					怀远江淮学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0552-3971110</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					安徽省蚌埠市怀远县榴城镇怀远工业园创业园</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					33</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					太原晟新学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0351-6825888</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					山西省太原市小店区学府街22号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					34</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					昆明市西山方亚培训学校学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0871-65422855</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					云南省昆明市西山区西苑街道十里长街商务楼</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					35</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					新疆科技职业技术学院学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					18149896448</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					新疆维吾尔族自治区五家渠市猛进南路980号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					36</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					兰州连邦职业培训学校学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0931-8910156</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					甘肃省兰州市城关区白银路181号</p>
			</td>
		</tr>
		<tr>
			<td style="width:30px;height:21px;">
				<p>
					37</p>
			</td>
			<td style="width:123px;height:21px;">
				<p>
					南昌经济技术开发区英豪培训学校学习中心</p>
			</td>
			<td style="width:111px;height:21px;">
				<p>
					0791-83810315</p>
			</td>
			<td style="width:291px;height:21px;">
				<p>
					江西省南昌经济技术开发区双港东大街469号二楼</p>
                </td>
		</tr>
	</tbody>
</table></p> -->
                    </div>
                </div>
            </div>
      </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            entranceList:[
               {
                 title: '1',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getEntList(){
      const res = await consult.getEntList(3, 1)
      console.log(res,'entrance')
      const list = res.data.data.list.records
      this.entranceList = list.map(entrance => {
        const {entranceTitle, entranceId, entranceContent} = entrance
        return {
          title: entranceTitle.substring(0,4),
          id: entranceId,
          content: entranceContent,
        }
      })
    },
  },
  mounted() {
    this.getEntList()
  }
  }
</script>

<style>
.lcright9 {
    float: left; 
    width:1000px;
    margin-top: 200px;
   }
.lcright-019 {margin-right:10px;}
.lcright-029 {border-bottom: 1px solid #dadada;
       height: 54px;
       position: relative;
       width: 1000px;
      }
.main-049 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z9 {color: #898989;font-size: 12px;}
.lcright-039 {margin: 10px 10px 81px 0;
       /* width: 1000px;
       position: relative; */
      }
.zy_about9 {line-height: 2em;
     }
.zy_about9 p{ 
     margin-bottom:10px;
     margin-left: auto;
     }
.nr-z29 {
  color: #353535;
  font-size: 13px;
  width: 1000px;
  }
</style>