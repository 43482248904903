<template>
    <div class="lcright_04">
      <div class="lcright-01_04">
          <div class="lcright-02_04">
              <div class="main-04_04 linknav nav-z_04">
                  <router-link to="/homeguidel">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">制定学习计划</font>
              </div>
          </div>
          <div class="lcright-03_04">
                	<div class="zy_about_04 nr-z2_04" v-for="h of homeList" :key="h.title">
                    	<p>
    <p v-html="h.content">
	<!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 每个学生应根据自己的专业背景、文化基础、学习能力、学习动机以及年龄、职业和工作、家务负担等主客观条件，精心设计、合理安排个人学习计划。可以在导学教师、班主任等的指导下完成。制订个人学习计划需考虑的因素有：<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1、你完成学业需修读多少门课，有没有需要补修的课程；<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2、你打算每个学期各修读哪几门课程，这些课程的关系和难易程度如何；<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3、你每周有多少业余时间可以用来学习；<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4、你打算何时完成学业；<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 5、必须符合专业规则的相关要求。 -->
</p>
</p>
                    </div>
                </div>
      </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            img: '',
            homeList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getHomeList(){
      const res = await consult.getHomeList(2, 1)
      console.log(res,'home')
      const list = res.data.data.list.records
      this.homeList = list.map(home => {
        const {alwaysTitle, createTime, alwaysId, alwaysContent} = home
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getHomeList()
  }
  }
</script>

<style>
.lcright_04 {
    float: left; 
    width:1000px;
    margin-top: 200px;
   }
.lcright-01_04 {margin-right:10px;}
.lcright-02_04 {border-bottom: 1px solid #dadada;
       height: 54px;
       position: relative;
       width: 1000px;
      }
.main-04_04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z_04 {color: #898989;font-size: 12px;}
.lcright-03_04 {margin: 10px 10px 81px 0;}
.zy_about_04 {line-height: 2em;}
.zy_about_04 p{ margin-bottom:10px;}
.nr-z2_04 {
        color: #353535;
        font-size: 13px;
        width: 1000px;
        }
</style>