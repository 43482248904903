<template>
  <div class="main">
  <div class="content">
  <div class="left">
  <div class="left-top"></div>
  <div class="aleft"><router-link to="/gonggao"><span  class="tabs">通知公告</span></router-link></div>
  <div class="aleft"><router-link to="/yuanxiao"><span  class="tabs">院校新闻</span></router-link></div>
  <div class="aleft"><router-link to="/hangye"><span  class="tabs">行业动态</span></router-link></div>
  </div>
  <div name="right" class="right">
      <router-view></router-view>
  </div>
  </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        tabPosition: 'left'
      };
    },
    watch: {
      
    }
    ,
    mounted() {
      if(location.hash == '#/about')
        this.$router.push({path: '/yxjj'})
    }
  };
</script>

<style lang="scss" scoped>
.main{
  width: 100%;
  padding-bottom: 100px;
.content{
  display: flex;
  width: 1000px;
  margin: 0 auto;
.left{
  background: url(@/assets/imgs/index/leftbj.jpg);
  background-size: 100% 100%;
  height: 196px;
  .left-top{
    height: 49px;
  }
.tabs{
  background-image: url(@/page/Index/image/left-02.png);
  background-repeat: no-repeat;
  color: #737373;
  display: block;
  font-size: 20px;
  font-weight: bold;
  height: 49px;
  line-height: 49px;
  padding-left: 20px;
  text-align: left;
  text-decoration: none;
  width: 200px;
}
.tabs:active{
  background: url(@/assets/imgs/leftcf.png);
  color: #fff;
  cursor: pointer;
}
.tabs:hover{
  color: #1a4d7b;
}
.aleft .router-link-active {
  .tabs {
      background: url(@/assets/imgs/leftcf.png);
      color: #fff;
  }
}
.tabs:visited{
  background: url(@/assets/imgs/leftcf.png);
  color: #fff;
}

}
.right{
  width: 815px;
  margin-left: 30px;
}
}
}
</style>
