<template>
          <div class="lcright">
        	<div class="lcright-01" v-for="e of entranceList" :key="e.title">
            	<div class="lcright-02">
                	<div class="main-04 linknav nav-z">
                    	<router-link to="/homeguide">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">{{e.title}}</font>
                    </div>
                </div>
                <div class="lcright-03">
                	<div class="zy_about nr-z2">
                    	<p>
                             <p>
	<span v-html="e.content"  style="font-size: 16px"></span></p>
<!-- <p> 
	<span style="font-size: 16px">【专科】年满17周岁，具有普通高中、职业高中、技工学校和中等专业学校及以上层次毕业证书的学习者，可注册专科学历教育相关专业学习。</span></p>
<p>
	<span style="font-size: 16px;">【本科】</span><span style="font-size:16px;">具有国民教育系列的专科或专科以上毕业证书者，可注册本科学历教育相关专业学习。</span></p>
<p>
	&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>  -->
</p>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            entranceList:[
               {
                 title: '1',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getEntList(){
      const res = await consult.getEntList(1, 1)
      console.log(res,'entrance')
      const list = res.data.data.list.records
      this.entranceList = list.map(entrance => {
        const {entranceTitle, entranceId, entranceContent} = entrance
        return {
          title: entranceTitle.substring(0,4),
          id: entranceId,
          content: entranceContent,
        }
      })
    },
  },
  mounted() {
    this.getEntList()
  }
  }
</script>

<style>
.lcright {
          float: left; 
          width:1000px;
          margin-top: 200px;
         }
.lcright-01 {margin-right:10px;}
.lcright-02 {border-bottom: 1px solid #dadada;
             height: 54px;
             position: relative;
             width: 1000px;
            }
.main-04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z {color: #898989;font-size: 12px;}
.lcright-03 {margin: 10px 10px 81px 0;
             /* width: 1000px;
             position: relative; */
            }
.zy_about {line-height: 2em;
           }
.zy_about p{ 
           margin-bottom:10px;
           margin-left: auto;
           }
.nr-z2 {
        color: #353535;
        font-size: 13px;
        width: 1000px;
        }
</style>