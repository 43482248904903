<template>
    <div class="guide4">
      <div class="tip4"></div>
<div class="topbox4 widths14"><img src="../../assets/imgs/index/z_logo_baoming2.jpg"/></div>
<div class="navbox4 widths4">
	<div class="nav_l fl"><img src="../../assets/imgs/index/navle1.png" style="width: 5px; height: 45px"/></div>
    <div class="nav_c4 fl">
    	<ul>
        	<li><router-link to="/homeguide">指南首页</router-link></li>
            <li><router-link to="/condition">报名条件</router-link></li>
            <li><router-link to="/process">报名流程</router-link></li>
            <li><router-link to="/standard">收费标准</router-link></li>
            <li><router-link to="/net">报名网络</router-link></li>
            <li><router-link to="/link">常用链接</router-link></li>
        </ul>
    </div>
    <div class="nav_r fl"><img src="../../assets/imgs/index/navri1.png"  style="width: 5px; height: 45px"/></div>
  </div>
    <div class="con_zy4 widths24">
            <div class="con_zy4 widths24">
              <router-view></router-view>
              </div>
          </div> 
          <div class="footbox4">
	<div class="foot4 widths4">
    	<p>咨询电话： 010-88259037  57519541 <a href="mailto:gkrjxy@crtvu.edu.cn">咨询邮箱</a></p>
		<p>国家开放大学软件学院版权所有 京ICP备14053447号-1 京公网安备11010802015291</p>
    </div>
</div>      
     </div>
  </template>
  
  <script>
  export default {
         data(){
          return {
          tabPosition: 'left'
      };
         },
         watch: {

         },
         mounted(){
          if(location.hash == '#/guide')
          this.$router.push({path: '/homeguide'})
         }
  }
  </script>
  
  <style>
  .tip4{ width:100%; border-top:5px solid blue;} 
  /* .topbox{ height:20px; padding:15px 0px; text-align:center;}  */
  .widths4{ width:1000px; margin:0px auto;}
  .widths14{
                   width:1000px; 
                   margin:0px auto;
                   background: #fff;
                   text-align:center;
                   padding:15px 0px;
  }
  .widths24{
                   width:1000px; 
                   margin:0px auto;
  }
  .navbox4{
          height:45px;
         }
  .nav_c4{background:url(../../assets/imgs/index/nav1.jpg) repeat-x; width:990px; height:45px; line-height:45px;}
  .nav_c4 li{ float:left;}
  .nav_c4 li a{ font-size:16px; color:#fff; display:block; width:120px; margin:0px 22px; text-align:center;}
  .nav_c4 li a:hover{ background:blue; color:#fff;}
  .con_zy4{ display:block; overflow:hidden; _zoom:1; min-height:520px;}
  .footbox4{ 
           background:url(../../assets/imgs/index/foottip1.png) repeat-x top center #ebebeb;
           background-size: 1px 5px;
           margin-top:20px;
          }
  .foot4{ text-align:center; line-height:24px; height:75px; padding-top:20px;}
  .foot4 .widths4{ width:1000px; margin:0px auto;} 
  </style>