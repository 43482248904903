<template>
  <div class="xsyd">
    <div class="lcright-02">
      <div class="main-04 linknav nav-z">
        <router-link to="/index">首页</router-link
        >&nbsp;&gt;&nbsp;教学支持服务&nbsp;&gt;&nbsp;<font color="blue"
          >学生园地</font
        >
      </div>
    </div>
    <div class="content">
      <div class="guanli" v-for="XS of xsydList" :key="XS.title">
        <router-link class="item" :to="xsydUrl + XS.id">
          <a href="">{{ XS.title }}</a>
          <span>{{ XS.date }}</span>
        </router-link>
      </div>
      <Paning :total="total" :count="count"></Paning>
    </div>
  </div>
</template>
<script>
import Paning from '@/components/Paning.vue'
import consult from '@/api/yssz/consult'
export default {
  components: {
    Paning,
  },
  data() {
    return {
      xsydList: [
        {
          title: '1',
          date: '2022',
          id: '',
        },
      ],
      count: '15',
      page: '1',
      xsydUrl: '/xsyd/',
    }
  },
  computed: {
    pageList() {
      console.log(
        this.xsydList.slice(this.count * this.page - 1, this.count * this.page),
        'j'
      )
      return this.xsydList.slice(
        this.count * (this.page - 1),
        this.count * this.page
      )
    },
    total() {
      return this.xsydList.length
    },
  },
  watch: {
    '$route.query.page'() {
      this.page = this.$route.query.page
      if (!this.page) {
        this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      }
    },
  },
  methods: {
    idToRouter(id) {
      return '/xsyd/' + id
    },
    async getXsydList() {
      const res = await consult.getXsydList(1, 8)

      const list = res.data.data.list.records
      if (!this.$route.query.page)
        this.$router.replace({ query: { ...this.$route.query, page: 1 } })

      this.xsydList = list.map((it) => {
        const { alwaysTitle, createTime, alwaysId } = it
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
        }
      })
    },
  },
  mounted() {
    this.getXsydList()
  },
}
</script>
<style lang="scss" scoped>
.xsyd {
  margin: -200px auto;
}
.lcright-02 {
  border-bottom: 1px solid #dadada;
  height: 54px;
  position: relative;
  width: 725px;
}
.main-04 {
  height: 22px;
  line-height: 22px;
  position: absolute;
  right: 4px;
  text-align: right;
  top: 25px;
  width: 500px;
  z-index: 1;
}
.nav-z {
  color: #898989;
  font-size: 12px;
}
.content {
  width: 720px;
  margin-bottom: 80px;
  .guanli {
    height: 45px;
    line-height: 45px;
    border-bottom: 1px dotted #b8bcbe;
    font-size: 13px;
    background: url(@/assets/imgs/index/blaicon.gif) no-repeat left center;
    .item {
      display: flex;
      justify-content: space-between;
    }
    span {
      color: #a2a6a8;
    }
    a {
      color: #000000;
      margin-left: 5px;
    }
    a:hover {
      color: #0189ff;
    }
  }
}
</style>
