<template>
    <div class="admission_16">
    <div class="lcright-02_16">
                <div class="main-04_16 linknav nav-z_16">
                    <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;新媒体&nbsp;&gt;&nbsp;<font color="blue">认识新媒体</font>
                </div>
            </div>
            <div class="lcright-03">
                      <div class="zy_about nr-z2">
                          <p style="text-align: center;">
                          <span style="font-size:16px;line-height:47px; height:47px;color:black;font-weight:bold;">
                            {{inf.title}}
                          </span>
                        </p>
                        <p style="text-align: center; background-color:#e7e7e7; margin-bottom:10px; ">
                          <span style="font-size:12px;  color:#444; font-family: 微软雅黑, 'Microsoft Yahei';line-height:27px; height:27px; ">发布时间：{{inf.date}}
                          </span>
                        </p>
                          <p>
                          <p>
                         <span v-html="inf.content" style="color: rgb(102, 102, 102); font-family: 微软雅黑, 'Microsoft Yahei'; line-height: 26px;"></span>
                      </p>
                    </p>
                      </div>
                  </div> 
    </div>
  </template>
  
  <script>
  import consult from "@/api/yssz/consult";
  export default {
  data() {
    return {
      inf: {
        title: '',
        content: '',
        date:'',
      }
      
    }
    },
    computed: {
      
    },
    methods: {
      idToRouter(id) {
        return '/knowNewMedia/'+id
      },
      async getInf(){
        console.log('这个id是',this.$route.params.id)
        let res = await consult.getInfId(this.$route.params.id)
        let inf= res.data.data.always
  
        this.inf = {
          title: inf.alwaysTitle,
          content: inf.alwaysContent,
          date:this.$moment(inf.createTime).format('l')+' '+this.$moment(inf.createTime).format('LTS'),
        }
      }
    },
    mounted() {
    this.getInf();
  
  }
  };
  </script>
  
  <style lang="scss" scoped>
  .admission_16 {
    margin: -200px auto;
  }
  .lcright-02_16 { border-bottom: 1px solid #dadada;
                   height: 54px;
                   position: relative;
                   width: 725px;
        }
  .main-04_16 {height: 22px;
               line-height: 22px;
               position: absolute;
               right: 4px;
               text-align: right;
               top: 25px;
               width: 500px;
               z-index: 1;}
  .nav-z_16 {color: #898989;font-size: 12px;}
  .lcright-03 {margin: 10px 10px 81px 0;
               width: 715px;}
  .zy_about {line-height: 2em;
             width: 715px;}
  .zy_about p{ margin-bottom:10px;}
  .main-04 {height: 22px;
            line-height: 22px;
            position: absolute;
            right: 4px;
            text-align: right;
            top: 25px;
            width: 500px;
            z-index: 1;}
  .nav-z {color: #898989;
          font-size: 12px;}
  .nr-z2 {color: #353535;
          font-size: 13px;padding-bottom: 200px;}
  </style>