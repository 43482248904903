<template>
    <div class="profession_p">
    <div class="lcright-02_p">
                <div class="main-04_p linknav nav-z_p">
                    <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;招生支持服务&nbsp;&gt;&nbsp;<font color="blue">开设专业</font>
                </div>
            </div>
            <div class="lcright-03_p">
                      <div class="zy_about_p nr-z2_p">
                          <p style="text-align: center;">
                          <span style="font-size:16px;line-height:47px; height:47px;">
                            {{maj.title}}
                          </span>
                        </p>
                        <p style="text-align: center; background-color:#e7e7e7; margin-bottom:10px; ">
                          <span style="font-size:12px;  color:#444; font-family: 微软雅黑, 'Microsoft Yahei';line-height:27px; height:27px; ">发布时间：{{maj.date}}
                          </span>
                        </p>
                          <p>
                          <p>
                         <span v-html="maj.content" style="color: rgb(102, 102, 102); font-family: 微软雅黑, 'Microsoft Yahei'; line-height: 26px;"></span>
                      </p>
                    </p>
                      </div>
                  </div> 
    </div>
  </template>
  
  <script>
  import consult from "@/api/yssz/consult";
  export default {
  data() {
    return {
        maj: {
        title: '',
        content: '',
        date:'',
      }
      
    }
    },
    computed: {
      
    },
    methods: {
      idToRouter(id) {
        return '/profession/'+id
      },
      async getMaj(){
        let res = await consult.getMajId(this.$route.params.id)
        let maj= res.data.data.major
        console.log(res)
        this.maj = {
          title: maj.majorTitle,
          content: maj.majorContent,
          date:this.$moment(maj.createTime).format('l')+' '+this.$moment(maj.createTime).format('LTS'),
        }
      }
    },
    mounted() {
    this.getMaj();
  
  }
  };
  </script>
  
  <style lang="scss" scoped>
  .profession_p{
    margin: -200px auto;
}
  .lcright-02_p { border-bottom: 1px solid #dadada;
                   height: 54px;
                   position: relative;
                   width: 725px;
        }
  .main-04_p {height: 22px;
               line-height: 22px;
               position: absolute;
               right: 4px;
               text-align: right;
               top: 25px;
               width: 500px;
               z-index: 1;}
  .nav-z_p {color: #898989;font-size: 12px;}
  .lcright-03_p {margin: 10px 10px 81px 0;
               width: 715px;}
  .zy_about_p {line-height: 2em;
             width: 715px;}
  .zy_about_p p{ margin-bottom:10px;}
  .main-04_p {height: 22px;
            line-height: 22px;
            position: absolute;
            right: 4px;
            text-align: right;
            top: 25px;
            width: 500px;
            z-index: 1;}
  .nav-z_p {color: #898989;
          font-size: 12px;}
  .nr-z2_p {color: #353535;
          font-size: 13px;}
  </style>