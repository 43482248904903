import axios from "axios";
import { MessageBox, Message } from "element-ui";

// create an axios instance
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
});

// request interceptor

export default service;
