<template>
    <div class="mxmk">
        <div class="lcright-02">
                     <div class="main-04 linknav nav-z">
                      <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;教学支持服务&nbsp;&gt;&nbsp;<font color="blue">免修免考</font>
                      </div> 
                  </div>
        <div class="content">
            <div v-for="MX of mxmkList" :key="MX.title">
            <div class="title">{{MX.title}}</div>
            <div class="text" v-html="MX.content"></div>
            </div>
            <!-- <div class="title">免修免考、免修不免考的对象是什么？</div>
            <div class="text">免修免考对象为电大课程、国家自学考试课程、合作高等学校课程、国家外语等级证书；另外，对于公共基础课程（15年有效），允许已获得相应科类专业专科以上学历（国家承认的国民教育系列）者免修免考，并获得相应学分，如：数学专业毕业的学生可申请非数学专业的高等数学课程免修免考。<br>
    免修不免考的对象为其他高等学校课程。</div> -->
        </div>
        </div>
    </template>
    <script>
    import consult from '@/api/yssz/consult'
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        // name: "#jxjwgl",
        data() {
        return {
          img: '',
          mxmkList:[
            {
              title: '1',
              date: '2022',
              id: '',
            },
          ]
        }
        },
      
        methods: {
            async getMxmkList() {
                const res = await consult.getMxmkList(1,10)
                console.log(res,'222')
                const list = res.data.data.list.records
                this.mxmkList = list.map(it => {
            const {alwaysTitle,alwaysContent, alwaysId} = it
            return {
              title: alwaysTitle,
              content: alwaysContent,
              id: alwaysId,
            }
          })
    
         }
        },
        mounted() {
            this.getMxmkList()
        }
    };
    </script>
    <style lang="scss" scoped>
    .mxmk{
        margin: -200px auto;
        }
        .lcright-02 {border-bottom: 1px solid #dadada;height: 54px;position: relative;width: 725px;}
    .main-04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
    .nav-z {color: #898989;font-size: 12px;}
       .content{
            // display: flex;
            // margin-top: 8px;
            padding-top: 8px;
            padding-bottom: 80px;
            height: 1400px;
            .title{
                font-weight: bold;
                font-size: 15px;
                margin-top: 20px;
            }
            .text{
                margin-top: 10px;
                color: #585a5c;
                font-size: 13px;
                line-height: 23px;
            }
        }
    </style>