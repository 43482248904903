<template>
          <div class="lcright_7">
        	<div class="lcright-01_7">
            	<div class="lcright-02_7">
                	<div class="main-04_7 linknav nav-z_7">
                    	<router-link to="/homeguidel">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">参加面授</font>
                    </div>
                </div>
                <div class="lcright-03_7">
                	<div class="zy_about_7 nr-z2_7" v-for="h of homeList" :key="h.title">
                    	<p>
                            <p v-html="h.content">
	<!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 面授学习作为实时教学活动的一种形式，学习中心的课程辅导教师可以根据教学的实际需要安排少量学时的面授。面授学习是学生自主学习的一种辅助形式，并不是大学主流的学习方式，学院采取了多种渠道鼓励、支持学生通过网上学习的方式来完成课程的学习。 -->
</p>
</p>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            img: '',
            homeList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getHomeList(){
      const res = await consult.getHomeList(5, 1)
      console.log(res,'home')
      const list = res.data.data.list.records
      this.homeList = list.map(home => {
        const {alwaysTitle, createTime, alwaysId, alwaysContent} = home
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getHomeList()
  }
  }
</script>

<style>
.lcright_7 {
          float: left; 
          width:1000px;
          margin-top: 200px;
         }
.lcright-01_7 {margin-right:10px;}
.lcright-02_7 {border-bottom: 1px solid #dadada;
             height: 54px;
             position: relative;
             width: 1000px;
            }
.main-04_7 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z_7 {color: #898989;font-size: 12px;}
.lcright-03_7 {margin: 10px 10px 81px 0;}
.zy_about_7 {line-height: 2em;}
.zy_about_7 p{ margin-bottom:10px;}
.nr-z2_7 {
          color: #353535;
          font-size: 13px;
          width: 1000px;
          }
</style>