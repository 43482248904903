<template>
  <div class="fanye">
    <component :is="lastName"
      class="disabled" @click="last">
      &lt;
    </component>
    <span
    @click="pageClick(1)"
      :class="{active: page == 1}"  :key="i" class="current ">1</span>
    <span v-show="(page > 4)">...</span>
    <span
    @click="pageClick(i)"
    v-for="(i) in test" :class="{active: page == i}"
    :key="i" class="current "
    v-if="(i != 1) && (i != test)"
    v-show="(page > i - 3) && (page < i + 3)"
    >{{i}}</span>
    <span v-show="(page < test - 3)">...</span>

    <span
    @click="pageClick(test)"
      :class="{active: page == test}"  :key="i" class="current "
      v-if="(test > 1)"
      >{{test}}</span>

    <component @click="next" :is="nextName" class="disabled">
      &gt;
    </component>
  </div>
</template>

<script>
import { string } from 'postcss-selector-parser'

export default {
  props:{
    total:{
      type: String,
    },
    count:{
      type: String,
    },
  },
  data() {
    return {
      test: 5,
      page: '1',
    }
  },
  computed:{
    lastName(){
      return this.page > 1 ? 'a' : 'span'
    },
    nextName(){
      return this.page < this.test ? 'a' : 'span'
    }
  },
  watch:{
    "$route.query.page"(){
      this.page = this.$route.query.page
    },
    total(){
      console.log(this.total, 'ttttt',this.count)
      this.test = Math.ceil(this.total / this.count)
    }
  },
  methods:{
    last(){
      if(this.lastName == 'a') {
        this.$router.replace({query: {...this.$route.query, page: this.page-1}})
      }
    },
    next(){
      if(this.nextName == 'a') {
        this.$router.replace({query: {...this.$route.query, page: Number(this.page)+1}})

      }
    },
    pageClick(i){
      this.$router.replace({query: {...this.$route.query, page: i}})
    },
    init(){
      this.page = this.$route.query.page
      console.log(this.total, 'toto',  this.count,'jfjs')
      this.test = this.total/ this.count
    }
  },
  created(){
    this.init()
  }

}
</script>

<style lang="scss" scoped>
DIV.fanye {
    clear: both;
    margin: 35px 0px 18px 0px;
    *margin: 25px 0px 35px 0px;
    display: block;
    overflow: hidden;
    _zoom: 1;
    height: 23px;
    line-height: 23px;
    text-align: center;
    width: 100%;
}
.con_zy {
    min-height: 520px;
}
.current {
  background: #e6e6e6;
    margin-right: 8px;
    padding: 8px 10px;
    &:hover {
      background: #323B94;
      color: #fff;
      font-weight: bold;

    }
}
.active {
  background: #323B94;
    color: #fff;
    font-weight: bold;

}
.disabled {
    color: #333;
    margin-right: 8px;
    padding: 8px 10px;
}
a.disabled {
  background: #e6e6e6;
  &:hover {
      background: #323B94;
      color: #fff;
      font-weight: bold;

    }
}
.lcright-02 {
  border-bottom: 1px solid #dadada;
  height: 54px;
  position: relative;
  width: 1000px;
  margin: 0 auto;
}

.zy_news2 li span {
  float: right;
  color: #999;
}

a {
  text-decoration: none;
  font-size: 12px;
  color: #343434;
}

.zy_news2 li {
  line-height: 40px;
  border-bottom: 1px dashed #dedede;
  background: url(../assets/static/image/blaicon.gif) no-repeat left center;
  padding-left: 10px;
}

.lcright-03 {
  margin: 10px 10px 81px 0;
  width: 1000px;
  margin: 0 auto;
}

.zy_about {
  line-height: 2em;
  width: 715px;
}

.zy_about p {
  margin-bottom: 10px;
}

.main-04 {
  height: 22px;
  line-height: 22px;
  position: absolute;
  right: 4px;
  text-align: right;
  top: 25px;
  width: 500px;
  z-index: 1;
}

.nav-z {
  color: #898989;
  font-size: 12px;
}

.nr-z2 {
  color: #353535;
  font-size: 13px;
}
</style>
