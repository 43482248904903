<template>
  <div class="footer">
    <div class="footer-top">
      <div class="aa">
        <p class="abc">
          <router-link to="/index" class="abcd">首页</router-link>
        </p>
        <div class="aaa">
          <ul>
            <li>
              <router-link to="/gonggao"
                ><span class="text">通知公告</span>
              </router-link>
            </li>
            <li>
              <router-link to="/yuanxiao"
                ><span class="text">院校新闻</span>
              </router-link>
            </li>
            <li>
              <router-link to="/hangye"
                ><span class="text">行业动态</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="aa">
        <p class="abc">
          <router-link to="/about" class="abcd">学院概况</router-link>
        </p>
        <div class="aaa">
          <ul>
            <li>
              <router-link to="/yxjj"
                ><span class="text">院校简介</span></router-link
              >
            </li>
            <li>
              <router-link to="/xyld"
                ><span class="text">学院领导</span></router-link
              >
            </li>
            <li>
              <router-link to="/yzzc"
                ><span class="text">院长致辞</span></router-link
              >
            </li>
            <li>
              <router-link to="/jgsz"
                ><span class="text">机构设置</span></router-link
              >
            </li>
            <li>
              <router-link to="/bxlc"
                ><span class="text">办学历程</span></router-link
              >
            </li>
            <li>
              <router-link to="/xxzxfb"
                ><span class="text">学习中心分布</span></router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="aa">
        <p class="abc">
          <router-link to="/xljy" class="abcd">学历教育</router-link>
        </p>
        <div class="aaa">
          <ul>
            <li>
              <router-link to="/zkzy"
                ><span class="text">开放教育</span>
              </router-link>
            </li>
            <li>
              <router-link to="/bkzy"
                ><span class="text">单考单招</span>
              </router-link>
            </li>
            <li>
              <router-link to="/tskc"
                ><span class="text">特色课程</span>
              </router-link>
            </li>
            <!-- <li>
              <router-link to="/msmj"
                ><span class="text">名师名教</span>
              </router-link>
            </li>
            <li>
              <router-link to="/xxzx"
                ><span class="text">学习中心</span>
              </router-link>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="aa">
        <p class="abc">
          <router-link to="/fxljy" class="abcd">非学历教育</router-link>
        </p>
        <div class="aaa">
          <ul>
            <li>
              <router-link to="/overview"
                ><span class="text">概况</span>
              </router-link>
            </li>
            <li>
              <router-link to="/certification"
                ><span class="text">认证体系</span>
              </router-link>
            </li>
            <li>
              <router-link to="/course"
                ><span class="text">课程体系</span>
              </router-link>
            </li>
            <li>
              <router-link to="/teacher"
                ><span class="text">认证讲师</span>
              </router-link>
            </li>
            <li>
              <router-link to="/center"
                ><span class="text">授权培训中心</span>
              </router-link>
            </li>
            <li>
              <router-link to="/train"
                ><span class="text">实训基地</span>
              </router-link>
            </li>
            <li>
              <router-link to="/search"
                ><span class="text">证书查询</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="aa">
        <p class="abc">
          <router-link to="/zhaosheng" class="abcd">招生支持服务</router-link>
        </p>
        <div class="aaa">
          <ul>
            <li>
              <router-link to="/admission"
                ><span class="text">招生资讯</span>
              </router-link>
            </li>
            <li>
              <router-link to="/profession"
                ><span class="text">开设专业</span>
              </router-link>
            </li>
            <li>
              <router-link to="/homeguide"
                ><span class="text">入学指南</span>
              </router-link>
            </li>
            <li>
              <router-link to="/homeguidel"
                ><span class="text">学习指南</span>
              </router-link>
            </li>
            <li>
              <router-link to="/luqu"
                ><span class="text">录取查询</span>
              </router-link>
            </li>
            <li>
              <router-link to="/certificate"
                ><span class="text">证书样本</span>
              </router-link>
            </li>
            <li>
              <router-link to="/answers"
                ><span class="text">招生问答</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="aa">
        <p class="abc">
          <router-link to="/jiaoxue" class="abcd">教学支持服务</router-link>
        </p>
        <div class="aaa">
          <ul>
            <li>
              <router-link to="/xxfb"
                ><span class="text">信息发布</span>
              </router-link>
            </li>
            <li>
              <router-link to="/jxjwgl"
                ><span class="text">教学教务管理</span>
              </router-link>
            </li>
            <li>
              <router-link to="/xkjs"
                ><span class="text">学科建设</span>
              </router-link>
            </li>
            <li>
              <router-link to="/szdw"
                ><span class="text">师资队伍</span>
              </router-link>
            </li>
            <li>
              <router-link to="/mxmk"
                ><span class="text">免修免考</span>
              </router-link>
            </li>
            <li>
              <router-link to="/jypg"
                ><span class="text">教研评估</span>
              </router-link>
            </li>
            <li>
              <router-link to="/xsyd"
                ><span class="text">学生园地</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="aa">
        <p class="abc">
          <router-link to="/cooperate" class="abcd">新媒体</router-link>
        </p>
        <!-- <div class="aaa">
          <ul>
            <li>
              <router-link to="/product/list"
                ><span class="text">开放教育</span>
              </router-link>
            </li>
            <li>
              <router-link to="/caselist"
                ><span class="text">单靠单招</span>
              </router-link>
            </li>
            <li>
              <router-link to="/caselist"
                ><span class="text">特色课程</span>
              </router-link>
            </li>
          </ul>
        </div> -->
      </div>
      <!-- <div class="ab">
        <router-link  to="/contact" class="abb">联系我们</router-link>
        <div class="aaa">
          <div class="atext">
            <span>电话：</span>
            15188640848 王总
          </div>
          <div class="atext">
            <span class="atext">邮箱：</span>
            2433698250@qq.com
          </div>
          <div class="atext">
            <span>地址：</span>
            浙江省杭州市西湖区保俶路浙江大学西溪校区培训楼西楼1楼101
          </div>
        </div>
      </div> -->
      <!-- <div class="bb">
        <img src="../assets/imgs/index/logo.png" alt="" />
        <p>云生数字</p>
      </div> -->
    </div>
    <div class="footer-bottom">
      <div class="left">
        <p>
          <a href="http://www.moe.gov.cn/" target="_new" class="left-text"
            >教育部网站</a
          >&nbsp;<a href="http://www.cdce.cn/" target="_new" class="left-text"
            >中国现代远程与继续教育网</a
          >&nbsp;<a href="http://www.jyb.cn/" target="_new" class="left-text"
            >中国教育新闻网</a
          >&nbsp;<a href="http://www.jyb.cn/" target="_new" class="left-text"
            >中国网·国开在线</a
          >
        </p>
        <p>
          版权所有：浙江开放大学软件学院&nbsp;<a
            href="https://beian.miit.gov.cn/"
            target="_new"
            class="left-text"
          >
            &nbsp;&nbsp;&nbsp;浙ICP备000000号-2</a
          >&nbsp;
        </p>
        <p>浙江知海管理咨询有限公司提供运营支持</p>
      </div>
      <div class="right">
        <!-- <img src="http://www.beian.gov.cn/img/new/gongan.png" /> -->
        <!-- <P class="right-text"
          >地址：浙江省杭州市西湖区教工路42号&nbsp;邮编：310000</P
        >
        <P class="right-text"
          >联系电话：0571-85230128&nbsp;<a
            href="http://www.moe.gov.cn/"
            target="_new"
            class="right-text"
            >联系邮箱</a
          ></P
        > -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nav-footer',
}
</script>

<style lang="scss">
.footer {
  border-top: solid 1px #1a4d7b;
  width: 100%;
  height: 400px;
  background-color: #00396c;
  // text-align: center;
  .footer-top {
    width: 1000px;
    height: 300px;
    margin: 0 auto;
    display: flex;
    text-align: center;
    justify-content: center;
    .aa {
      width: 140px;
      height: 250px;
      // margin-right: 20px;
      margin-top: 50px;
      // justify-content: center;
      text-align: left;
      // font-family: fangsong;
      font-family: '微软雅黑', Microsoft Yahei;
      font-size: 20px;
      color: #fff;
      p {
        font-size: 20px;
        color: #000;
        width: 120px;
        height: 30px;
        border-bottom: 1px solid #63676b;
        .abcd {
          font-size: 20px;
          color: #fff;
        }
      }
      .aaa {
        font-size: 18px;
        margin-top: 20px;
        .text {
          color: #fff;
        }
        li {
          margin-bottom: 5px;
          &:hover span {
            color: rgb(149, 191, 240);
          }
        }
      }
    }
    .ab {
      width: 350px;
      height: 180px;
      margin-right: 20px;
      margin-top: 50px;
      justify-content: center;
      text-align: left;
      font-family: '微软雅黑', Microsoft Yahei;
      font-size: 20px;
      color: #fff;
      .abb {
        font-size: 20px;
        color: #fff;
      }
      .aaa {
        font-size: 18px;
        margin-top: 20px;
        .atext {
          font-size: 18px;
          color: rgb(149, 191, 240);
          margin-bottom: 5px;
        }
      }
    }
    .bb {
      width: 180px;
      height: 180px;
      margin-left: 100px;
      margin-top: 30px;
      float: right;
      text-align: center;
      margin-bottom: 36px;
      font-family: '微软雅黑', Microsoft Yahei;
      font-size: 18px;
      color: rgb(149, 191, 240);
      img {
        margin-top: 30px;
        width: 53px;
        height: 53px;
        margin-bottom: 13px;
      }
    }
  }
  .footer-bottom {
    border-top: solid 1px #1a4d7b;
    height: 100px;
    width: 1000px;
    font-family: '微软雅黑', Microsoft Yahei;
    font-size: 15px;
    color: #fff;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    display: flex;
    .left {
      float: left;
      width: 650px;
      p {
        display: flex;
        margin-bottom: 10px;
      }
      .left-text {
        color: #fff;
      }
      a:hover {
        color: rgb(149, 191, 240);
      }
    }
    .right {
      width: 500px;
      p {
        display: flex;
        margin-bottom: 10px;
      }
      .right-text {
        float: right;
        color: #fff;
      }
      a:hover {
        color: rgb(149, 191, 240);
      }
    }
  }
}
</style>
