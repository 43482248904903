<template>
    <div class="banner">
    <div class="lcright-02_b">
                <div class="main-04_b linknav nav-z_b">
                    <router-link to="/index">首页</router-link>
                    <span> > </span>
                    <font color="blue">图片新闻</font>
                </div>
            </div>
            <div class="lcright-03_b">
                      <div class="zy_about_b nr-z2_b">
                          <p style="text-align: center;">
                          <span style="font-size:16px;line-height:47px; height:47px;">
                            {{banner.title}}
                          </span>
                        </p>
                        <p style="text-align: center; background-color:#e7e7e7; margin-bottom:10px; ">
                          <span style="font-size:12px;  color:#444; font-family: 微软雅黑, 'Microsoft Yahei';line-height:27px; height:27px; ">发布时间：{{banner.date}}
                          </span>
                        </p>
                          <p>
                          <p>
                         <span v-html="banner.content" style="color: rgb(102, 102, 102); font-family: 微软雅黑, 'Microsoft Yahei'; line-height: 26px;"></span>
                      </p>
                    </p>
                      </div>
                  </div> 
    </div>
  </template>
  
  <script>
  import consult from "@/api/yssz/consult";
  export default {
  data() {
    return {
        banner: {
        title: '',
        content: '',
        date:'',
      }
      
    }
    },
    computed: {
      
    },
    methods: {
      idToRouter(id) {
        return '/yemian/'+id
      },
      async getBanner(){
        let res = await consult.getBannerId(this.$route.params.id)
        let banner= res.data.data.slideshow
        console.log(res)
        this.banner = {
          title: banner.slideshowTitle,
          content: banner.slideshowContent,
          date:this.$moment(banner.createTime).format('l')+' '+this.$moment(banner.createTime).format('LTS'),
        }
      }
    },
    mounted() {
    this.getBanner();
  
  }
  };
  </script>
  
  <style lang="scss" scoped>
//   .admission_16 {
//     margin: -200px auto;
//   }
.banner{
    width: 1000px;
    margin: 0 auto;
}
  .lcright-02_b { border-bottom: 1px solid #dadada;
                   height: 54px;
                   position: relative;
                   width: 1000px;
                   margin: 0 auto;
        }
  .main-04_b {height: 22px;
               line-height: 22px;
               position: absolute;
               right: 4px;
               text-align: right;
               top: 25px;
               width: 1000px;
               z-index: 1;}
  .nav-z_b {color: #898989;font-size: 12px;}
  .lcright-03_b {margin: 10px 10px 81px 0;
               width: 1000px;}
  .zy_about_b {line-height: 2em;
             width: 1000px;}
  .zy_about_b p{ margin-bottom:10px;}
  .main-04_b {height: 22px;
            line-height: 22px;
            position: absolute;
            right: 4px;
            text-align: right;
            top: 25px;
            width: 1000px;
            z-index: 1;}
  .nav-z_b {color: #898989;
          font-size: 12px;}
  .nr-z2_b {color: #353535;
          font-size: 13px;}
  </style>


































































<!-- <template>
    <div class="course1">
        <div class="banner">
            <div class="lcright-02-2_b">
                <div class="main-04_b linknav nav-z_b">
                    <a href="index.asp">首页</a>
                    <span> > </span>
                    <font color="#c80000">图片新闻</font>
                </div>
            </div>
            <div class="lcright-03-2_b con_zy">
                <div class="zy_about2_b nr-z2_b">
                    <p style="text-align: center; "><span style="font-size:16px;line-height:47px; height:47px; ">{{banner.title}}</span></p>
                    <p style="text-align: center; background-color:#e7e7e7; margin-bottom:10px; "><span style="font-size:12px;  color:#444; font-family:'宋体'; line-height:27px; height:27px; ">发布时间：{{banner.date}}</span></p>
                    
                    <p v-html="banner.content">
                         <p>
&nbsp; &nbsp; &nbsp; &nbsp; 国家开放大学于2019年6月5日至7日在辽宁省大连市召开2019年招生工作会议。会上总结了国家开放大学2019年春季招生工作，部署2019年秋季招生工作，探讨当前招生过程中存在的问题和对策，研究规范招生工作的举措。</p>
<p>
&nbsp; &nbsp; &nbsp; &nbsp; 在&ldquo;评先进、促改革&rdquo;表彰中，软件学院荣获&ldquo;招生工作先进集体&rdquo;奖牌。</p>
<p>
<img alt="" src="UploadFiles/images/2019082859305209.jpg" style="width: 780px; height: 487px;" /></p>
<p>
软件学院院长助理李涛（左四）代表学院领奖</p> 
</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import consult from "@/api/yssz/consult";
export default {
data() {
  return {
    banner: {
      title: '',
      content: '',
      date:'',
    }
    
  }
  },
  computed: {
    
  },
  methods: {
    idToRouter(id) {
      return '/index/'+id
    },
    async getBanner(){
      let res = await consult.getBannerId(this.$route.params.id)
      let banner= res.data.data.banner
      console.log(res)
      this.banner = {
        title: banner.slideshowTitle,
        content: banner.slideshowContent,
        date:this.$moment(banner.createTime).format('l')+' '+this.$moment(banner.createTime).format('LTS'),
      }
    }
  },
  mounted() {
  this.getBanner();

}
};
</script>

<style lang="scss" scoped>
.con_zy {
    min-height: 1520px;
}
// .banner{
//     margin-right: 30px;
// }
.lcright-02-2_b{border-bottom: 1px solid #dadada;
    height: 54px;
    position: relative;
    width: 1000px;
    margin: 0 auto;
}
.main-04_b {height: 22px;
    line-height: 22px;
    position: absolute;
    right: 4px;
    text-align: right;
    top: 25px;
    width: 500px;
    z-index: 1;}
.nav-z_b {color: #898989;font-size: 12px;}
.lcright-03-2_b{
    margin: 10px 10px 81px 0;
    width: 1000px;
    margin: 0 auto;
}
.zy_about2_b {line-height: 2em;}
.zy_about2_b p{ margin-bottom:10px;}
.nr-z2_b {color: #353535;font-size: 13px;}
</style> -->