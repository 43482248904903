<template>
  <div class="noticebox">
    <div class="notice widths">
      <div class="notice_tit fl">通知公告</div>
      <div class="notice_text fl">
        <div id="gdtw">
          <ul class="items" v-for="i in [1,2]" :key='i'>
            <li class="item" v-for="notice in noticeList" :key="notice.to">
              <router-link :to="notice.url">{{ notice.name }}</router-link>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
export default {
  data() {
    return {
      noticeList: [
        {
          name: '郑重声明：关于我院未与“优仕教育、好未来教育、升兴教育”第三方机构合作招生的声明',
          to: ''

        },
        
        // {
        //   name: '致全院学生的一封信',
        //   to: ''
        // },
        // {
        //   name: '关于冒用国家开放大学软件学院及专业名义违规招生宣传的声明',
        //   to: ''
        // },
        // {
        //   name: '2019年春季学期考前工作会议通知',
        //   to: ''
        // },
        // {
        //   name: '关于国家开放大学2018年综合教学检查工作部署的通知',
        //   to: ''
        // },
        // {
        //   name: '关于开展“移动互联应用技术”专业培训的通知',
        //   to: ''
        // },
        // {
        //   name: '关于国家开放大学2017年教学检查及质量工作部署的通知',
        //   to: ''
        // },
        // {
        //   name: '软件学院关于召开电子商务技术专业研讨会的通知',
        //   to: ''
        // },
      ]
    }
  },
  methods: {
    async getNotList(){
      const res = await consult.getNotList(1, 10)
      console.log(res,'777')
      const list = res.data.data.list.records
      this.noticeList = list.map(it => {
        const {noticeTitle, noticeContent, noticeId} = it
        return {
          name: noticeTitle,
          content: noticeContent,
          id: noticeId,
          url: 'gonggao/'+noticeId
        }
      })
    }
  },
  mounted() {
    this.getNotList()
  
  }


}

</script>

<style lang="scss" scoped>
.noticebox {
  background: url(../image/notice.jpg) repeat-x;
}

.noticebox,
.notice {
  height: 41px;
}

.notice_tit {
  width: 111px;
  height: 26px;
  line-height: 26px;
  color: #fff;
  font-size: 14px;
  margin-top: 8px;
  margin-left: 7px;
  text-align: center;
  background: url(../image/noticetit.jpg) no-repeat;
}

.notice_text {
  width: 830px;
  padding-left: 15px;
  height: 41px;
  line-height: 41px;
  overflow: hidden;
}

.scroll_div {
  width: 830px;
  height: 41px;
  line-height: 41px;
  overflow: hidden;
  white-space: nowrap;
  float: left;
}

.scroll_div b {
  color: #560dcc;
}

#scroll_begin,
#scroll_end,
#scroll_begin p,
#scroll_end p {
  display: inline;
}

.notice_text a {
  width: 260px;
  margin-right: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
  :hover{
    color: #560dcc;
  }
}

/*公告无缝滚动*/
.moveBox {
  position: relative;
  width: 100%;
  margin: 0 auto px2rem(50);
  overflow: hidden;
  white-space: nowrap;
}

.move {
  position: absolute;
  width: px2rem(1700);
  top: 0;
  left: 100%;
  white-space: nowrap;
  animation: moveAni 15s infinite linear normal;
}

.item {
  float: left;
  width: px2rem(800);
  font-size: px2rem(20);
}

@keyframes moveAni {
  0% {
    left: 0;
  }

  100% {
    left: px2rem(-800);
  }
}

#gdtw {

  // width: 1000px;
  display: flex;
  .items {
    display: flex;
    flex-wrap: nowrap;
    animation: myMove 80s linear infinite; // 重点，定义动画
    animation-fill-mode: forwards;
    padding-right: 50%;
    &:hover {
      animation-play-state:paused;
    }
    .item {
      // height: 100%;
      // box-sizing: border-box;
      // margin-left: 100%; // 把文字弄出可见区域
      // width: 100%;
      // animation: myMove 10s linear infinite; // 重点，定义动画
      // animation-fill-mode: forwards;
      // padding-right: 100%;
      // transition: translateX(100%);
    }

    /*文字无缝滚动*/
    @keyframes myMove {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-100%);
      }
    }
  }
}
</style>
