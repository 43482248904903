<template>
  <div class="yxjj">
    <div class="lcright-02">
      <div class="main-04 linknav nav-z">
        <router-link to="/index">首页</router-link
        >&nbsp;&gt;&nbsp;学院概况&nbsp;&gt;&nbsp;<font color="blue"
          >院系简介</font
        >
      </div>
    </div>
    <div class="content" v-for="y of yxjjList" :key="y.title">
      <div class="content-title">{{ y.title }}</div>
      <div v-html="y.content" class="content-text"></div>
      <!-- <div class="empty"></div>
        <div class="content-text">伴随近年“互联网+”与传统制造业深度融合，软件和信息技术服务业继续呈现稳中向好的运行态势，吸纳就业人数平稳增加，软件和信息服务产业每年创造15%左右的新增人才需求。国内大学及大中专IT专业毕业生在近年中呈现增长态势，但仍无法满足行业快速扩张的人才需求。国家开放大学软件学院的建立，旨在为软件行业从业人员提供系统化的继续教育和软件新技术学习体系，为其他行业的软件使用人员提供便捷而灵活的软件应用技术学习途径，促进工业与信息化的深度融合。</div>
        <div class="empty"></div>
        <div class="content-text">软件学院发挥贴近企业、服务行业的特色，与东软集团合作共建移动互联应用技术专业（专科）。此专业旨在培养掌握移动互联和软件编程的基本理论和基本技能，具有团队协作能力和一定的技术创新能力，能在移动智能应用开发、手机游戏开发和移动网站建设等领域中从事一线岗位工作的移动开发实用人才。</div>
        <div class="empty"></div>
        <div class="content-text">学院下设学习中心。为保证教学质量，学习中心要求有行业和企业背景、有办学能力、招生和人才供应能力。目前学习中心以大型企业、软件协会、行业培训机构合作单位为主。学院对学习中心进行精细化、动态化管理，保持整个体系的活力。</div>
        <div class="empty"></div>
        <div class="content-text">软件学院作为继续教育学习成果认证、积累与转换试点单位，已完成学分银行各项申报审核，正式被批准成为学习成果互认联盟成员单位。依据学分银行资历框架软件学院完成行业职业结构的梳理，并挑选了网络管理工程师、Web前端开发工程师、移动应用开发工程师、平面设计师四个典型的职业证书，组织完成对应认证单元学习成果与和评价标准的编写，对学历教育学习成果与认证单元比对，非学历教学学习成果与认证单元比对，两类学习成果对应关系进行了初步设计。学院还在体系范围内进行了学历学分与非学历证书的互认互换试点，部分学员在相关学分课程完成后，通过成果互认顺利的申领到行业技术水平认定证书。</div>
        <div class="empty"></div>
        <div class="content-text">非学历教育方面，为促进产教融合，探索软件与信息服务行业应用型人才培养模式改革和创新，学院联合中国软件行业协会教育培训委员会发起“全国软件与信息服务人才培养工程”项目，旨在为全国软件与信息服务类企业、中高职院校搭建沟通与合作平台，为学生提供技能培训、认证、实习实训、学历提升以及就业创业整体解决方案。</div>
        <div class="empty"></div> -->
    </div>
  </div>
</template>
<script>
import consult from '@/api/yssz/consult'
export default {
  data() {
    return {
      yxjjList: [
        {
          title: '1',
          date: '2022',
          content: '',
          id: '',
        },
      ],
    }
  },
  methods: {
    async getYxjjList() {
      const res = await consult.getYxjjList(1, 1)
      const list = res.data.data.list.records
      this.yxjjList = list.map((yxjj) => {
        const { alwaysTitle, createTime, alwaysId, alwaysContent } = yxjj
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getYxjjList()
  },
}
</script>

<style lang="scss" scoped>
.yxjj {
  // width: 100%;
  // margin-left: 30px;
  // // height: 493px;
  margin: -200px auto;
}
.top {
  /* float: right;
       display: flex; */
  height: 60px;
  border-bottom: 1px solid #cdd1d4;
  width: 100%;
  .right-title {
    display: flex;
    float: right;
    margin-top: 25px;
    width: 100%;
    font-size: 15px;
  }
}
.content {
  // display: flex;
  // margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 80px;
  .content-title {
    font-weight: bold;
    font-size: 15px;
    height: 30px;
  }
  .content-text {
    font-size: 15px;
    text-indent: 2em;
    line-height: 30px;
    padding-bottom: 200px;
  }
  .empty {
    height: 15px;
    width: 100%;
  }
}
.lcright-02 {
  border-bottom: 1px solid #dadada;
  height: 54px;
  position: relative;
  width: 725px;
}
.main-04 {
  height: 22px;
  line-height: 22px;
  position: absolute;
  right: 4px;
  text-align: right;
  top: 25px;
  width: 500px;
  z-index: 1;
}
.nav-z {
  color: #898989;
  font-size: 12px;
}
</style>
