<template>
  <div class="luqu_13">
    <div class="lcrightl-02_13">
      <div class="mainl-04_13 linknav nav-zl_13">
        <router-link to="/index">首页</router-link
        >&nbsp;&gt;&nbsp;招生支持服务&nbsp;&gt;&nbsp;<font color="blue"
          >录取查询</font
        >
      </div>
    </div>
    <div class="lcright-03_13">
      <div class="zy_about_13 nr-z2_13" v-for="LQ of luquList" :key="LQ.title">
        <p v-html="LQ.content"></p>
        <!-- <p><p>
	学生在获取入学通知书后，可到&ldquo;中国高等教育学生信息网（学信网）&ldquo;查询学籍信息。</p>
<p>
	步骤1：打开学信网（<a href="https://www.chsi.com.cn/">https://www.chsi.com.cn/</a>），点击&rdquo;学籍查询&ldquo;</p>
<p> -->
        <!-- <img alt="" :src="LQ.content" style="width: 1349px; height: 723px;" /></p> -->
        <!-- <p>
	步骤2：注册学信网账号</p>
<p>
	<img alt="" src="../../assets/imgs/index/2019082842434685.jpg" style="width: 1361px; height: 730px;" /></p>
<p>
	步骤3：登录学信档案</p>
<p>
	<img alt="" src="../../assets/imgs/index/2019082850157557.jpg" style="width: 1366px; height: 736px;" /></p>
<p>
	步骤4：获取学籍信息查询结果</p>
<p>
	<img alt="" src="../../assets/imgs/index/2019082850181721.png" style="width: 1366px; height: 789px;padding-bottom: 200px;" /></p>
</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import consult from "@/api/yssz/consult";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  // name: "#jxjwgl",
  data() {
    return {
      img: "",
      luquList: [
        {
          title: "1",
          content: "2022",
          id: "",
        },
      ],
    };
  },

  methods: {
    async getLqList() {
      const res = await consult.getLqList(1, 8);
      console.log(res, "333");
      const list = res.data.data.list.records;
      this.luquList = list.map((it) => {
        const { alwaysTitle, alwaysContent, alwaysId } = it;
        return {
          title: alwaysTitle,
          content: alwaysContent,
          id: alwaysId,
        };
      });
    },
  },
  mounted() {
    this.getLqList();
  },
};
</script>

<style>
.luqu_13 {
  margin: -200px auto;
}
.lcrightl-02_13 {
  border-bottom: 1px solid #dadada;
  height: 54px;
  position: relative;
  width: 725px;
}
.lcright-03_13 {
  margin: 10px 10px 81px 0;
  width: 715px;
}
.zy_about_13 {
  line-height: 2em;
  width: 715px;
}
.zy_about_13 p {
  margin-bottom: 10px;
}
.mainl-04_13 {
  height: 22px;
  line-height: 22px;
  position: absolute;
  right: 4px;
  text-align: right;
  top: 25px;
  width: 500px;
  z-index: 1;
}
.nav-zl_13 {
  color: #898989;
  font-size: 12px;
}
.nr-z2_13 {
  color: #353535;
  font-size: 13px;
  padding-bottom: 200px;
}
</style>
