<template>
  <div class="con widths">
    <div class="flor1" style="overflow: hidden">
      <div class="banner fl">
        <div id="fsD1" class="focus">
          <div id="D1pic1" class="fPic">
            <div
              v-show="show == i"
              class="fcon"
              v-for="(banner, i) of BannerList"
              :key="banner.id"
            >
              <router-link target="_blank" :to="bannerUrl + banner.id">
                <img :src="banner.content" style="opacity: 1" />
              </router-link>
              <span class="shadow">
                <a target="_blank">
                  <!-- 软件学院多位教师参加国家开放大学教务管理骨干教师研修班 -->
                  {{ banner.title }}
                </a>
              </span>
            </div>
          </div>
          <div class="fbg">
            <div class="D1fBt" id="D1fBt">
              <a
                hidefocus="true"
                target="_self"
                :class="{ current: show == i }"
                v-for="(item, i) in bannerList"
                @click="theShow(i)"
                :key="i"
                ><i>1</i></a
              >
            </div>
          </div>
          <span class="prev" @click="bannerPrev"></span>
          <span class="next" @click="bannerNext"></span>
        </div>
      </div>
      <div class="news fr">
        <div class="newstit">
          <span><router-link to="/yuanxiao">More</router-link></span>
          <div class="newstittext">
            <h2>院校新闻\</h2>
            <font>News</font>
          </div>
        </div>
        <div class="newsbox" v-for="i of NewsList" :key="i.id">
          <!-- <ul>
            <li> -->
          <div class="newspic">
            <router-link :to="newsUrl + i.id">
              <img :src="i.img" width="93" height="75" />
            </router-link>
          </div>
          <div class="newstext">
            <h2>
              <router-link class="new12" :to="newsUrl + i.id">{{
                i.title
              }}</router-link>
            </h2>
            <p>
              <router-link
                class="new12"
                :to="newsUrl + i.id"
                v-html="i.content"
              ></router-link>
            </p>
          </div>
          <!-- </li> -->

          <!-- <li>
              <div class="newspic fl"><a href="javascript:;"><img src="static/picture/2020082538949093.jpg" width="93"
                    height="75"></a></div>
              <div class="newstext fr">
                <h2><a href="javascript:;" title="学院电子商务技术专业（专科）核心课程大纲通过审定">学院电子商务技术专业（专科）核心..</a></h2>
                <p><a href="javascript:;" title="学院电子商务技术专业（专科）核心课程大纲通过审定"> 软件学院自诞生之初，一直致力于发挥行业优势、融合行业优质资源，..</a></p>
              </div>
            </li>

            <li>
              <div class="newspic fl"><a href="javascript:;"><img src="static/picture/2021051054313201.jpg" width="93"
                    height="75"></a></div>
              <div class="newstext fr">
                <h2><a href="javascript:;" title="同心战“疫”——我院向湖北电大、武汉电大、国开总部捐赠防疫物资">同心战“疫”——我院向湖北电大、..</a></h2>
                <p><a href="javascript:;" title="同心战“疫”——我院向湖北电大、武汉电大、国开总部捐赠防疫物资"> 疫情无情，人间有爱。2月26日中午，饱含软件学院深厚情谊的20..</a>
                </p>
              </div>
            </li> -->

          <!-- </ul> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import consult from '@/api/yssz/consult'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      show: 0,
      bannerList: [0, 1],
      interKey: '',
      timer: 2000,
      img: '',
      BannerList: [
        {
          title: '1',
          content: '2022',
          id: '',
        },
      ],
      bannerUrl: '/yemian/',
      NewsList: [
        {
          title: '1',
          content: '2022',
          id: '',
          img: '',
        },
      ],
      newsUrl: '/yuanxiao/',
    }
  },
  watch: {
    show() {},
  },
  methods: {
    bannerNext() {
      if (this.show >= this.bannerList.length - 1) {
        this.show = 0
      } else {
        this.show += 1
      }
    },
    bannerPrev() {
      if (this.show <= 0) {
        this.show = this.bannerList.length - 1
      } else {
        this.show -= 1
      }
    },
    theShow(i) {
      this.show = i
    },
    idToRouter(id) {
      return '/yemian/' + id
    },
    async getBannerList() {
      const res = await consult.getBannerList(1, 2)
      console.log(res, '555')
      const list = res.data.data.list.records

      this.BannerList = list.map((banner) => {
        const { slideshowTitle, slideshowCover, slideshowId } = banner
        return {
          title: slideshowTitle,
          content: slideshowCover,
          id: slideshowId,
        }
      })
    },
    idToRouter(id) {
      return '/yuanxiao/' + id
    },
    async getNewsList() {
      const res = await consult.getNewsList(1, 3)
      console.log(res, '666')
      const list = res.data.data.list.records
      this.NewsList = list.map((it) => {
        const { pressTitle, pressContent, pressId, pressCover } = it
        let el = document.createElement('div')
        el.innerHTML = pressContent
        const content = Array.from(el.getElementsByTagName('p'))
          .map((it) => it.innerText)
          .join(' ')
        console.log(el.getElementsByTagName('p'), 'sjdf', pressContent)
        return {
          title: pressTitle,
          content,
          id: pressId,
          img: pressCover,
        }
      })
    },
  },
  mounted() {
    this.interKey = setInterval(() => {
      this.bannerPrev()
    }, this.timer)
    this.getBannerList()
    this.getNewsList()
  },
  beforeDestroy() {
    clearInterval(this.interKey)
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/reset.scss';
.topbox,
.navbox,
.noticebox,
.con,
.footbox {
  min-width: 1000px;
}
.con,
.flor1,
.flor2,
.flor3,
.flor4,
.footbox,
.conbox_zy,
.con_zy {
  display: block;
  overflow: hidden;
  _zoom: 1;
}
.con {
  padding: 20px 0px 50px;
}
.widths {
  width: 1000px;
  margin: 0px auto;
}

/*内容*/
.con,
.flor1,
.flor2,
.flor3,
.flor4,
.footbox,
.conbox_zy,
.con_zy {
  display: block;
  _zoom: 1;
}

.con {
  padding: 20px 0px 50px;
  margin: 0 auto;
}

.flor1,
.news {
  height: 350px;
  overflow: hidden;
}

/*内容 banner*/
.banner {
  width: 658px;
}

/*焦点图*/
.focus {
  position: relative;
  width: 658px;
  height: 350px;
  background-color: rgba(0, 0, 0, 0.6);
  float: left;
}

.focus img {
  width: 658px;
  height: 350px;
}

.focus .shadow .title {
  width: 260px;
  height: 65px;
  padding-left: 30px;
  padding-top: 20px;
}

.focus .shadow .title a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: bolder;
  overflow: hidden;
}

.focus .btn {
  position: absolute;
  bottom: 34px;
  left: 510px;
  overflow: hidden;
  zoom: 1;
}

.focus .btn a {
  position: relative;
  display: inline;
  width: 13px;
  height: 13px;
  border-radius: 7px;
  margin: 0 5px;
  color: #b0b0b0;
  font: 12px/15px '\5B8B\4F53';
  text-decoration: none;
  text-align: center;
  outline: 0;
  float: left;
  background: #d9d9d9;
}

.focus .btn a:hover,
.focus .btn a.current {
  cursor: pointer;
  background: blue;
}

.focus .fPic {
  position: absolute;
  left: 0px;
  top: 0px;
}

.focus .D1fBt {
  overflow: hidden;
  zoom: 1;
  height: 16px;
  z-index: 10;
}

.focus .shadow {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0px;
  z-index: 10;
  height: 44px;
  line-height: 44px;
  background: rgba(0, 0, 0, 0.6);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#80000000', endColorstr='#80000000')\9;
  display: block;
  text-align: left;
}

.focus .shadow a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  overflow: hidden;
  margin-left: 10px;
  font-family: '微软雅黑', Microsoft Yahei;
}

.focus .fcon {
  position: relative;
  width: 100%;
  float: left;
  background: rgba(0, 0, 0, 0.6);
}

.focus .fcon img {
  display: block;
}

.focus .fbg {
  bottom: 15px;
  right: 15px;
  position: absolute;
  height: 12px;
  text-align: center;
  z-index: 200;
}

.focus .fbg div {
  margin: 0px auto;
  overflow: hidden;
  zoom: 1;
  height: 12px;
}

.focus .D1fBt a {
  position: relative;
  display: inline;
  width: 12px;
  height: 12px;
  border-radius: 7px;
  margin: 0 5px;
  color: #b0b0b0;
  font: 12px/15px '\5B8B\4F53';
  text-decoration: none;
  text-align: center;
  outline: 0;
  float: left;
  background: #d9d9d9;
}

.focus .D1fBt .current,
.focus .D1fBt a:hover {
  background: blue;
}

.focus .D1fBt img {
  display: none;
}

.focus .D1fBt i {
  display: none;
  font-style: normal;
}

.focus .prev,
.focus .next {
  position: absolute;
  width: 40px;
  height: 74px;
  background: url(../image/focus_btn.png) no-repeat;
}

.focus .prev {
  top: 50%;
  margin-top: -37px;
  left: 0;
  background-position: 0 0;
  cursor: pointer;
}

.focus .next {
  top: 50%;
  margin-top: -37px;
  right: 0;
  background-position: -40px 0;
  cursor: pointer;
}

.focus .prev:hover {
  background-position: 0 -74px;
}

.focus .next:hover {
  background-position: -40px -74px;
}

/*内容 新闻中心*/
.news {
  width: 322px;
}

.newstit {
  height: 31px;
  line-height: 30px;
  *line-height: 31px;
  border-bottom: 2px solid #999999;
  background: url(http://rjxy.ouchn.edu.cn/images/blaline.png) no-repeat center
    bottom;
  padding-right: 3px;
  font-size: 18px;
}
$colorStyle: blue;
.newstittext {
  float: left;
  border-bottom: 2px solid $colorStyle;
  // padding: -10px 3px;
  margin: -2px 0;
  width: 130px;
  _width: 140px;
}

.newstittext h2 {
  font-size: 18px;
  float: left;
  margin-right: 4px;
}

.newstittext font {
  color: #999999;
  font-size: 18px;
  margin-left: 3px;
}

.newstit span a {
  font-size: 14px;
}

.newstit span a:hover {
  color: $colorStyle;
}

.newstit span {
  float: right;
  background: url(../image/more.jpg) no-repeat right center;
  padding-right: 20px;
}

.newsbox {
  height: 90px;
  // overflow: hidden;
  // padding: 0px 5px;
  width: 322px;
  // padding-top: 15px;
  // padding-bottom: 15px;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
  .newspc a {
    float: left;
    width: 93px;
    .img {
      height: 75px;
      width: 93px;
    }
  }
  .newstext {
    margin-left: 15px;
    width: 205px;
    line-height: 23px;
    float: right;
    h2 {
      font-size: 12px;
      line-height: 28px;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.new12 {
  color: black;
}

.newsbox li {
  height: 90px;
  padding-top: 15px;
  border-bottom: 1px solid #ebebeb;
}

.newsbox li .newspic {
  width: 93px;
}

.newsbox li .newspic img {
  width: 93px;
  height: 75px;
}

.newsbox li .newstext {
  width: 205px;
  line-height: 23px;
}

.newsbox li .newstext h2 {
  font-size: 12px;
  color: $colorStyle;
  line-height: 28px;
  white-space: nowrap;
}

.newsbox li .newstext h2 a {
  color: $colorStyle;
}

/*3新闻*/
.flor2 {
  padding: 30px 8px 35px;
}

.flor4 {
  padding: 0px 8px;
}

.news_zhaosheng,
.news_jiaowu,
.news_hangye {
  width: 286px;
  height: 344px;
}

.news_line {
  background: url(../image/newsline.jpg) no-repeat center 52px;
  width: 63px;
  height: 253px;
}

.newstit2 {
  height: 39px;
  line-height: 39px;
  border-bottom: 2px solid #999999;
  background: url(http://rjxy.ouchn.edu.cn/images/blaline.png) no-repeat center
    bottom;
  padding-right: 3px;
  font-size: 18px;
}

.newstittext2 h2 {
  font-size: 18px;
  margin-top: -2px;
  padding-right: 3px;
  border-bottom: 2px solid $colorStyle;
  display: block;
  float: left;
}

.newstit2 span a {
  font-size: 14px;
}

.newstit2 span a:hover {
  color: $colorStyle;
}

.newstit2 span {
  float: right;
  background: url(../image/more.jpg) no-repeat right center;
  padding-right: 20px;
}

.newslistpic {
  width: 286px;
  height: 118px;
  margin-top: 13px;
}

.newslistpic img {
  width: 276px;
  height: 108px;
  padding: 4px;
  border: 1px solid #dedede;
}

.newslisttext {
  height: 160px;
  overflow: hidden;
  padding: 3px 10px 0px 5px;
}

.newslisttext li {
  line-height: 32px;
}

.newslisttext li span {
  float: left;
  width: 75px;
  color: $colorStyle;
  margin-right: 15px;
}

.newslisttext li a {
  display: block;
  float: left;
  width: 178px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.newslisttext_about {
  line-height: 32px;
  height: 160px;
  overflow: hidden;

  padding: 3px 5px 0px;
}

.newslisttext_about a {
  color: #343434;
}

.newslisttext_about a:hover {
  color: blue;
}
</style>
