<template>
    <div class="jxjwgl">
      <div class="lcright-02">
        <div class="main-04 linknav nav-z">
          <router-link to="/index">首页</router-link
          >&nbsp;&gt;&nbsp;教学支持服务&nbsp;&gt;&nbsp;<font color="blue"
            >教学教务管理</font
          >
        </div>
      </div>
      <div class="content">
        <div class="guanli" v-for="JX of alwaysList" :key="JX.title">
          <router-link class="item" :to="alwaysUrl + JX.id">
          <a href="">{{ JX.title }}</a>
          <span>{{ JX.date }}</span>
          </router-link>
        </div>
        <!-- <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
          <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
          <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
          <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
          <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
          <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
          <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div> -->
<Paning :total="total" :count="count"></Paning>
      </div>
    </div>
  </template>
  <script>
  import Paning from "@/components/Paning.vue"
  import consult from "@/api/yssz/consult";
  export default {
    components:{
    Paning,
  },
    data() {
      return {
        img: "",
        alwaysList: [
          {
            title: "1",
            date: "2022",
            id: "",
          },
        ],
      count: '15',
      page: '1',
      alwaysUrl:'/jxjwgl/'
      }
    },
    computed:{
    pageList(){
      console.log(this.alwaysList.slice(this.count * this.page-1, this.count * this.page),'j')
      return this.alwaysList.slice(this.count * (this.page-1), this.count * this.page)
    },
    total(){
      return this.alwaysList.length
    }
  },
watch:{
    '$route.query.page'(){
      this.page = this.$route.query.page
      if(!this.page) {
        this.$router.replace({query: {...this.$route.query, page: 1}})
      }
    }
  },
    methods: {
      idToRouter(id) {
          return '/jxjwgl/'+id
        },
      async getAlwList() {
        const res = await consult.getAlwList(1, 8);
        console.log(res, "lll");
        const list = res.data.data.list.records;
        if(!this.$route.query.page)
        this.$router.replace({query: {...this.$route.query, page: 1}})
        console.log(this.alwaysList, '111')
        this.alwaysList = list.map((it) => {
          const { alwaysTitle, createTime, alwaysId } = it;
          return {
            title: alwaysTitle,
            date: createTime.split("T")[0],
            id: alwaysId,
          };
        });
      },
    },
    mounted() {
      this.getAlwList();
    },
  };
  </script>
  <style lang="scss" scoped>
  .jxjwgl {
    margin: -200px auto;
  }
  .lcright-02 {
    border-bottom: 1px solid #dadada;
    height: 54px;
    position: relative;
    width: 725px;
  }
  .main-04 {
    height: 22px;
    line-height: 22px;
    position: absolute;
    right: 4px;
    text-align: right;
    top: 25px;
    width: 500px;
    z-index: 1;
  }
  .nav-z {
    color: #898989;
    font-size: 12px;
  }
  .content {
    width: 720px;
    margin-bottom: 80px;
    .guanli {
      height: 45px;
      line-height: 45px;
      border-bottom: 1px dotted #b8bcbe;
      font-size: 13px;
      background: url(@/assets/imgs/index/blaicon.gif) no-repeat left center;
      .item{
        display: flex;
        justify-content:space-between;
      }
      span {
        color: #a2a6a8;
      }
      a {
        color: #000000;
        margin-left: 5px;
      }
      a:hover {
        color: #0189ff;
      }
    }
    DIV.fanye_15 {
      clear: both;
      margin: 35px 0px 18px 0px;
      *margin: 25px 0px 35px 0px;
      display: block;
      overflow: hidden;
      _zoom: 1;
      height: 23px;
      line-height: 23px;
      text-align: center;
      width: 100%;
    }
    DIV.fanye_15 A {
      background: #e6e6e6;
      color: #333;
      margin-right: 8px;
      padding: 8px 10px;
      text-decoration: none;
    }
    DIV.fanye_15 A:hover {
      background: blue;
      color: #fff;
      margin-right: 8px;
    }
    DIV.fanye_15 A:active {
      background: blue;
      color: #fff;
      margin-right: 8px;
    }
    DIV.fanye_15 SPAN.current_15 {
      background: blue;
      color: #fff;
      font-weight: bold;
      margin-right: 8px;
      padding: 8px 10px;
    }
    DIV.fanye_15 SPAN.disabled_15 {
      color: #333;
      margin-right: 8px;
      padding: 8px 10px;
    }
  }
      
  </style>
  