<template>
<div class="bkzy">
    <div class="lcright-02">
                 <div class="main-04 linknav nav-z">
                  <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;学历教育&nbsp;&gt;&nbsp;<font color="blue">单考单招</font>
                  </div> 
              </div>
    <div v-for="d of dkdzList" :key="d.title" class="content">
        <div v-html="d.content" class="content-title" >
    </div>
    </div>
    
    </div>
</template>
<script>
import consult from '@/api/yssz/consult'
export default {
  data() {
   return {
    dkdzList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ],
   }
},
methods: {
    async getDkdzList(){
      const res = await consult.getDkdzList(1, 1)
      console.log(res,'dkdz')
      const list = res.data.data.list.records
      this.dkdzList = list.map(dkdz => {
        const {alwaysTitle,createTime, alwaysId, alwaysContent} = dkdz
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getDkdzList()
  }
};
</script>
<style lang="scss" scoped>
.bkzy{
    margin: -200px auto;
    }
.lcright-02 {border-bottom: 1px solid #dadada;height: 54px;position: relative;width: 725px;}
.main-04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z {color: #898989;font-size: 12px;}
    // .top{
    //    /* float: right;
    //    display: flex; */
    //    height: 60px;
    //    border-bottom: 1px solid #cdd1d4;
    //    width: 100%;
    //    .right-title{
    //        display: flex;
    //        float: right;
    //        margin-top: 25px;
    //        width: 100%;
    //        font-size: 15px;
    //     }
    // }
   .content{
        // display: flex;
        // margin-top: 8px;
        padding-top: 8px;
        padding-bottom: 80px;
        .content-title{
            font-weight: bold;
            font-size: 15px;
            height: 30px;
        }
        .content-text{
            font-size: 15px;
            text-indent: 2em;
            line-height: 30px;
        }
        .empty{
            height:15px;
            width: 100%;
        }
    }
</style>