<template>
  <div class="con widths">
    <div class="flor2">
      <theCard></theCard>

      <div class="news_line fl"></div>
      <div class="news_zhaosheng fl">
        <div class="newstit2">
          <span><router-link to="/admission">More</router-link></span>
          <div class="newstittext2">
            <h2>招生资讯</h2>
          </div>
        </div>
        <div class="newslist">
          <div class="newslistpic"> <img src="../static/picture/2020042455072921.jpg" width="276" height="108">
          </div>
          <div class="newslisttext">
            <ul>
              <li v-for="con of consuList"  :key="con.title">
                <span><router-link :to="consuUrl+con.id">[{{con.date}}]</router-link></span>
                <router-link :to="consuUrl+con.id">
                  {{con.title}}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="news_line fl"></div>
      <div class="news_jiaowu fl">
        <div class="newstit2">
          <span><router-link to="/homeguide" target="_blank">More</router-link></span>
          <div class="newstittext2">
            <h2>入学指南</h2>
          </div>
        </div>
        <div class="newslist">
          <div class="newslistpic">
            <p>
              <img alt="" src="../static/picture/2014122936231297.jpg" style="width: 277px; height: 108px">
            </p>
          </div>
          <div class="newslisttext_about">
            <p v-for="(e, i) of entranceList" :key="e.title" class="text">
                <router-link :to="map[i]">{{e.title}}</router-link>
                <!-- <a href="javascript:;">:{{a.content}}</a> -->
              </p>
            
            <!-- <p>
              常用链接：<a href="javascript:;">中国软件行业协会</a>&nbsp; &nbsp; &nbsp;&nbsp;<a href="javascript:;">国家开放大学</a></p>
            <p>
              <a href="javascript:;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</a><a
                href="javascript:;" microsoft=""
                style="margin: 0px; padding: 0px; text-decoration-line: none; color: rgb(201, 22, 30); font-family: 微软雅黑, ">国家开放大学</a><a
                href="javascript:;" microsoft=""
                style="margin: 0px; padding: 0px; text-decoration-line: none; color: rgb(102, 102, 102); font-family: 微软雅黑, ">软件学院学习平台</a>
            </p>
            <p>
              <a href="javascript:;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 国家开放大学学习平台</a>
            </p>
            <p>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p> -->

          </div>
        </div>
      </div>
    </div>
    <div class="flor4">
      <div class="news_zhaosheng fl">
        <div class="newstit2">
          <span><router-link to="/profession">More</router-link></span>
          <div class="newstittext2">
            <h2>开设专业</h2>
          </div>
        </div>
        <div class="newslist">

          <div class="newslistpic">
            <p>
              <img alt="" src="../static/picture/2014122937610877.jpg" style="width: 276px; height: 108px">
            </p>
          </div>

          <div class="newslisttext_about">
            <div id="a_demo_1" style="overflow:hidden; height:160px; margin:auto;">
              <div id="a_demo1_1">
                <p v-for="maj of majorList" :key="maj.title">
                  <!-- <a href="javascript:;">移动互联应用技术（专科）</a> -->
                   <a><router-link :to="majorUrl + maj.id">{{maj.title}}({{maj.status}})</router-link></a>
                </p>
              </div>
              <div id="a_demo1_2"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="news_line fl"></div>
      <div class="news_jiaowu fl">
        <div class="newstit2">
          <span><router-link to="/index/12">More</router-link></span>
          <div class="newstittext2">
            <h2>名师名教</h2>
          </div>
        </div>
        <div class="newslist">
          <div class="newslistpic">
            <p>
              <img alt="" src="../static/picture/2014122937108421.jpg" style="width: 277px; height: 108px">
            </p>
          </div>
          <div class="newslisttext_about2">
            <div id="b_demo_1" style="overflow:hidden; height:160px; margin: auto;">
              <div id="b_demo1_1">
                <!-- <p v-html="teacher.content"> -->
                <p v-for="tea of teacherList" :key="tea.title" style="margin:  auto">
                  <router-link :to="teacherUrl + tea.id">{{tea.title}}</router-link>
                  <a style="margin-left:10px">{{tea.major}}</a> 
                 <!-- <a v-html = "tea.content" ></a> -->
                  <!-- <a v-html="teacher.content"></a>
                   
                   <a href="javascript:;">郝明明&nbsp; &nbsp;移动开发</a> -->
                </p>
                <!-- <p>
                  <a href="javascript:;">周国威&nbsp; &nbsp;Android应用/系统开发</a>
                </p>
                <p>
                  <a href="javascript:;">王亚洲&nbsp; &nbsp;Android开发</a>
                </p>
                <p>
                  <a href="javascript:;"><span style="font-size:12px;"><span microsoft=""
                        style="color: rgb(102, 102, 102); font-family: 微软雅黑, " text-align:="">贾帅&nbsp;
                        &nbsp;</span><span microsoft=""
                        style="color: rgb(102, 102, 102); font-family: 微软雅黑, ">Android开发</span></span></a>
                </p>
                <p>
                  <a href="javascript:;">王桢&nbsp; &nbsp;技术总监</a>
                </p>
                <p>
                  <a href="javascript:;"><span style="font-size:12px;"><span microsoft=""
                        style="color: rgb(102, 102, 102); font-family: 微软雅黑, " text-align:="">段玉&nbsp;
                        &nbsp;设计总监</span></span></a>
                </p>
                <p>
                  <a href="javascript:;"><span style="font-size:12px;"><span microsoft=""
                        style="color: rgb(102, 102, 102); font-family: 微软雅黑, " text-align:="">马锋&nbsp;
                        &nbsp;</span><span microsoft=""
                        style="color: rgb(102, 102, 102); font-family: 微软雅黑, ">软硬件开发</span></span></a>
                </p>
                <p>
                  <a href="javascript:;" target="_blank">刘苍松</a>&nbsp;Java技术<br>
                  <a href="javascript:;" target="_blank">李新刚</a>&nbsp;网络营销<br>
                  <a href="javascript:;" target="_blank">刘&nbsp;&nbsp;&nbsp; 涛</a>&nbsp;Adobe&nbsp;
                  &nbsp;Macromedia&nbsp; &nbsp;NIT-Pro<br>
                  <a href="javascript:;" target="_blank">刘老师</a>&nbsp;Java技术<br>
                  <a href="javascript:;" target="_blank">苏老师</a>&nbsp;系统架构<br>
                  <a href="javascript:;" target="_blank">许老师</a>&nbsp;Java技术<br>
                  <a href="javascript:;" target="_blank">赵老师</a>&nbsp;嵌入式
                </p> -->
              </div>
              <div id="b_demo1_2">
              </div>
            </div>
            <div id="b_demo_2" style="overflow:hidden; height:160px; margin: auto;">
              <div id="b_demo1_1">
                <!-- <p v-html="teacher.content"> -->
                <p v-for="tea of teacherList" :key="tea.title" style="margin:  auto">
                  <router-link :to="teacherUrl + tea.id">{{tea.title}}</router-link>
                  <a style="margin-left:10px">{{tea.major}}</a> 
                 <!-- <a v-html = "tea.content" ></a> -->
                  <!-- <a v-html="teacher.content"></a>
                   
                   <a href="javascript:;">郝明明&nbsp; &nbsp;移动开发</a> -->
                </p>
                <!-- <p>
                  <a href="javascript:;">周国威&nbsp; &nbsp;Android应用/系统开发</a>
                </p>
                <p>
                  <a href="javascript:;">王亚洲&nbsp; &nbsp;Android开发</a>
                </p>
                <p>
                  <a href="javascript:;"><span style="font-size:12px;"><span microsoft=""
                        style="color: rgb(102, 102, 102); font-family: 微软雅黑, " text-align:="">贾帅&nbsp;
                        &nbsp;</span><span microsoft=""
                        style="color: rgb(102, 102, 102); font-family: 微软雅黑, ">Android开发</span></span></a>
                </p>
                <p>
                  <a href="javascript:;">王桢&nbsp; &nbsp;技术总监</a>
                </p>
                <p>
                  <a href="javascript:;"><span style="font-size:12px;"><span microsoft=""
                        style="color: rgb(102, 102, 102); font-family: 微软雅黑, " text-align:="">段玉&nbsp;
                        &nbsp;设计总监</span></span></a>
                </p>
                <p>
                  <a href="javascript:;"><span style="font-size:12px;"><span microsoft=""
                        style="color: rgb(102, 102, 102); font-family: 微软雅黑, " text-align:="">马锋&nbsp;
                        &nbsp;</span><span microsoft=""
                        style="color: rgb(102, 102, 102); font-family: 微软雅黑, ">软硬件开发</span></span></a>
                </p>
                <p>
                  <a href="javascript:;" target="_blank">刘苍松</a>&nbsp;Java技术<br>
                  <a href="javascript:;" target="_blank">李新刚</a>&nbsp;网络营销<br>
                  <a href="javascript:;" target="_blank">刘&nbsp;&nbsp;&nbsp; 涛</a>&nbsp;Adobe&nbsp;
                  &nbsp;Macromedia&nbsp; &nbsp;NIT-Pro<br>
                  <a href="javascript:;" target="_blank">刘老师</a>&nbsp;Java技术<br>
                  <a href="javascript:;" target="_blank">苏老师</a>&nbsp;系统架构<br>
                  <a href="javascript:;" target="_blank">许老师</a>&nbsp;Java技术<br>
                  <a href="javascript:;" target="_blank">赵老师</a>&nbsp;嵌入式
                </p> -->
              </div>
              <div id="b_demo1_2">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="news_line fl"></div>
      <div class="news_hangye fl">
        <div class="newstit2">
          <span><router-link to="/tskc">More</router-link></span>
          <div class="newstittext2">
            <h2>特色课程</h2>
          </div>
        </div>
        <div class="newslist">
          <div class="newslistpic" style="overflow:hidden;position: relative;z-index: 5;">
            <p>
              <img alt="" src="../static/picture/2014122937716469.jpg" style="width: 277px; height: 108px;">
            </p>
          </div>
          <div class="newslisttext_about2">
            <div id="c_demo_1" class="one" style=" margin:auto;">
              <div id="c_demo1_1">
                <p v-for="cou of courseList" :key="cou.title">
                  <a><router-link :to="courseUrl+cou.id">{{cou.title}}</router-link></a>
                </p>
                <!-- <p>
                  <a href="javascript:;">Android核心开发技术</a>
                </p>
                <p>
                  <a href="javascript:;">Android网络开发技术</a>
                </p>
                <p>
                  <a href="javascript:;">移动应用界面设计</a>
                </p>
                <p>
                  <a href="javascript:;">移动服务接口开发技术</a>
                </p>
                <p>
                  <a href="javascript:;">Oracle数据库编程</a>
                </p>
                <p>
                  <a href="javascript:;">移动商务界面设计与制作</a>
                </p>
                <p>
                  <a href="javascript:;">电子商务网站前端开发</a>
                </p>
                <p>
                  <a href="javascript:;">常用数据库基础</a>
                </p>
                <p>
                  <a href="javascript:;">电子商务数据分析与应用</a>
                </p>
                <p>
                  <a href="javascript:;">新媒体运营推广技术</a>
                </p> -->
              </div>
              <div id="c_demo1_2">
                <p v-for="cou of courseList" :key="cou.title">
                  <a><router-link :to="courseUrl+cou.id">{{cou.title}}</router-link></a>
                </p>
                <!-- <p>
                  <a href="javascript:;">Android核心开发技术</a>
                </p>
                <p>
                  <a href="javascript:;">Android网络开发技术</a>
                </p>
                <p>
                  <a href="javascript:;">移动应用界面设计</a>
                </p>
                <p>
                  <a href="javascript:;">移动服务接口开发技术</a>
                </p>
                <p>
                  <a href="javascript:;">Oracle数据库编程</a>
                </p>
                <p>
                  <a href="javascript:;">移动商务界面设计与制作</a>
                </p>
                <p>
                  <a href="javascript:;">电子商务网站前端开发</a>
                </p>
                <p>
                  <a href="javascript:;">常用数据库基础</a>
                </p>
                <p>
                  <a href="javascript:;">电子商务数据分析与应用</a>
                </p>
                <p>
                  <a href="javascript:;">新媒体运营推广技术</a>
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import theCard from './theCard.vue';
import consult from '@/api/yssz/consult'
export default {
  data() {
    return {
      img: '',
      consuList:[
        {
          title: '1',
          date: '2022',
          id: '',
        },
      ],
      consuUrl: '/admission/', 
      entranceList:[
    {
            title: '1',
            content: '2022',
            id: '',
            date: '2022'
          },
  
  ],
  map: [
                  '/condition',
                  '/process',
                  '/net',
                  '/standard',
                  '/link'
                ],
      majorList:[
        {
          title: '1',
          date: '2022',
          id: '',
        },
      ],
      majorUrl: '/profession/',
      teacherList:[
        {
          title: '1',
          date: '2022',
          content: '',
          id: '',
        }
      ],
      teacherUrl: '/index/',
      courseList: [
        {
          title: '1',
          date: '2022',
          id: '', 
        }
      ],
      courseUrl: '/tskc/'
      }
  },
  components: {
    theCard,
  },
  methods: {
    idToRouter(id) {
      return '/admission/'+id
    },
    async getConList(){
      const res = await consult.getConList(1, 10)
      console.log(res,'res')
      const list = res.data.data.list.records
      this.consuList = list.map(it => {
        const {consultTitle, createTime, consultId} = it
        return {
          title: consultTitle,
          date: createTime.split('T')[0],
          id: consultId,
        }
      })
    },
    async getEntList() {
        const res = await consult.getEntList(1,4)
        console.log(res,'ruxue')
        const list = res.data.data.list.records
        this.entranceList = list.map(it => {
          let {entranceId, entranceTitle, entranceContent,createTime} = it
          var el = document.createElement( 'html' );
          el.innerHTML = entranceContent
          let pList = el.getElementsByTagName( 'p' )
          let i = 0
          let content = ''
          while(content == '') {
            content = pList[i].innerText
            i++
          }
          console.log(content, 'content')
          return {
            title: entranceTitle,
            content,
            id: entranceId,
            date:createTime,
          }
        })
        },
    idToRouter(id) {
      return '/profession/'+id
    },    
    async getMajList(){
      const res = await consult.getMajList(1, 5)
      console.log(res,'major')
      const list = res.data.data.page.records
      this.majorList = list.map(major => {
        const {majorId,createTime,majorTitle,majorStatus} = major
        return {
          title: majorTitle,
          date: createTime,
          id: majorId,
          status: majorStatus == 1 ? '专科' : '本科'
          
        }
      })
    },
    idToRouter(id) {
      return '/index/'+id
    },
    async getTeaList(){
      const res = await consult.getTeaList(1, 5)
      console.log(res,'teacher')
      const list = res.data.data.list.records
      this.teacherList = list.map(teacher => {
        const {teacherId,createTime,teacherName, teacherContent,teacherMajor} = teacher
        return {
          title: teacherName,
          date: createTime,
          id: teacherId,
          content: teacherContent,
          major: teacherMajor
        }
      })
    },
    idToRouter(id) {
      return '/tskc/'+id
    },
    async getCouList(){
      const res = await consult.getCouList(1, 10)
      console.log(res,'course')
      const list = res.data.data.list.records
      this.courseList = list.map(course => {
        const {courseId,createTime,courseTitle} = course
        return {
          title: courseTitle,
          date: createTime,
          id: courseId,
        }
      })
    },
  },
  mounted() {
    this.getConList()
    this.getMajList()
    this.getTeaList()
    this.getCouList()
    this.getEntList()
  }

}
</script>

<style lang="scss" scoped>
.flor2 {
  padding: 30px 8px 35px;
  overflow: hidden;
}

.flor4 {
  padding: 0px 8px;
  overflow: hidden;
}

.news_zhaosheng,
.news_jiaowu,
.news_hangye {
  width: 286px;
  height: 344px;
}

.news_line {
  background: url(../image/newsline.jpg) no-repeat center 52px;
  width: 63px;
  height: 253px;
}

.newstit2 {
  height: 39px;
  line-height: 39px;
  border-bottom: 2px solid #999999;
  background: url(http://rjxy.ouchn.edu.cn/images/blaline.png) no-repeat center bottom;
  padding-right: 3px;
  font-size: 18px;
}

.newstittext2 h2 {
  font-size: 18px;
  margin: -2px 0 0 0;
  padding-right: 3px;
  border-bottom: 2px solid blue;
  display: block;
  float: left;
}

.newstit2 span a {
  font-size: 14px;
}

.newstit2 span a:hover {
  color: blue;
}

.newstit2 span {
  float: right;
  background: url(../image/more.jpg) no-repeat right center;
  padding-right: 20px;
}

.newslistpic {
  width: 286px;
  height: 118px;
  margin-top: 13px;
}

.newslistpic img {
  width: 276px;
  height: 108px;
  padding: 4px;
  border: 1px solid #dedede;
}

.newslisttext {
  height: 160px;
  overflow: hidden;
  padding: 3px 10px 0px 5px;
}

.newslisttext li {
  line-height: 32px;
}

.newslisttext li span {
  float: left;
  width: 75px;
  color: blue;
  margin-right: 15px;
}

.newslisttext li a {
  display: block;
  float: left;
  width: 178px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.newslisttext_about {
  line-height: 32px;
  height: 160px;
  overflow: hidden;
  padding: 3px 5px 0px;
  .text{
       overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    }
}
.newslisttext_about2 {
  line-height: 32px;
  height: 160px;
  overflow: hidden;
  padding: 3px 5px 0px;
  .text{
       overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    }
}
.newslisttext_about2 {
  > * {
    line-height: 32px;
    padding-bottom: 50%;
    height: 160px;
    overflow: hidden;
    padding: 3px 5px 0px;
    animation: myMove 6s linear infinite; // 重点，定义动画
    animation-fill-mode: forwards;
    .text{
        overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      }
      @keyframes myMove {
        0% {
          transform: translateY(100%);
        }
        100% {
          transform: translateY(-100%);
        }
      }
  }
}
.newslisttext_about a {
  color: #343434;
  white-space: nowrap;
}
.newslisttext_about2 a {
  color: #343434;
  white-space: nowrap;
}
.newslisttext_about a:hover {
  color: blue;
}
.newslisttext_about2 a:hover {
  color: blue;
}
</style>
