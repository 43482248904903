<template>
    <div class="xyld">
        <div class="lcright-02">
                 <div class="main-04 linknav nav-z">
                  <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;学院概况&nbsp;&gt;&nbsp;<font color="blue">学院领导</font>
                  </div> 
              </div>
    <div class="name" v-for="o of officeList" :key="o.title">
        {{o.name}}  {{o.person}}
    </div>
    <!-- <div class="name">
        副院长  袁薇
    </div>
    <div class="name">
        副院长  关杰
    </div>
    <div class="name">
        院长助理  李涛
    </div> -->
    </div>
</template>
<script>
import consult from '@/api/yssz/consult'
export default {
  data() {
   return {
    officeList:[
               {
                 name: '1',
                 person: '',
               },
            ],
   }
},
methods: {
    async getSchList(){
      const res = await consult.getSchList(1, 1)
      console.log(res,'office')
      const list = res.data.data.list.records
      this.officeList = list.map(office => {
        const {officeName, createTime, officePerson} = office
        return {
          name: officeName,
          date: createTime.split('T')[0],
          person: officePerson,
        }
      })
    },
  },
  mounted() {
    this.getSchList()
  }
};
</script>
<style lang="scss" scoped>
.xyld{
    // width: 100%;
    // // margin-left: 30px;
    // height: 493px;
    margin: -200px auto;
    }
    .top{
       /* float: right;
       display: flex; */
       height: 60px;
       border-bottom: 1px solid #cdd1d4;
       width: 100%;
       .right-title{
           display: flex;
           float: right;
           margin-top: 25px;
           width: 100%;
           font-size: 15px;
        }
    }
    .name{
        font-size: 20px;
        font-weight: bold;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .lcright-02 {border-bottom: 1px solid #dadada;height: 54px;position: relative;width: 725px;}
.main-04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z {color: #898989;font-size: 12px;}
</style>
