<template>
  <div class="profession_12">
    <div class="lcrightl-02_12">
                 <div class="mainl-04_12 linknav nav-zl_12">
                  <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;招生支持服务&nbsp;&gt;&nbsp;<font color="blue">开设专业</font>
                  </div> 
              </div> 
              <div class="lcright-03_12">
                	<div class="zy_about_12 nr-z2_12">
                    <p v-for="maj of majorList" :key="maj.title">
	                       <a><router-link :to="majorUrl + maj.id">{{maj.title}}({{maj.status}})</router-link></a>
<!-- <p> -->
	<!-- <a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=308">移动互联应用技术（专科）</a></p>
<p>
	 <a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=442">软件工程（专科起点本科）</a></p> -->
                    </p>
                  </div>
                </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
export default {
   data() {
     return {
      majorList:[
        {
          title: '1',
          date: '2022',
          id: '',
        },
      ],
      majorUrl: '/profession/'
     }
   },
   methods: {
    idToRouter(id) {
      return '/profession/'+id
    },
    async getMajList(){
      const res = await consult.getMajList(1, 5)
      console.log(res,'major')
      const list = res.data.data.page.records
      this.majorList = list.map(major => {
        const {majorId,createTime,majorTitle,majorStatus} = major
        return {
          title: majorTitle,
          date: createTime,
          id: majorId,
          status: majorStatus == 1 ? '专科' : '本科'
          
        }
      })
    },
   },
   mounted() {
    this.getMajList()
  }
}
</script>

<style>
.profession_12{
          margin: -200px auto;
}
.lcrightl-02_12 { 
                  border-bottom: 1px solid #dadada;
                  height: 54px;
                  position: relative;
                  width: 725px;

                }
  .lcright-03_12 {margin: 10px 10px 81px 0;width: 715px;}
  .zy_about_12 {line-height: 2em;width: 715px;}
  .zy_about_12 p{ margin-bottom:10px;}
  .mainl-04_12 {
                height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
  .nav-zl_12 {
              color: #898989;font-size: 12px;}
  .nr-z2_12 {color: #353535;font-size: 13px;}
</style>