<template>
  <div class="homeguidel_2">
    <div class="banner_2 widths_2">
<ul>
	<DIV id="swfContent_2"></DIV>
 </ul>
</div>

<div class="con_2 widths_2">
	<div class="con_u_2">
    	<div v-for="(h, i) of homeList" :key="h.title" class="con_u_l_2 fl_2" >
        	<div class="tit_2">
            	<span><router-link :to="map[i]">详情</router-link></span>
            	<h2>{{h.title}}</h2>
            </div>
            <div class="conbox1_2">
            <p v-html="h.content" style="">
            </p>
            </div>
        </div>
        <!-- <div class="con_u_c_2 fl_2">
        	<div class="tit_2">
            	<span><router-link to="/jihua">详情</router-link></span>
            	<h2>制订学习计划</h2>
            </div>
            <div class="conbox1_2">
            	 <p>
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 每个学生应根据自己的专业背景、文化基础、学习能力、学习动机以及年龄、职业和工作、家务负担等主客观条件，精心设计、合理安排个人学习计划。可以在导学教师、班主任等的指导下完成。制订个人学习计划需考虑的因素有：<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1、你完成学业需修读多少门课，有没有需要补修的课程；<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2、你打算每个学期各修读哪几门课程，这些课程的关系和难易程度如何；<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3、你每周有多少业余时间可以用来学习；</p>
<p>
	<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4、你打算何时完成学业；<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 5、必须符合专业规则的相关要求。</p>

            </div>
        </div>
        <div class="con_u_r_2 fl_2">
        	<div class="tit_2">
            	<span><router-link to="/kaoshi">详情</router-link></span>
            	<h2>参加考试</h2>
            </div>
            <div class="conbox1_2">
            	 <p>
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 课程考核一般包括形成性考核（测评）和终结性考试（测评），课程考核要把形成性考核（简称形考）和终结性考试（简称终考或考试）有机地结合起来，综合测量和评价学生的学习行为、学习过程和学习成就。学生的课程考核总成绩由形考成绩和终考成绩合成计算并记录。<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 形考是学习过程中的测评活动，一般有阶段性学习测验、教学实践活动、专题讨论、小组学习、学习记录等基本形式，按照&ldquo;统一要求、分级管理、分层负责、指导（辅导）教师评阅&rdquo;的要求组织实施。<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 参加考终考前必须完成选课、学习和报考环节，缺少任</p>
<p>
	&nbsp;</p>
<p>
	何一个环节均不能参加终考。终考有闭卷、开卷或者半开卷考试，部分课程的考试可采用听力考试、面试（口试）或网上考试等方式。应根据课程的性质、特点，结合各种考试方式的特点和适用范围，以及考试工作的可操作性，适当选择考试方式。</p>

            </div>
        </div>
    </div>
    <div class="con_d_2">
    	<div class="con_d_l_2 fl_2">
        	<div class="tit_2">
            	<span><router-link to="/xuexi">详情</router-link></span>
            	<h2>网上学习</h2>
            </div>
            <div class="conbox2_2">
            	 <p>
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 国家开放大学学生的学习模式是&ldquo;有支持的自主学习&rdquo;。学生可根据课程安排和课程目标制订适合自己的学习计划，通过在线观看视频、在线讨论、虚拟课堂、BBS答疑、社区讨论、论坛发帖等多种形式开展学习，同时，在教师指导下完成课程作业和课程考核。<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 部分课程直接通过在线学习平台即可完成学习和形考，这部分课程所占比例会越来越高。</p>

            </div>
        </div>
        <div class="con_d_c_2 fl_2">
        	<div class="tit_2">
            	<span><router-link to="/mianshou">详情</router-link></span>
            	<h2>参加面授</h2>
            </div>
            <div class="conbox2_2">
             <p>
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 面授学习作为实时教学活动的一种形式，学习中心的课程辅导教师可以根据教学的实际需要安排少量学时的面授。面授学习是学生自主学习的一种辅助形式，并不是大学主流的学习方式，学院采取了多种渠道鼓励、支持学生通过网上学习的方式来完成课程的学习。</p>

            </div>
        </div>
        <div class="con_d_r_2 fl_2">
        	<div class="tit_2">
            	<span><router-link to="/shixun">详情</router-link></span>
            	<h2>参加实验实训</h2>
            </div>
            <div class="conbox3_2">
            	<div id="KinSlideshow_2" style="visibility:hidden;">
                    
       <a href="about.asp?id=12" target="_blank"> 
         <img src="../" alt="正在整理中..." width="310" height="171" /> 
       </a>
 
                    
                </div>
            </div>
        </div>
    </div>
    <div class="con_hudong_2">
    	<div class="tit_2">
            <span><router-link to="/hudong">详情</router-link></span>
            <h2>与教师同学互动</h2>
        </div>
        <div class="con_hudonglist_2">
        	<ul>
            	  
                <li><router-link to="/hudong"><img src="@/assets/imgs/index/2019082354755737.jpg" width="186" height="138" /></router-link><p><a href="hdny.asp?id=395">参加校内实训</a></p></li>

  
                <li><router-link to="/hudong"><img src="@/assets/imgs/index/2019082354701713.jpg" width="186" height="138" /></router-link><p><a href="hdny.asp?id=396">拜访当地软件企业</a></p></li>

  
                <li><router-link to="/hudong"><img src="@/assets/imgs/index/2019082354329137.jpg" width="186" height="138" /></router-link><p><a href="hdny.asp?id=399">专业课答疑</a></p></li>

  
                <li><router-link to="/hudong"><img src="@/assets/imgs/index/2019082354598441.jpg" width="186" height="138" /></router-link><p><a href="hdny.asp?id=397">开学典礼后合影</a></p></li>

 
                
                
            </ul>
        </div>
    </div> -->
</div>
  </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            img: '',
            homeList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ],
            map:[
                '/xuanke',
                '/jihua',
                '/kaoshi',
                '/xuexi',
                '/mianshou',
                '/shixun',
                '/hudong',
            ] 
       }
    },
  methods: {
    async getHomeList(){
      const res = await consult.getHomeList(1, 7)
      console.log(res,'Home')
      const list = res.data.data.list.records
      this.homeList = list.map(home => {
        const {alwaysTitle, createTime, alwaysId, alwaysContent} = home
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getHomeList()
  }
  }
</script>

<style lang="scss" scoped>
.con_u_2 {
    >div:last-child {
        width: 930px;
        float: left;  
    }
  }
// .conbox1_2{
//   img:last-child{
//       width:186px; height:138px; 
//     }
// }
.fl_2{
    float: left;
    margin: 10px;
}
.banner_2{ height:250px;}
.con_2{ padding:20px 0px;}
.con_u_2{ 
         height:310px;
         /* width:1000px; */
        }
.con_u_l_2,.con_u_c_2
                     { 
                       width:310px;
                       /* flex-shrink: 0; */
                    }
.con_u_r_2{ width:320px;}
.con_u_c_2{ margin:0px 30px;}
.tit_2{ 
        height:35px; 
        line-height:35px; 
        border-bottom:1px solid #c6c6c6;}
.tit_2 span{ float:right; background:url(@/assets/imgs/index/more.jpg) no-repeat center 6px; width:57px; height:29px; text-align:center;letter-spacing:3px;}
.tit_2 span a{ display:block;}
.tit_2 h2{ background:url(@/assets/imgs/index/subicon1.png) no-repeat left center; 
           padding-left:28px;
           background-size: 22px 20px;
         }
.conbox1_2{ height:260px; overflow:hidden; padding-top:13px; line-height:25px;}
.conbox1_2 img{ margin:0px 5px; width:300px; height:130px; margin-bottom:5px;}
.con_d_2{ height:230px; padding-top:30px;}
.con_d_l_2,.con_d_c_2{ width:310px;}
.con_d_c_2{ margin:0px 30px;}
.conbox2_2{ padding-top:15px; line-height:25px; height:175px; overflow:hidden;}
.conbox2_2 img{ width:119px; height:171px; float:left; margin-right:12px;}
.con_d_r_2{ width:320px;}
.conbox3_2{padding-top:15px;height:171px; overflow:hidden;}
.conbox3_2 a{ display:block; float:left;}
.conbox3_2 img{ width:310px; height:171px;}
.con_hudong_2{ margin-top:20px;}
.con_hudonglist_2{ height:170px; overflow:hidden; padding-top:20px; padding-left:5px;}
.con_hudonglist_2 li{ float:left; margin:0px 5px; text-align:center;}
.con_hudonglist_2 li img{ width:186px; height:138px; border:1px solid #dedede;}
.con_hudonglist_2 li p{ line-height:28px;}
</style>