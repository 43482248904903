<template>
<div>
    <div class="lcright7">
      <div class="lcright-017" v-for="e of entranceList" :key="e.title">
          <div class="lcright-027">
              <div class="main-047 linknav nav-z7">
                  <router-link to="/homeguide">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">{{e.title}}</font>
              </div>
          </div>
          <div class="lcright-037">
                	<div class="zy_about7 nr-z27">
                    	<p>
<p>
	<span v-html="e.content" style="font-size: 16px">
	</span>
</p>
<!-- <p>
	<span style="font-size: 16px"><strong>入学资格审核及录取</strong><br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;报名者报名时须签订《诚信承诺书》，对不符合规定条件或持假证件报名入学者，无论何时查出，均予以取消学籍处理，所交费用一律不予退还。学生的入学资格在入学后和毕业前还须经过教育部相关部门的校验，校验不合格者将不能毕业和进行毕业证书电子注册。入学资格审核合格者方可录取并注册学籍。</span></p>
<p>
	<span style="font-size: 16px"><strong>领取录取通知书和学生证</strong><br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;凡入学资格审核合格的学生，将收到国家开放大学发放的&ldquo;录取通知书&rdquo;和&ldquo;学生证&rdquo;。学生根据录取通知书上的要求，进入国家开放大学教务教学平台学习。</span></p> -->
</p>
                    </div>
                </div>
            </div>
      </div>
      </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            entranceList:[
               {
                 title: '1',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getEntList(){
      const res = await consult.getEntList(2, 1)
      console.log(res,'entrance')
      const list = res.data.data.list.records
      this.entranceList = list.map(entrance => {
        const {entranceTitle, entranceId, entranceContent} = entrance
        return {
          title: entranceTitle.substring(0,4),
          id: entranceId,
          content: entranceContent,
        }
      })
    },
  },
  mounted() {
    this.getEntList()
  }
  }
</script>

<style>
.lcright7 {
    float: left; 
    width:1000px;
    margin-top: 200px;
   }
.lcright-017 {margin-right:10px;}
.lcright-027 {border-bottom: 1px solid #dadada;
       height: 54px;
       position: relative;
       width: 1000px;
      }
.main-047 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z7 {color: #898989;font-size: 12px;}
.lcright-037 {margin: 10px 10px 81px 0;
       /* width: 1000px;
       position: relative; */
      }
.zy_about7 {line-height: 2em;
     }
.zy_about7 p{ 
     margin-bottom:10px;
     /* margin-left: auto; */
     }
.nr-z27 {
  color: #353535;
  font-size: 13px;
  width: 1000px;
  }
</style>