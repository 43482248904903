<template>
  <div class="home">
    <nav-header></nav-header>
    <router-view/>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue'
import NavFooter from '@/components/NavFooter.vue'
export default {
  components: { NavHeader, NavFooter},
    name:"HomeE"
}
</script>

<style lang="scss" scoped>
  // .home {
  //   background: url(@/assets/imgs/index/beijing2.jpg) no-repeat;
  // background-size: 100% 100%;
  // }

</style>
