<template>
  <div class="course">
  <div class="overview">
    <div class="lcright-02">
                 <div class="main-04 linknav nav-z">
                  <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;非学历教育&nbsp;&gt;&nbsp;<font color="blue">课程体系</font>
                  </div> 
              </div> 
               <div class="lcright-03">
                <div v-for="c of courseList" :key="c.title" class="content">
        <div v-html="c.content" class="content-title" >
    </div>
    </div>
              </div> 
  </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
export default {
  data() {
   return {
    courseList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ],
   }
},
methods: {
    async getCousList(){
      const res = await consult.getCousList(1, 1)
      console.log(res,'course')
      const list = res.data.data.list.records
      this.courseList = list.map(course => {
        const {alwaysTitle,createTime, alwaysId, alwaysContent} = course
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getCousList()
  }
};
</script>

<style lang="scss" scoped>
.lcright-02 {border-bottom: 1px solid #dadada;height: 54px;position: relative;width: 725px;}
  .lcright-03 {margin: 10px 10px 81px 0;width: 715px;}
  .zy_about {line-height: 2em;width: 715px;}
  .zy_about p{ margin-bottom:10px;}
  .main-04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
  .nav-z {color: #898989;font-size: 12px;}
  .nr-z2 {color: #353535;font-size: 13px;}
</style>