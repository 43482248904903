import Vue from 'vue'
import Vue2Editor from "vue2-editor";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map';
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import axios from 'axios'
import utils from '@/utils/utils.js'
import moment from 'moment';
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'babel-polyfill';
import fullpage from 'fullpage.js';
import $ from 'jquery'

Vue.prototype.$ = $

Vue.prototype.fullpage = fullpage


moment.locale('zh-cn')
Vue.prototype.$moment = moment
Vue.prototype.utils = utils
window.axios = axios
Vue.prototype.$http = axios
Vue.config.productionTip=false
Vue.use(BaiduMap,{ak:'j7ebwwp1gQLwM9OIuyBv5D1U6u3KmA6o'})
Vue.use(ElementUI);
Vue.use(AOS);
Vue.use(Vue2Editor);
// Vue.config.productionTip = false
AOS.init()
router.afterEach(() => {
  window.scrollTo(0,0);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
