<template>
  <div class="lsf">
    <theNotice></theNotice>
    <theFlor1></theFlor1>
    <theModel />
  </div>
</template>

<script>
import theNotice from './components/theNotice.vue'
import theFlor1 from './components/theFlor1.vue'
import theModel from './components/theModel.vue'

export default {
  components: {
    theNotice,
    theFlor1,
    theModel,
  }
}
</script>
<style lang="scss" scoped>
.lsf {
  overflow: hidden;
}

</style>
