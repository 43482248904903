export default{
  imgUrlHandler(imgUrl) {
    if(imgUrl) {
      // return 'http://' + location.host + '/' + imgUrl.split('/').slice(3).join('/')
      return imgUrl
    } else {
      return ''
    }
  }
}
