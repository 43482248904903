<template>
  <div class="lcright_03">
  <div class="lcright-01_03">
      <div class="lcright-02_03">
          <div class="main-04_03 linknav nav-z_03">
              <router-link to="/homeguidel">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">选课</font>
            </div>
        </div>
        <div class="lcright-03_03">
                	<div class="zy_about_03 nr-z2_03" v-for="h of homeList" :key="h.title">
                    	<p>
                            <p v-html="h.content">
	<!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 学生按照制订的学习计划，在参加课程学习或课程考试之前，需完成选课和课程注册流程。<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 学生应在其所属的学习中心按学期进行选课，新生只能选择所在学习中心第一学期的开设课程。选课有集体选课、按班选课等方式，通常是按班选课。<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 完成学期选课后，即可通过教务管理系统进行课程注册，课程注册是学生参加该课程后续相关作业的基础，未注册的课程，学生不能参加该课程的学习、考核等。 -->
</p>
</p>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            img: '',
            homeList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getHomeList(){
      const res = await consult.getHomeList(1, 1)
      console.log(res,'home')
      const list = res.data.data.list.records
      this.homeList = list.map(home => {
        const {alwaysTitle, createTime, alwaysId, alwaysContent} = home
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getHomeList()
  }
  }
</script>

<style>
.lcright_03 {
  float: left; 
  width:1000px;
  margin-top: 200px;
 }
.lcright-01_03 {margin-right:10px;}
.lcright-02_03 {border-bottom: 1px solid #dadada;
     height: 54px;
     position: relative;
     width: 1000px;
    }
.main-04_03 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z_03 {color: #898989;font-size: 12px;}
.lcright-03_03 {margin: 10px 10px 81px 0;}
.zy_about_03 {line-height: 2em;}
.zy_about_03 p{ margin-bottom:10px;}
.nr-z2_03 {color: #353535;
        font-size: 13px;
        width:1000px}
</style>