<template>
  <div class="gonggao">
    <div class="lcright-02">
      <div class="main-04 linknav nav-z">
        <router-link to="/index">首页</router-link
        >&nbsp;&gt;&nbsp;新闻中心&nbsp;&gt;&nbsp;<font color="blue"
          >通知公告</font
        >
      </div>
    </div>
    <div class="content">
      <div class="guanli" v-for="not in noticeList" :key="not.title">
        <router-link class="item" :to="noticeUrl + not.id">
        <a href="">{{ not.title }}</a>
        <span>{{ not.date }}</span>
        </router-link>
      </div>
      <!-- <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
        <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
        <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
        <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
        <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
        <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div>
        <div class="guanli"><span>2019-10-08</span><a href="">昆明宏剑学习中心2019秋季学期课程安排表</a></div> -->
      <div class="fanye_15">
        <span class="disabled_15"> &lt; </span>
        <span class="current_15">1</span>
        <a href="?id=7&page=2">2</a>&nbsp;&nbsp;<a href="?id=7&page=2"> > </a>
      </div>
    </div>
  </div>
</template>
<script>
import consult from "@/api/yssz/consult";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  // name: "#jxjwgl",
  data() {
    return {
      img: "",
      noticeList: [
        {
          title: "1",
          date: "2022",
          id: "",
        },
      ],
      noticeUrl:'/gonggao/'
    };
  },

  methods: {
    idToRouter(id) {
        return '/gonggao/'+id
      },
    async getNotList() {
      const res = await consult.getNotList(1, 8);
      console.log(res, "888");
      const list = res.data.data.list.records;
      this.noticeList = list.map((it) => {
        const {noticeTitle, createTime, noticeId} = it
        return {
          title: noticeTitle,
          date: createTime.split("T")[0],
          id: noticeId,
        };
      });
    },
  },
  mounted() {
    this.getNotList();
  },
};
</script>
<style lang="scss" scoped>
.gonggao {
  // width: 100%;
  // height: 493px;

// .top{
//    /* float: right;
//    display: flex; */
//    height: 60px;
//    border-bottom: 1px solid #cdd1d4;
//    width: 100%;
//    .right-title{
//        display: flex;
//        float: right;
//        margin-top: 25px;
//        width: 100%;
//        font-size: 15px;
//     }
// }
.lcright-02 {
  border-bottom: 1px solid #dadada;
  height: 54px;
  position: relative;
  width: 725px;
}
.main-04 {
  height: 22px;
  line-height: 22px;
  position: absolute;
  right: 4px;
  text-align: right;
  top: 25px;
  width: 500px;
  z-index: 1;
}
.nav-z {
  color: #898989;
  font-size: 12px;
}
.content {
  width: 720px;
  margin-bottom: 80px;
  .guanli {
    height: 45px;
    line-height: 45px;
    border-bottom: 1px dotted #b8bcbe;
    font-size: 13px;
    background: url(@/assets/imgs/index/blaicon.gif) no-repeat left center;
    .item{
      display: flex;
      justify-content:space-between;
    }
    span {
      color: #a2a6a8;
    }
    a {
      color: #000000;
      margin-left: 5px;
    }
    a:hover {
      color: #0189ff;
    }
  }
  DIV.fanye_15 {
    clear: both;
    margin: 35px 0px 18px 0px;
    *margin: 25px 0px 35px 0px;
    display: block;
    overflow: hidden;
    _zoom: 1;
    height: 23px;
    line-height: 23px;
    text-align: center;
    width: 100%;
  }
  DIV.fanye_15 A {
    background: #e6e6e6;
    color: #333;
    margin-right: 8px;
    padding: 8px 10px;
    text-decoration: none;
  }
  DIV.fanye_15 A:hover {
    background: blue;
    color: #fff;
    margin-right: 8px;
  }
  DIV.fanye_15 A:active {
    background: blue;
    color: #fff;
    margin-right: 8px;
  }
  DIV.fanye_15 SPAN.current_15 {
    background: blue;
    color: #fff;
    font-weight: bold;
    margin-right: 8px;
    padding: 8px 10px;
  }
  DIV.fanye_15 SPAN.disabled_15 {
    color: #333;
    margin-right: 8px;
    padding: 8px 10px;
  }
}
    }
</style>
