<template>
<div class="jypg">
    <div class="lcright-02">
                 <div class="main-04 linknav nav-z">
                  <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;教学支持服务&nbsp;&gt;&nbsp;<font color="blue">教研评估</font>
                  </div> 
              </div>
    <div v-for="j of jypgList" :key="j.title" class="content">
        <div v-html="j.content" class="content-title" >
    </div>
    </div>
    </div>
</template>
<script>
import consult from '@/api/yssz/consult'
export default {
  data() {
   return {
    jypgList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ],
   }
},
methods: {
    async getJypgList(){
      const res = await consult.getJypgList(1, 1)
      console.log(res,'jypg')
      const list = res.data.data.list.records
      this.jypgList = list.map(jypg => {
        const {alwaysTitle,createTime, alwaysId, alwaysContent} = jypg
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getJypgList()
  }
};
</script>
<style lang="scss" scoped>
.jypg{
    margin: -200px auto;
    }
    .lcright-02 {border-bottom: 1px solid #dadada;height: 54px;position: relative;width: 725px;}
.main-04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z {color: #898989;font-size: 12px;}
   .content{
        // display: flex;
        // margin-top: 8px;
        padding-top: 8px;
        padding-bottom: 80px;
        .content-title{
            font-weight: bold;
            font-size: 15px;
            height: 30px;
        }
        .content-text{
            font-size: 15px;
            text-indent: 2em;
            line-height: 30px;
        }
        .empty{
            height:15px;
            width: 100%;
        }
    }
</style>