<template>
    <div class="homeguide">
      <div class="banner6 widths6">
       <ul>
        <DIV id="swfContent6"></DIV>
       </ul>
      </div>
      <div class="con6 widths6">
      <div class="con_u6">
          <div v-for="(e, i) of entranceList" :key="e.title" class="con_u_l6 fl6">
              <div class="tit6">
                  <span><router-link :to="map[i]" >详情</router-link></span>
                  <h2>{{e.title}}</h2>
              </div>
              <div class="conbox16" >
             <p>
      <span v-html="e.content"  style="font-size: 12px">
         <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br/>
      &nbsp; &nbsp; &nbsp; &nbsp;   -->
    </span>
</p> 
   <!-- <p>
      <span style="font-size: 12px">&nbsp; &nbsp; &nbsp; &nbsp;【专科】报名时年满17周岁，具有普通高中、职业高中、技工学校和中等专业学校及以上层次毕业证书的学习者，可注册专科学历教育相关专业学习。&nbsp; &nbsp;&nbsp;</span>
 </p>
  <p>
      &nbsp; &nbsp; &nbsp; &nbsp;【本科】具有国民教育系列的专科或专科以上毕业证书者，可注册本科学历教育相关专业学习。
  </p> --> 
  
              </div>
              <!-- <div class="conbox16">
                  <p>
      <span style="font-size: 12px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 报名者在报名前要仔细阅读招生简章及专业介绍等相关材料，根据自己的兴趣和职业发展，选择好报读专业。<br />
      &nbsp; &nbsp; &nbsp; &nbsp; </span></p>
  <p>
      <span style="font-size: 12px">&nbsp; &nbsp; &nbsp; &nbsp;【专科】报名时年满17周岁，具有普通高中、职业高中、技工学校和中等专业学校及以上层次毕业证书的学习者，可注册专科学历教育相关专业学习。&nbsp; &nbsp;&nbsp;</span></p>
  <p>
      &nbsp; &nbsp; &nbsp; &nbsp;【本科】具有国民教育系列的专科或专科以上毕业证书者，可注册本科学历教育相关专业学习。</p>
  
              </div> -->
          <!-- </div>
          <div class="con_u_c6 fl6">
              <div class="tit6">
                  <span><router-link to="/process">详情</router-link></span>
                   <h2>报名流程</h2>
          </div> -->
              <!-- <div class="conbox16">
                  <p>
      <span style="font-size: 12px"><strong>现场报名</strong><br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;报名者持毕业证书、身份证原件及复印件，至各学习中心或学习点进行电子照片采集并办理报名手续。</span></p>
  <p>
      <span style="font-size: 12px"><strong>入学资格审核及录取</strong><br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;报名者报名时须签订《诚信承诺书》，对不符合规定条件或持假证件报名入学者，无论何时查出，均予以取消学籍处理，所交费用一律不予退还。学生的入学资格在入学后和毕业前还须经过教育部相关部门的校验，校验不合格者将不能毕业和进行毕业证书电子注册。入学资格审核合格者方可录取并注册学籍。</span></p>
  <p>
      &nbsp;</p>
  <p>
      <span style="font-size: 12px"><strong>领取录取通知书和学生证</strong><br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;凡入学资格审核合格的学生，将收到国家开放大学发放的&ldquo;录取通知书&rdquo;和&ldquo;学生证&rdquo;。学生根据录取通知书上的要求，进入国家开放大学教务教学平台学习。</span></p>
  
              </div> -->
          <!-- </div>
          <div class="con_u_r6 fl6">
              <div class="tit6">
                  <span><router-link to="/net">详情</router-link></span>
                  <h2>报名网络</h2>
              </div> -->
              <!-- <div class="conbox16">
                  <div id=b_demo_16 style="overflow:hidden; height:320px; width:260; margin:auto;">
                      <div id=b_demo1_16>
                                  <table border="0" cellpadding="0" cellspacing="0" width="292">
      <colgroup>
          <col />
          <col />
      </colgroup>
      <tbody>
          <tr height="44">
              <td height="44" style="height:44px;width:155px;">
                  授权学习中心</td>
              <td style="width:139px;">
                  报名咨询电话</td>
          </tr>
          <tr height="21">
              <td height="21" style="height:21px;width:155px;">
                  &nbsp;</td>
              <td style="width:139px;">
                  &nbsp;</td>
          </tr>
      </tbody>
  </table>
  <br/>
                  
                  </div>
                      <div id=b_demo1_26></div>
                  </div>
              </div> -->
          <!-- </div>
      </div>
      <div class="con_d6">
          <div class="con_d_l6 fl6">
              <div class="tit6">
                  <span><router-link to="/standard">详情</router-link></span>
                  <h2>收费标准</h2>
              </div> -->
              <!-- <div class="conbox26">
                   <p>
      <a href="UploadFiles/files/2019082240592797.PDF">国开财资【2018】5号关于规范国家开放大学实验学院、行业学院收费标准的通知.PDF</a></p>
  
              </div> -->
          <!-- </div>
          <div class="con_d_c6 fl6">
              <div class="tit6">
                  <span><router-link to="/link">详情</router-link></span>
                  <h2>常用链接</h2>
              </div> -->
              <!-- <div class="conbox26">
                <p>
      &nbsp;</p>
              </div> -->
          </div>
      </div>
  </div>
    </div>
  </template>
  
  <script>
  import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            img: '',
            entranceList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ], 
            map: [
                  '/condition',
                  '/process',
                  '/net',
                  '/standard',
                  '/link'
                ]
       }
    },
  methods: {
    async getEntList(){
      const res = await consult.getEntList(1, 5)
      console.log(res,'entrance')
      const list = res.data.data.list.records
      this.entranceList = list.map(entrance => {
        const {entranceTitle, createTime, entranceId, entranceContent} = entrance
        return {
          title: entranceTitle.substring(0,4),
          date: createTime.split('T')[0],
          id: entranceId,
          content: entranceContent,
        }
      })
    },
  },
  mounted() {
    this.getEntList()
  }
  }
  </script>
  
  <style lang="scss" scoped>
  .con_u6 {
    >div:last-child {
        width: 620px;
    }
  }
  .widths6{ width:1000px; margin:0px auto;}
  .banner6{ height:250px;}
  .con6{ padding:20px 0px;}
  .fl6{
    float: left;
    margin: 10px;
  }
.con_u6{ height:310px;}
.con_u_l6,.con_u_c6{ width:310px;}
.con_u_r6{ width:320px;}
.con_u_c6{ margin:0px 30px;}
.tit6 { 
       height:35px; 
       line-height:35px; 
       border-bottom:1px solid #c6c6c6;
      }
.tit6 span { 
             float:right; 
             background:url(@/assets/imgs/index/more.jpg) no-repeat center 6px; 
             width:57px; 
             height:29px; 
             text-align:center;
             letter-spacing:3px;
            }
.tit6 span a{ display:block;}
.tit6 h2{ 
         background:url(@/assets/imgs/index/subicon1.png) no-repeat left center; 
         background-size: 22px 20px;
         padding-left:28px;
        }
.conbox16{ height:260px; overflow:hidden; padding-top:13px; line-height:25px;  float:left;}
.conbox16 img{ margin:0px 5px; width:300px; height:130px; margin-bottom:5px;}
.conbox16 a{ color:#343434;}
.con_d6{ height:230px; padding-top:30px;}
.con_d_l6{ width:310px;}
.con_d_c6{width:660px}
.con_d_c6{ margin-left:30px;}
.conbox26{ padding-top:15px; line-height:25px; height:175px; overflow:hidden;}
.conbox26 img{ width:119px; height:171px; float:left; margin-right:12px;}
  </style>