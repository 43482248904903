<template>
  <div class="zkzy_17">
    <div class="lcrightl-02_17">
      <div class="mainl-04_17 linknav nav-zl_17">
        <router-link to="/index">首页</router-link
        >&nbsp;&gt;&nbsp;学历教育&nbsp;&gt;&nbsp;<font color="blue"
          >开放教育</font
        >
      </div>
    </div>
    <div v-for="k of kfjyList" :key="k.title" class="content">
      <div v-html="k.content" class="content-title"></div>
    </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
export default {
  data() {
    return {
      kfjyList: [
        {
          title: '1',
          date: '2022',
          content: '',
          id: '',
        },
      ],
    }
  },
  methods: {
    async getKfjyList() {
      const res = await consult.getKfjyList(1, 1)
      console.log(res, 'kfjy')
      const list = res.data.data.list.records
      this.kfjyList = list.map((kfjy) => {
        const { alwaysTitle, createTime, alwaysId, alwaysContent } = kfjy
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getKfjyList()
  },
}
</script>

<style>
.zkzy_17 {
  /* margin: -200px auto; */
  margin-top: -200px;
  margin-bottom: 100px;
}
.content {
  padding-bottom: 20px;
}
.lcrightl-02_17 {
  border-bottom: 1px solid #dadada;
  height: 54px;
  position: relative;
  width: 725px;
  /* padding-bottom: 200px; */
  /* margin: -200px auto; */
}
.lcright-03_17 {
  margin: 10px 10px 81px 0;
  width: 715px;
}
.zy_about_17 {
  line-height: 2em;
  width: 715px;
}
.zy_about_17 p {
  margin-bottom: 10px;
}
.mainl-04_17 {
  height: 22px;
  line-height: 22px;
  position: absolute;
  right: 4px;
  text-align: right;
  top: 25px;
  width: 500px;
  z-index: 1;
}
.nav-zl_17 {
  color: #898989;
  font-size: 12px;
}
.nr-z2_17 {
  color: #353535;
  font-size: 13px;
}
.lcright-03_17 {
  margin: 10px 10px 81px 0;
  width: 715px;
}
.zy_news_17 {
  line-height: 2em;
  width: 715px;
}
.zy_news_17 li {
  height: 90px;
  padding-top: 15px;
  border-bottom: 1px solid #ebebeb;
}
/* .zy_news_11 li .newspic_11{ width:93px;}
  .zy_news_11 li .newspic_11 img{ width:93px; height:75px;} */
.zy_news_17 li .newstext_17 {
  width: 715px;
  line-height: 23px;
  /* padding: -100px auto; */
  margin: -10px auto;
}
.zy_news_17 li .newstext_17 a {
  color: #888;
}
.zy_news_17 li .newstext_17 h2 {
  line-height: 24px;
}
.zy_news_17 li .newstext_17 h2 a {
  font-size: 13px;
  font-weight: bold;
  color: #666;
}
.zy_news_17 li .newstextmore_17 a {
  color: blue;
}
.zy_news_17 li .newstextmore_17 a:hover {
  text-decoration: underline;
}
.nr-z2_17 {
  color: #353535;
  font-size: 13px;
}
.fr {
  float: right;
}
DIV.fanye_17 {
  clear: both;
  margin: 35px 0px 18px 0px;
  *margin: 25px 0px 35px 0px;
  display: block;
  overflow: hidden;
  _zoom: 1;
  height: 23px;
  line-height: 23px;
  text-align: center;
  width: 100%;
}
DIV.fanye_17 A {
  background: #e6e6e6;
  color: #333;
  margin-right: 8px;
  padding: 8px 10px;
  text-decoration: none;
}
DIV.fanye_17 A:hover {
  background: blue;
  color: #fff;
  margin-right: 8px;
}
DIV.fanye_17 A:active {
  background: blue;
  color: #fff;
  margin-right: 8px;
}
DIV.fanye_17 SPAN.current_17 {
  background: blue;
  color: #fff;
  font-weight: bold;
  margin-right: 8px;
  padding: 8px 10px;
}
DIV.fanye_17 SPAN.disabled_17 {
  color: #333;
  margin-right: 8px;
  padding: 8px 10px;
}
</style>

<!-- <template>
<div class="zkzy">
    <div class="lcright-02">
                 <div class="main-04 linknav nav-z">
                  <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;学历教育&nbsp;&gt;&nbsp;<font color="blue">开放教育</font>
                  </div> 
              </div>
     <div class="content">
        <div class="content-text"><router-link to="" class="title">软件技术专业</router-link></div>
        <div class="content-aa"><router-link to="" class="aa"><span>一、培养目标 专业培养规格：本科（专科起点），两年制，三年业余学习，最短学习年限不低于两年半。</span></router-link></div>
        <div class="date"><router-link to="" class="time">2022-11-14</router-link></div></div> 
        <div class="lcright-03_17">
                	<div class="zy_news_17 nr-z2_17"><ul>
   <li>
                                <div class="newstext_17 fr_17" style="float: left">
                                   <h2><router-link to="/zhaoszx">国家开放大学软件学院 关于开放教育招生的声明</router-link></h2>
                                   <p><router-link to="/zhaoszx">	在2022年秋季招生工作中，有收到反映社会上存在一些机构和个人冒用国家开放大学软件学院的名义通过互..</router-link></p>
                                   <p class="newstextmore_17"><router-link to="/zhaoszx">2022-07-27</router-link></p>
                                </div>
                            </li>

    <li>
                                  
                                <div class="newstext_11 fr_11" style="float: left">
                                   <h2><a href="fwny.asp?id=579">国家开放大学软件学院 2022年秋季学期招生简章</a></h2>
                                   <p><a href="fwny.asp?id=579">	国家开放大学是教育部直属的，以促进终身学习为使命、以现代信息技术为支撑、以&ldquo;互联网+&..</a></p>
                                   <p class="newstextmore_11"><a href="fwny.asp?id=579">2022-07-27</a></p>
                                </div>
                            </li>

   <li>
                               
                                <div class="newstext_11 fr_11" style="float: left">
                                   <h2><a href="fwny.asp?id=577">国家开放大学软件学院 2022春季学期招生简章</a></h2>
                                   <p><a href="fwny.asp?id=577">	国家开放大学是教育部直属的，以促进终身学习为使命、以现代信息技术为支撑、以&ldquo;互联网+&..</a></p>
                                   <p class="newstextmore_11"><a href="fwny.asp?id=577">2022-01-19</a></p>
                                </div>
                            </li> 
</ul></div><div class='fanye_17'><span class='disabled_17'> < </span> <span class='current_17'>1</span> <a href="?id=6&page=2">2</a> <a href="?id=6&page=3">3</a>&nbsp;&nbsp;<a href="?id=6&page=2"> > </a></div>
                 </div>  
 </div>
</template>
<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "#zkzy",
};
</script>
<style lang="scss" scoped>
.zkzy{
    margin: -200px auto;
    }
.lcright-02 {border-bottom: 1px solid #dadada;height: 54px;position: relative;width: 725px;}
.main-04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z {color: #898989;font-size: 12px;}
    // .top{
    //    /* float: right;
    //    display: flex; */
    //    height: 60px;
    //    border-bottom: 1px solid #cdd1d4;
    //    width: 100%;
    //    .right-title{
    //        display: flex;
    //        float: right;
    //        margin-top: 25px;
    //        width: 100%;
    //        font-size: 15px;
    //     }
    // }
//    .content{
//         // display: flex;
//         // margin-top: 8px;
//         padding-top: 20px;
//         padding-bottom: 80px;
//         height: 130px;
//         border-bottom:  1px solid #cdd1d4;
//         .content-text{
//         .title{
//             font-weight: bold;
//             font-size: 20px;
//             color: #000000;
//             height: 30px;
//         }
//         }
//         .aa{
//             display: flex;
//             margin-top: 10px;
//             color: #615d5d;
//             line-height: 18px;
//             font-size: 18px;
//             overflow: hidden;
//             overflow: hidden;
//             text-overflow: ellipsis;
//             white-space: nowrap;
//             span{
//                 white-space: nowrap;
//               font-weight:normal;
//             }
            
//         }
//         .date{
//             display: flex;
//             margin-top: 10px;
//             margin-bottom: 20px;
//             margin-left: 100px;
//             .time{
//                 color: #1a4d7b;
//                 font-size: 18px;
//                 margin-left: 480px;
//             }
            
//         }
//     }
.lcright-03_17 {margin: 10px 10px 81px 0;width: 715px;}
  .zy_about_17 {line-height: 2em;width: 715px;}
  .zy_about_17 p{ margin-bottom:10px;}
  .mainl-04_17 {
                height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
  .nav-zl_17 {
              color: #898989;font-size: 12px;}
  .nr-z2_17 {color: #353535;font-size: 13px;}
.lcright-03_17 {margin: 10px 10px 81px 0;width: 715px;}
.zy_news_17{line-height: 2em;width: 715px;}
.zy_news_17 li{ height:90px; padding-top:15px; border-bottom:1px solid #ebebeb;}
/* .zy_news_11 li .newspic_11{ width:93px;}
.zy_news_11 li .newspic_11 img{ width:93px; height:75px;} */
.zy_news_17 li .newstext_11{ 
                             width:715px; 
                             line-height:23px;
                             /* padding: -100px auto; */
                             margin: -10px auto;
                            }
.zy_news_17 li .newstext_17 a{ color:#888;}
.zy_news_17 li .newstext_17 h2{ line-height:24px;}
.zy_news_17 li .newstext_17 h2 a{font-size:13px; font-weight:bold; color:#666;}
.zy_news_17 li .newstextmore_17 a{ color:blue;}
.zy_news_17 li .newstextmore_17 a:hover{ text-decoration:underline;}
.nr-z2_17 {color: #353535;font-size: 13px;}
.fr{ float:right;}
DIV.fanye_17 { clear:both;margin: 35px 0px 18px 0px;*margin: 25px 0px 35px 0px; display:block; overflow:hidden; _zoom:1; height:23px; line-height:23px;text-align: center;width: 100%;}
DIV.fanye_17 A {background: #e6e6e6;color: #333;margin-right: 8px;padding:8px 10px;text-decoration: none;}
DIV.fanye_17 A:hover {background:blue;color: #fff;margin-right: 8px;}
DIV.fanye_17 A:active {background:blue;color: #fff;margin-right: 8px;}
DIV.fanye_17 SPAN.current_17 {background:blue;color: #fff;font-weight: bold;margin-right: 8px;padding:8px 10px;}
DIV.fanye_17 SPAN.disabled_17 {color:#333;margin-right: 8px;padding:8px 10px;}
</style> -->
