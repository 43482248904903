<template>
  <div class="certificate_14">
    <div class="lcrightl-02_14">
      <div class="mainl-04_14 linknav nav-zl_14">
        <router-link to="/index">首页</router-link
        >&nbsp;&gt;&nbsp;招生支持服务&nbsp;&gt;&nbsp;<font color="blue"
          >证书样本</font
        >
      </div>
    </div>
    <div class="lcright-03_14">
      <div
        class="zy_about_14 nr-z2_14"
        v-for="item of ZhengshuList"
        :key="item.id"
      >
        <!-- <p> -->
        <p v-html="item.content"></p>
        <!-- <p align="center">
	<img alt="" src="../../assets/imgs/index/2022072041350149.jpg" style="width: 740px; height: 553px;" /></p>
<p align="center">
	<img alt="" src="../../assets/imgs/index/2022072041371845.jpg" style="height: 515px; width: 754px;" /></p>
<p align="center">
	新版国家开放大学毕业证书样本</p>
<p align="center">
	&nbsp;</p>
<p align="center">
	&nbsp;</p>
<p align="center">
	&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<img alt="" src="../../assets/imgs/index/2022072041419845.jpg" style="width: 647px; height: 886px;margin: -50px auto;" /></p>
<p align="center">
	<img alt="" src="../../assets/imgs/index/2022072041472237.jpg" style="width: 647px; height: 769px;"/></p>
<p align="center" style="margin-bottom: 300px;">
	新版国家开放大学学士学位证书样本</p>
</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import consult from "@/api/yssz/consult";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  // name: "#jxjwgl",
  data() {
    return {
      img: "",
      ZhengshuList: [
        {
          title: "1",
          content: "2022",
          id: "",
        },
      ],
    };
  },

  methods: {
    async getZsList() {
      const res = await consult.getZsList(1, 8);
      console.log(res, "444");
      const list = res.data.data.list.records;
      this.ZhengshuList = list.map((it) => {
        const { alwaysTitle, alwaysContent, alwaysId } = it;
        return {
          title: alwaysTitle,
          content: alwaysContent,
          id: alwaysId,
        };
      });
    },
  },
  mounted() {
    this.getZsList();
  },
};
</script>

<style>
.certificate_14 {
  margin: -200px auto;
}
.lcrightl-02_14 {
  border-bottom: 1px solid #dadada;
  height: 54px;
  position: relative;
  width: 725px;
}
.lcright-03_14 {
  margin: 10px 10px 81px 0;
  width: 700px;
  height: 3100px;
}
.zy_about_14 {
  line-height: 2em;
  width: 715px;
}
.zy_about_14 p {
  margin-bottom: 10px;
  /* padding-bottom: 10px; */
}
.mainl-04_14 {
  height: 22px;
  line-height: 22px;
  position: absolute;
  right: 4px;
  text-align: right;
  top: 25px;
  width: 500px;
  z-index: 1;
}
.nav-zl_14 {
  color: #898989;
  font-size: 12px;
}
.nr-z2_14 {
  color: #353535;
  font-size: 13px;
}
</style>
