<template>
  <div class="guide5">
    <div class="tip5"></div>
<div class="topbox5 widths15"><img src="../../assets/imgs/index/z_logo_xuexi2.jpg"/></div>
<div class="navbox5 widths5">
<div class="nav_l fl"><img src="../../assets/imgs/index/navle1.png" style="width: 5px; height: 45px"/></div>
  <div class="nav_c5 fl">
    <ul>
        <li><router-link to="/homeguidel">指南首页</router-link></li>
          <li><router-link to="/xuanke">选课</router-link></li>
          <li><router-link to="/jihua">制定学习计划</router-link></li>
          <li><router-link to="/kaoshi">参加考试</router-link></li>
          <li><router-link to="/xuexi">网上学习</router-link></li>
          <li><router-link to="/mianshou">参加面授</router-link></li>
          <li><router-link to="/shixun">参加实验实训</router-link></li>
          <li><router-link to="/hudong">与教师同学互动</router-link></li>
      </ul>
  </div>
  <div class="nav_r fl"><img src="../../assets/imgs/index/navri1.png"  style="width: 5px; height: 45px"/></div>
</div>
  <div class="con_zy5 widths25">
          <div class="con_zy5 widths25">
            <router-view></router-view>
            </div>
        </div> 
        <div class="footbox5">
<div class="foot5 widths5">
    <p>咨询电话： 010-88259037  57519541 <a href="mailto:gkrjxy@crtvu.edu.cn">咨询邮箱</a></p>
  <p>国家开放大学软件学院版权所有 京ICP备14053447号-1 京公网安备11010802015291</p>
  </div>
</div>      
   </div>
</template>

<script>
export default {
         data(){
          return {
          tabPosition: 'left'
      };
         },
         watch: {

         },
         mounted(){
          if(location.hash == '#/learn')
          this.$router.push({path: '/homeguidel'})
         }
  }
</script>

<style>
.tip5{ width:100%; border-top:5px solid blue;} 
/* .topbox{ height:20px; padding:15px 0px; text-align:center;}  */
.widths5{ width:1000px; margin:0px auto;}
.widths15{
                 width:1000px; 
                 margin:0px auto;
                 background: #fff;
                 text-align:center;
                 padding:15px 0px;
}
.fl{ float:left;}
.widths25{
                 width:1000px; 
                 margin:0px auto;
}
.navbox5{
        height:45px;
       }
.nav_c5{background:url(../../assets/imgs/index/nav1.jpg) repeat-x; width:990px; height:45px; line-height:45px;}
.nav_c5 li{ float:left;}
/* .nav_c5 li a{ font-size:16px; color:#fff; display:block; width:120px; margin:0px 22px; text-align:center;} */
.nav_c5 li a{ font-size:16px; color:#fff; display:block; padding:0px 14px;margin:0px 10px; text-align:center;}
.nav_c5 li a:hover{ background:blue; color:#fff;}
.con_zy5{ display:block; overflow:hidden; _zoom:1; min-height:520px;}
.footbox5{ 
         background:url(../../assets/imgs/index/foottip1.png) repeat-x top center #ebebeb;
         background-size: 1px 5px;
         margin-top:20px;
        }
.foot5{ text-align:center; line-height:24px; height:75px; padding-top:20px;}
.foot5 .widths5{ width:1000px; margin:0px auto;} 
</style>