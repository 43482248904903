import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../page/Home.vue'
import Index from '../page/Index/Index.vue'
import Teachercontent from '../page/xljy/Teachercontent.vue'
import Yemian from '../page/Index/components/yemian.vue'
import Bannercontent from '../page/Index/components/bannercontent.vue'
import Overview from '../page/feixueli/Overview.vue'
import Certification from '../page/feixueli/Certification.vue'
import Course from '../page/feixueli/Course.vue'
import Teacher from '../page/feixueli/Teacher.vue'
import Center from '../page/feixueli/Center.vue'
import Train from '../page/feixueli/Train.vue'
import Search from '../page/feixueli/Search.vue'
import Zzl from '../page/feixueli/Zzl.vue'
import Zzl1 from '../page/feixueli/Zzl1.vue'
import Yxjj from '../page/about/Yxjj.vue'
import Xyld from '../page/about/Xyld.vue'
import Yzzc from '../page/about/Yzzc.vue'
import Jgsz from '../page/about/Jgsz.vue'
import Bxlc from '../page/about/Bxlc.vue'
import Xxzxfb from '../page/about/Xxzxfb.vue'
import Admission from '../page/zhaosheng/Admission.vue'
import Zhaoszx from '../page/zhaosheng/Zhaoszx.vue'
import Ascontent from '../page/zhaosheng/Ascontent.vue'
import Profession from '../page/zhaosheng/Profession.vue'
import Professioncontent from '../page/zhaosheng/Professioncontent.vue'
import Guide from '../page/zhaosheng/Guide.vue'
import HomeGuide from '../page/zhaosheng/guide/HomeGuide.vue'
import Condition from '../page/zhaosheng/guide/Condition.vue'
import Process from '../page/zhaosheng/guide/Process.vue'
import Standard from '../page/zhaosheng/guide/Standard.vue'
import Net from '../page/zhaosheng/guide/Net.vue'
import Link from '../page/zhaosheng/guide/Link.vue'
import Learn from '../page/zhaosheng/Learn.vue'
import HomeGuidel from '../page/zhaosheng/xuexi/HomeGuidel.vue'
import Xuanke from '../page/zhaosheng/xuexi/Xuanke.vue'
import Jihua from '../page/zhaosheng/xuexi/Jihua.vue'
import Kaoshi from '../page/zhaosheng/xuexi/Kaoshi.vue'
import Xuexi from '../page/zhaosheng/xuexi/Xuexi.vue'
import Mianshou from '../page/zhaosheng/xuexi/Mianshou.vue'
import Shixun from '../page/zhaosheng/xuexi/Shixun.vue'
import Hudong from '../page/zhaosheng/xuexi/Hudong.vue'
import Luqu from '../page/zhaosheng/Luqu.vue'
import Certificate from '../page/zhaosheng/Certificate.vue'
import Answers from '../page/zhaosheng/Answers.vue'
import Zkzy from '../page/xljy/Zkzy.vue'
import Bkzy from '../page/xljy/Bkzy.vue'
import Tskc from '../page/xljy/Tskc.vue'
import Tskcontent from '../page/xljy/Tskcontent.vue'
import Xxfb from '../page/jxzcfw/Xxfb.vue'
import Xxfbcontent from '../page/jxzcfw/Xxfbcontent.vue'
import Jxjwgl from '../page/jxzcfw/Jxjwgl.vue'
import Jxglcontent from '../page/jxzcfw/Jxglcontent.vue'
import Xkjs from '../page/jxzcfw/Xkjs.vue'
import Szdw from '../page/jxzcfw/Szdw.vue'
import Mxmk from '../page/jxzcfw/Mxmk.vue'
import Jypg from '../page/jxzcfw/Jypg.vue'
import Xsyd from '../page/jxzcfw/Xsyd.vue'
import Xscontent from '../page/jxzcfw/Xscontent.vue'
import News from '../page/Index/components/News.vue'
import Gonggao from '../page/news/Gonggao.vue'
import Yuanxiao from '../page/news/Yuanxiao.vue'
import Hangye from '../page/news/Hangye.vue'
import Ggcontent from '../page/news/Ggcontent.vue'
import Yxcontent from '../page/news/Yxcontent.vue'
import Hycontent from '../page/news/Hycontent.vue'

import KnowNewMedia from '../page/jxzcfw/KnowNewMedia.vue'
import MediaContent from '../page/jxzcfw/MediaContent.vue'
import HowRunMedia from '../page/jxzcfw/HowRunMedia.vue'
import HowRunMediaContent from '../page/jxzcfw/HowRunMediaContent.vue'
import MediaOther from '../page/jxzcfw/MediaOther.vue'
import MediaOtherContent from '../page/jxzcfw/MediaOtherContent.vue'
import NewMediaProperty from '../page/jxzcfw/NewMediaProperty.vue'
import NewMediaPropertyContent from '../page/jxzcfw/NewMediaPropertyContent.vue'
import UseMediaTool from '../page/jxzcfw/UseMediaTool.vue'
import UseMediaToolContent from '../page/jxzcfw/UseMediaToolContent.vue'
const About = () => import('@/page/About')
const Xljy = () => import('@/page/Xljy')
const Fxljy = () => import('@/page/Fxljy')
const Zhaosheng = () => import('@/page/Zhaosheng')
const Jiaoxue = () => import('@/page/Jiaoxue')
const Cooperate = () => import('@/page/Cooperate')
const Download = () => import('@/page/Download')
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/index',
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/index',
        name: 'index',
        component: Index,
      },
      {
        path: '/index/:id',
        name: 'teachercontent',
        component: Teachercontent,
      },
      {
        path: '/yemian',
        name: 'yemian',
        component: Yemian,
      },
      {
        path: '/yemian/:id',
        name: 'bannercontent',
        component: Bannercontent,
      },
      {
        path: '/news',
        name: 'news',
        component: News,
        children: [
          {
            path: '/gonggao',
            name: 'gonggao',
            component: Gonggao,
          },
          {
            path: '/gonggao/:id',
            name: 'ggcontent',
            component: Ggcontent,
          },
          {
            path: '/yuanxiao/:id',
            name: 'yxcontent',
            component: Yxcontent,
          },
          {
            path: '/Yuanxiao',
            name: 'yuanxiao',
            component: Yuanxiao,
          },
          {
            path: '/hangye',
            name: 'hangye',
            component: Hangye,
          },
          {
            path: '/hangye/:id',
            name: 'hycontent',
            component: Hycontent,
          },
        ],
      },
      {
        path: '/about',
        name: 'about',
        component: About,
        children: [
          {
            path: '/yxjj',
            name: 'yxjj',
            component: Yxjj,
          },
          {
            path: '/xyld',
            name: 'xyld',
            component: Xyld,
          },
          {
            path: '/yzzc',
            name: 'yzzc',
            component: Yzzc,
          },
          {
            path: '/jgsz',
            name: 'jgsz',
            component: Jgsz,
          },
          {
            path: '/bxlc',
            name: 'bxlc',
            component: Bxlc,
          },
          {
            path: '/xxzxfb',
            name: 'xxzxfb',
            component: Xxzxfb,
          },
        ],
      },
      {
        path: '/xljy',
        name: 'xljy',
        component: Xljy,
        children: [
          {
            path: '/zkzy',
            name: 'zkzy',
            component: Zkzy,
          },
          {
            path: '/bkzy',
            name: 'bkzy',
            component: Bkzy,
          },
          {
            path: '/tskc',
            name: 'tskc',
            component: Tskc,
          },
          {
            path: '/tskc/:id',
            name: 'tskcontent',
            component: Tskcontent,
          },
        ],
      },
      {
        path: '/fxljy',
        name: 'fxljy',
        component: Fxljy,
        children: [
          {
            path: '/overview',
            name: 'overview',
            component: Overview,
          },
          {
            path: '/certification',
            name: 'certification',
            component: Certification,
          },
          {
            path: '/course',
            name: 'course',
            component: Course,
          },
          {
            path: '/teacher',
            name: 'teacher',
            component: Teacher,
          },
          {
            path: '/center',
            name: 'center',
            component: Center,
          },
          {
            path: '/train',
            name: 'train',
            component: Train,
          },
          {
            path: '/search',
            name: 'search',
            component: Search,
          },
          {
            path: '/center/:id',
            name: 'zzl',
            component: Zzl,
          },
          {
            path: '/train/:id',
            name: 'zzl1',
            component: Zzl1,
          },
        ],
      },
      {
        path: '/zhaosheng',
        name: 'zhaosheng',
        component: Zhaosheng,
        children: [
          {
            path: '/admission',
            name: 'admission',
            component: Admission,
          },
          {
            path: '/admission/:id',
            name: 'zhaoszx',
            component: Zhaoszx,
          },
          {
            path: '/profession',
            name: 'profession',
            component: Profession,
          },
          {
            path: '/profession/:id',
            name: 'professioncontent',
            component: Professioncontent,
          },
          {
            path: '/luqu',
            name: 'luqu',
            component: Luqu,
          },
          {
            path: '/certificate',
            name: 'certificate',
            component: Certificate,
          },
          {
            path: '/answers',
            name: 'answers',
            component: Answers,
          },
          {
            path: '/answers/:id',
            name: 'ascontent',
            component: Ascontent,
          },
        ],
      },
      {
        path: '/jiaoxue',
        name: 'jiaoxue',
        component: Jiaoxue,
        children: [
          {
            path: '/jxjwgl',
            name: 'jxjwgl',
            component: Jxjwgl,
          },
          {
            path: '/jxjwgl/:id',
            name: 'jxglcontent',
            component: Jxglcontent,
          },
          {
            path: '/xxfb',
            name: 'xxfb',
            component: Xxfb,
          },
          {
            path: '/xxfb/:id',
            name: 'xxfbcontent',
            component: Xxfbcontent,
          },
          {
            path: '/xkjs',
            name: 'xkjs',
            component: Xkjs,
          },
          {
            path: '/szdw',
            name: 'szdw',
            component: Szdw,
          },
          {
            path: '/mxmk',
            name: 'mxmk',
            component: Mxmk,
          },
          {
            path: '/jypg',
            name: 'jypg',
            component: Jypg,
          },
          {
            path: '/xsyd',
            name: 'xsyd',
            component: Xsyd,
          },
          {
            path: '/xsyd/:id',
            name: 'xscontent',
            component: Xscontent,
          },
        ],
      },
      {
        path: '/cooperate',
        name: 'cooperate',
        component: Cooperate,
        children: [
          {
            path: '/knowNewMedia',
            name: 'knowNewMedia',
            component: KnowNewMedia,
          },
          {
            path: '/knowNewMedia/:id',
            name: 'mediaContent',
            component: MediaContent,
          },
          {
            path: '/howRunMedia',
            name: 'howRunMedia',
            component: HowRunMedia,
          },
          {
            path: '/howRunMedia/:id',
            name: 'howRunMediaContent',
            component: HowRunMediaContent,
          },
          {
            path: '/mediaOther',
            name: 'mediaOther',
            component: MediaOther,
          },
          {
            path: '/mediaOther/:id',
            name: 'mediaOtherContent',
            component: MediaOtherContent,
          },
          {
            path: '/newMediaProperty',
            name: 'newMediaProperty',
            component: NewMediaProperty,
          },
          {
            path: '/newMediaProperty/:id',
            name: 'newMediaPropertyContent',
            component: NewMediaPropertyContent,
          },
          {
            path: '/useMediaTool',
            name: 'useMediaTool',
            component: UseMediaTool,
          },
          {
            path: '/useMediaTool/:id',
            name: 'useMediaToolContent',
            component: UseMediaToolContent,
          },
        ],
      },
      {
        path: '/download',
        name: 'download',
        component: Download,
      },
      {
        path: '/searchAll',
        name: 'searchAll',
        component: () => import('../page/Sousuo.vue'),
        // component: Sousuo,
      },
    ],
  },
  {
    path: '/guide',
    name: 'guide',
    component: Guide,
    children: [
      {
        path: '/homeguide',
        name: 'homeguide',
        component: HomeGuide,
      },
      {
        path: '/condition',
        name: 'condition',
        component: Condition,
      },
      {
        path: '/process',
        name: 'process',
        component: Process,
      },
      {
        path: '/standard',
        name: 'standard',
        component: Standard,
      },
      {
        path: '/net',
        name: 'net',
        component: Net,
      },
      {
        path: '/link',
        name: 'link',
        component: Link,
      },
    ],
  },
  {
    path: '/learn',
    name: 'learn',
    component: Learn,
    children: [
      {
        path: '/homeguidel',
        name: 'homeguidel',
        component: HomeGuidel,
      },
      {
        path: '/xuanke',
        name: 'xuanke',
        component: Xuanke,
      },
      {
        path: '/jihua',
        name: 'jihua',
        component: Jihua,
      },
      {
        path: '/kaoshi',
        name: 'kaoshi',
        component: Kaoshi,
      },
      {
        path: '/xuexi',
        name: 'xuexi',
        component: Xuexi,
      },
      {
        path: '/mianshou',
        name: 'mianshou',
        component: Mianshou,
      },
      {
        path: '/shixun',
        name: 'shixun',
        component: Shixun,
      },
      {
        path: '/hudong',
        name: 'hudong',
        component: Hudong,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
