import { render, staticRenderFns } from "./Yzzc.vue?vue&type=template&id=531d4fa8&scoped=true&"
import script from "./Yzzc.vue?vue&type=script&lang=js&"
export * from "./Yzzc.vue?vue&type=script&lang=js&"
import style0 from "./Yzzc.vue?vue&type=style&index=0&id=531d4fa8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531d4fa8",
  null
  
)

export default component.exports