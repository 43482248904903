<template>
  <div class="admission_11">
    <div class="lcrightl-02_11">
                 <div class="mainl-04_11 linknav nav-zl_11">
                  <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;招生支持服务&nbsp;&gt;&nbsp;<font color="blue">招生资讯</font>
                  </div> 
              </div> 
              <div class="lcright-03_11">
                	<div class="zy_news_11 nr-z2_11" v-for="con in consuList"  :key="con.title" >
                <ul>
                  <li>
                                <div class="newstext_11 fr_11" style="float: left">
                                   <h2><router-link :to="consuUrl + con.id">{{con.title}}</router-link></h2>
                                   <router-link :to="consuUrl + con.id"><p v-html="con.content" class="content"></p></router-link>
                                   <!-- <p>{{i.content}}</p> -->
                                   <p class="newstextmore_11"><router-link :to="consuUrl + con.id">{{con.date}}</router-link></p>
                                  </div>
                            </li>
   </ul>
</div>
<Paning :total="total" :count="count"></Paning>
                 </div>   
                </div>
</template>

<script>
import Paning from "@/components/Paning.vue"
import consult from '@/api/yssz/consult'
export default {
  components:{
    Paning,
  },
  data() {
    return {
      consuList:[
        {
          title: '1',
          date: '2022',
          content: '',
          id: '',
        },
      ],
      count: '15',
    page: '1',
      consuUrl: '/admission/' 
   }
  },
  computed:{
    pageList(){
      console.log(this.consuList.slice(this.count * this.page-1, this.count * this.page),'j')
      return this.consuList.slice(this.count * (this.page-1), this.count * this.page)
    },
    total(){
      return this.consuList.length
    }
  },
watch:{
    '$route.query.page'(){
      this.page = this.$route.query.page
      if(!this.page) {
        this.$router.replace({query: {...this.$route.query, page: 1}})
      }
    }
  },
  methods: {
    idToRouter(id) {
      return '/admission/'+id
    },
    async getConList(){
      const res = await consult.getConList(1, 8)
      console.log(res,'consult')
      const list = res.data.data.list.records
      if(!this.$route.query.page)
        this.$router.replace({query: {...this.$route.query, page: 1}})
      console.log(this.consuList, 'consult')
      this.consuList = list.map(consu => {
        const {consultTitle, createTime, consultId, consultContent} = consu
        let el = document.createElement( 'div' );
        el.innerHTML = consultContent;
        const content = Array.from(el.getElementsByTagName('p')).map(it => it.innerText).join(' ')
        console.log(el.getElementsByTagName('p'), 'sjdf', consultContent)
        return {
          title: consultTitle,
          date: createTime.split('T')[0],
          id: consultId,
          content
          // : consultContent
        }
      })
    },
  },
  mounted() {
    this.getConList()
  }
}
</script>

<style lang="scss" scoped>
.admission_11{
  margin: -200px auto;
}
.lcrightl-02_11 { 
                  border-bottom: 1px solid #dadada;
                  height: 54px;
                  position: relative;
                  width: 725px;
                  /* margin: -200px auto; */
                }
  .lcright-03_11 {margin: 10px 10px 81px 0;width: 715px;}
  .zy_about_11 {line-height: 2em;width: 715px;}
  .zy_about_11 p{ margin-bottom:10px;}
  .mainl-04_11 {
                height: 22px;
                line-height: 22px;
                position: absolute;
                right: 4px;
                text-align: right;
                top: 25px;
                width: 500px;
                z-index: 1;}
  .nav-zl_11 {
              color: #898989;
              font-size: 12px;}
  .nr-z2_11 {color: #353535;font-size: 12px;}
.lcright-03_11 {
                margin: 10px 10px 81px 0;
                width: 715px;
                height: 1000px;
              }
.zy_news_11{
             line-height: 2em;
             width: 715px;}
.zy_news_11 li{ height:90px; padding-top:15px; border-bottom:1px solid #ebebeb;}
/* .zy_news_11 li .newspic_11{ width:93px;}
.zy_news_11 li .newspic_11 img{ width:93px; height:75px;} */
.zy_news_11 li .newstext_11{ 
                             width:715px; 
                             line-height:23px;
                             /* padding: -100px auto; */
                             margin: -10px auto;
                            }
.zy_news_11 li .newstext_11 a{ color:#353535;}
.zy_news_11 li .newstext_11 h2{ line-height:24px;}
.zy_news_11 li .newstext_11 h2 a{font-size:16px; font-weight:bold; color:black;}
.zy_news_11 li .newstextmore_11 a{ color:blue;}
.zy_news_11 li .newstextmore_11 a:hover{ text-decoration:underline;}
.nr-z2_11 {color: #353535;font-size: 12px;}
.fr_11{ float:right;}
.content{
  // width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
  display: -webkit-box;
	/*限制在一个块元素显示文本的行数*/
	-webkit-line-clamp: 1;/*2行*/
	/*设置或检索伸缩盒对象子元素的排列方式*/
	-webkit-box-orient: vertical;
}
</style>