<template>
    <div class="zzzl">
      <div class="lcright-02">
        <div class="main-04 linknav nav-z">
          <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;新闻中心&nbsp;&gt;&nbsp;<font color="blue">通知公告</font>
        </div> 
        </div>
        
                <div class="lcright-03">
                	<div class="zy_about nr-z2 ">
                    	<p style="text-align: center; ">
                        <span style="font-size:16px;line-height:47px; height:47px;color:black;font-weight:bold; ">{{not.title}}</span>
                      </p>
                      <p style="text-align: center; background-color:#e7e7e7; margin-bottom:10px; ">
                        <span style="font-size:12px;  color:#444; font-family: 微软雅黑, 'Microsoft Yahei';line-height:27px; height:27px; ">发布时间：{{not.date}}&nbsp;   
                        </span>
                      </p>
                    	<p>
                        <p>
	                   <span style="color: rgb(102, 102, 102); font-family: 微软雅黑, 'Microsoft Yahei'; line-height: 26px;" v-html="not.content"></span>
                    </p>
                  </p>
                    
                </div> 

      </div> 
    </div>
</template>
  
<script>
import consult from "@/api/yssz/consult";
export default {
  data() {
    return {
      not: {
        title: '',
        content: '',
        date:'',
      }
      
    }
    },
    computed: {
      // news() {
      //   return this.newsList[this.$route.params.id - 1]
      // },
    },
    methods: {
      idToRouter(id) {
        return '/gonggao/'+id
      },
      async getNot(){
        let res = await consult.getNotId(this.$route.params.id)
        let notice = res.data.data.notice
        console.log(res)
        this.not = {
          title: notice.noticeTitle,
          content: notice.noticeContent,
          date:this.$moment(notice.createTime).format('l')+' '+this.$moment(notice.createTime).format('LTS'),
        }
      }
    },
    mounted() {
    this.getNot();

  }
//   data() {
//     return {
//       img: "",
//       noticeList: [
//         {
//           title: "1",
//           date: "2022",
//           id: "",
//         },
//       ],
//         content: '',
//       noticeUrl:'/gonggao/'
//     };
//   },

//   methods: {
//     idToRouter(id) {
//         return '/gonggao/'+id
//       },
//     async getNotList() {
//       const res = await this.$http.get('api/noticeCenter/getNoticePageList/' + this.$route.params.id)
//       console.log(res, "sss");
//       const list = res.data.data.list.records;
//       this.noticeList = list.map((it) => {
//         const {noticeTitle, createTime, noticeId,noticeContent} = it
//         return {
//           title: noticeTitle,
//           date: createTime.split("T")[0],
//           id: noticeId,
//           content:noticeContent,
//         };
//       });
//     },
//   },
//   mounted() {
//     this.getNotList();
//   },
};
</script>
  
<style lang="scss" scoped>
  .lcright-02 {border-bottom: 1px solid #dadada;height: 54px;position: relative;width: 725px;}
    .lcright-03 {margin: 10px 10px 81px 0;width: 715px;}
    .zy_about {line-height: 2em;width: 715px;}
    .zy_about p{ margin-bottom:10px;}
    .main-04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
    .nav-z {color: #898989;font-size: 12px;}
    .nr-z2 {color: #353535;font-size: 13px;}
    .lcright-03 {margin: 10px 10px 81px 0;width: 715px;}
  </style>