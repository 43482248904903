<template>
  <div class="news_hangye fl">
    <div class="newstit2">
      <span><router-link to="/hangye">More</router-link></span>
      <div class="newstittext2">
        <h2>行业动态</h2>
      </div>
    </div>
    <div class="newslist">
      <div class="newslistpic"> <img src="../static/picture/2020091630281827.jpg" width="276" height="108">
      </div>
      <div class="newslisttext">
        <ul>
          <li v-for="JX of consultList" :key="JX.title">
            <!-- <router-link class="item" :to="hangyeUrl + JX.id">  -->
            <span><router-link class="item" :to="hangyeUrl + JX.id"> [{{JX.date}}] </router-link></span>
             <a><router-link class="item" :to="hangyeUrl + JX.id">{{JX.title}}</router-link></a>
          </li>
          <!-- <li><span>[2021-07-28]</span><a href="javascript:;" title="2021中国软件教育年会">2021中国软件教育年会</a></li>
          <li><span>[2021-03-11]</span><a href="javascript:;" title="2021中国软件产业年会">2021中国软件产业年会</a></li>
          <li><span>[2019-07-24]</span><a href="javascript:;"
              title="2018年中国软件业收入不断增长，从业人数呈现增长态势">2018年中国软件业收入不断增长，从业人数呈现增长态势</a></li>
          <li><span>[2019-04-17]</span><a href="javascript:;"
              title="教育部等四部门部署启动1+X证书制度试点工作">教育部等四部门部署启动1+X证书制度试点工作</a></li>
          <li><span>[2019-03-18]</span><a href="javascript:;"
              title="2018年中国软件行业发展现状，软件工程技术将朝着全球化、开放性、模块化和简易化方向发展">2018年中国软件行业发展现状，软件工程技术将朝着全球化、开..</a></li> -->


        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
export default {
    data() {
      return {
        img:'',
        consultList:[
          {
            title: '1',
            date: '2022',
            id: '',
          },
        ],
        hangyeUrl:'/hangye/'
      }
    },
   methods: {
    idToRouter(id) {
        return '/hangye/'+id
      },
    async getList(){
      // console.log('ji')
      const res = await consult.getDynList(1,5)
      console.log(res,'res123')
      const list = res.data.data.list.records
      this.consultList = list.map(it => {
        const {dynamicTitle, createTime, dynamicId} = it
        return {
          title: dynamicTitle,
          date: createTime.split('T')[0],
          id: dynamicId,
        }
      })

    }
   },
   created() {
    this.getList()
   }
}
</script>

<style lang="scss" scoped>
.flor2 {
  padding: 30px 8px 35px;
  overflow: hidden;
}

.flor4 {
  padding: 0px 8px;
}

.news_zhaosheng,
.news_jiaowu,
.news_hangye {
  width: 286px;
  height: 344px;
}

.news_line {
  background: url(../image/newsline.jpg) no-repeat center 52px;
  width: 63px;
  height: 253px;
}

.newstit2 {
  height: 39px;
  line-height: 39px;
  border-bottom: 2px solid #999999;
  background: url(http://rjxy.ouchn.edu.cn/images/blaline.png) no-repeat center bottom;
  padding-right: 3px;
  font-size: 18px;
}

.newstittext2 h2 {
  font-size: 18px;
  margin: -2px 0 0 0;
  padding-right: 3px;
  border-bottom: 2px solid blue;
  display: block;
  float: left;
}

.newstit2 span a {
  font-size: 14px;
}

.newstit2 span a:hover {
  color: blue;
}

.newstit2 span {
  float: right;
  background: url(../image/more.jpg) no-repeat right center;
  padding-right: 20px;
}

.newslistpic {
  width: 286px;
  height: 118px;
  margin-top: 13px;
}

.newslistpic img {
  width: 276px;
  height: 108px;
  padding: 4px;
  border: 1px solid #dedede;
}

.newslisttext {
  height: 160px;
  overflow: hidden;
  padding: 3px 10px 0px 5px;
}

.newslisttext li {
  line-height: 32px;
}

.newslisttext li span {
  float: left;
  width: 75px;
  color: blue;
  margin-right: 15px;
}

.newslisttext li a {
  display: block;
  float: left;
  width: 178px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.newslisttext_about {
  line-height: 32px;
  height: 160px;
  overflow: hidden;
  padding: 3px 5px 0px;
}

.newslisttext_about a {
  color: #343434;
}

.newslisttext_about a:hover {
  color: blue;
}
</style>
