<template>
    <div class="lcright_9">
      <div class="lcright-01_9">
          <div class="lcright-02_9">
              <div class="main-04_9 linknav nav-z_9">
                  <router-link to="/homeguidel">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">与教师同学互动</font>
              </div>
          </div>
          <div class="lcright-03_9">
                	<div class="zy_hudong_texts_9">
                    	<p></p>
                    </div>
                	
    <div v-for="h of homeList" :key="h.title" class="zy_hudong_9 nr-z2_9">
<ul >

<li v-html="h.content">
    <!-- {{a.content}} -->
    <!-- <router-link to="/hudong">
        <img src="@/assets/imgs/index/2019082354755737.jpg" width="186" height="138" />
    </router-link> -->
    <!-- <p>
         <router-link to="/hudong">参加校内实训</router-link> 
    </p> -->
</li>



<!-- <li><router-link to="/hudong"><img src="@/assets/imgs/index/2019082354701713.jpg" width="186" height="138" /></router-link><p><router-link to="/hudong">拜访当地软件企业</router-link></p></li>



<li><router-link to="/hudong"><img src="@/assets/imgs/index/2019082354329137.jpg" width="186" height="138" /></router-link><p><router-link to="/hudong">专业课答疑</router-link></p></li>



<li><router-link to="/hudong"><img src="@/assets/imgs/index/2019082354598441.jpg" width="186" height="138" /></router-link><p><router-link to="/hudong">开学典礼后合影</router-link></p></li> -->

</ul>
</div>
<div class='fanye_9'>
    <span class='disabled_9'> < </span> 
    <span class='current_9'>1</span>
    <span class='disabled_9'> > </span></div>

                </div>
            </div>
      </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            img: '',
            homeList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getHomeList(){
      const res = await consult.getHomeList(7, 1)
      console.log(res,'home')
      const list = res.data.data.list.records
      this.homeList = list.map(home => {
        const {alwaysTitle, createTime, alwaysId, alwaysContent} = home
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getHomeList()
  }
  }
</script>

<style>
.lcright_9 {
    float: left; 
    width:1000px;
    margin-top: 200px;
   }
.lcright-01_9 {margin-right:10px;}
.lcright-02_9 {border-bottom: 1px solid #dadada;
       height: 54px;
       position: relative;
       width: 1000px;
      }
.main-04_9 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z_9 {color: #898989;font-size: 12px;}
.lcright-03_9 {margin: 10px 10px 81px 0;}
.nr-z2_9 {
          color: #353535;
          font-size: 13px;
          width: 1000px;
         }
.zy_hudong_texts_9{ padding:10px 30px; line-height:26px;}
.zy_about_9,.zy_hudong_9,.zy_hudong_9 ul{ display:block; overflow:hidden; _zoom:1;}
.zy_about_9 {line-height: 2em;}
.zy_about_9 p{ margin-bottom:10px;}
.zy_hudong_9{
             line-height: 2em;
             padding-top:15px;
             
            }
.zy_hudong_9 li{ 
                 float:left; 
                 margin:0px 20px 20px 35px; 
                 text-align:center;
                 /* flex: left; */
                 /* display: flex; */
                 /* display:inline-block; */
                /* 当内容在一行容不下时不换行
                flex-wrap: nowrap; */
                /* display:inline */
                }
.zy_hudong_9 li img{ width:186px; height:138px; border:1px solid #dedede;}
.zy_hudong_9 li p{ 
                   line-height:28px;
                }
DIV.fanye_9 { clear:both;margin: 35px 0px 18px 0px;*margin: 25px 0px 35px 0px; display:block; overflow:hidden; _zoom:1; height:23px; line-height:23px;text-align: center;width: 100%;}
DIV.fanye_9 A {background: #e6e6e6;color: #333;margin-right: 8px;padding:8px 10px;text-decoration: none;}
DIV.fanye_9 A:hover {background:blue;color: #fff;margin-right: 8px;}
DIV.fanye_9 A:active {background:blue;color: #fff;margin-right: 8px;}
DIV.fanye_9 SPAN.current_9 {background:blue;color: #fff;font-weight: bold;margin-right: 8px;padding:8px 10px;}
DIV.fanye_9 SPAN.disabled_9 {color:#333;margin-right: 8px;padding:8px 10px;}
</style>