<template>
    <div class="tsck_16">
    <div class="lcright-02_16">
                <div class="main-04_16 linknav nav-z_16">
                    <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;学历教育&nbsp;&gt;&nbsp;<font color="blue">特色课程</font>
                </div>
            </div>
            <div class="lcright-03_16">
                      <div class="zy_about_16 nr-z2_16">
                          <p style="text-align: center;">
                          <span style="font-size:16px;line-height:47px; height:47px;color:black;font-weight:bold;">
                            {{cou.title}}
                          </span>
                        </p>
                        <p style="text-align: center; background-color:#e7e7e7; margin-bottom:10px; ">
                          <span style="font-size:12px;  color:#444; font-family: 微软雅黑, 'Microsoft Yahei'; line-height:27px; height:27px; ">发布时间：{{cou.date}}
                          </span>
                        </p>
                          <p>
                          <p>
                         <span v-html="cou.content" style="color: rgb(102, 102, 102); font-family: 微软雅黑, 'Microsoft Yahei'; line-height: 26px;"></span>
                      </p>
                    </p>
                      </div>
                  </div> 
    </div>
  </template>
  
  <script>
  import consult from "@/api/yssz/consult";
  export default {
  data() {
    return {
      cou: {
        title: '',
        content: '',
        date:'',
      }
      
    }
    },
    computed: {
      
    },
    methods: {
      idToRouter(id) {
        return '/tskc/'+id
      },
      async getCou(){
        let res = await consult.getCouId(this.$route.params.id)
        let cou= res.data.data.course
        console.log(res)
        this.cou = {
          title: cou.courseTitle,
          content: cou.courseContent,
          date:this.$moment(cou.createTime).format('l')+' '+this.$moment(cou.createTime).format('LTS'),
        }
      }
    },
    mounted() {
    this.getCou();
  
  }
  };
  </script>
  
  <style lang="scss" scoped>
  .tsck_16 {
    margin-top: -200px;
    margin-bottom:100px;
  }
  .lcright-02_16 { border-bottom: 1px solid #dadada;
                   height: 54px;
                   position: relative;
                   width: 725px;
        }
  .main-04_16 {height: 22px;
               line-height: 22px;
               position: absolute;
               right: 4px;
               text-align: right;
               top: 25px;
               width: 500px;
               z-index: 1;}
  .nav-z_16 {color: #898989;font-size: 12px;}
  .lcright-03_16 {margin: 10px 10px 81px 0;
               width: 715px;}
  .zy_about_16 {line-height: 2em;
             width: 715px;}
  .zy_about_16 p{ margin-bottom:10px;}
  .main-04_16 {height: 22px;
            line-height: 22px;
            position: absolute;
            right: 4px;
            text-align: right;
            top: 25px;
            width: 500px;
            z-index: 1;}
  .nav-z_16 {color: #898989;
          font-size: 12px;}
  .nr-z2_16 {color: #353535;
          font-size: 13px;}
  </style>