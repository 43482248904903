<template>
    <div class="xxzxfb">
        <div class="lcright-02">
                 <div class="main-04 linknav nav-z">
                  <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;学院概况&nbsp;&gt;&nbsp;<font color="blue">学习中心分布</font>
                  </div> 
              </div>
    <div class="lcright-03">
                	<div class="zy_about nr-z2">
                    	<p><p>
	<img alt="" src="../../assets/imgs/index/R-C (2).jpg" style="width: 626px; height: 448px;" /></p>
<table border="0" cellpadding="0" cellspacing="0" width="568">
	<colgroup>
		<col span="4" />
	</colgroup>
	<tbody>
		<tr height="32">
			<td height="32" style="height:32px;width:143px;">
				<span style="font-size:12px;"><strong>东北区</strong></span></td>
			<td style="width:143px;">
				&nbsp;</td>
			<td style="width:143px;">
				&nbsp;</td>
			<td style="width:143px;">
				&nbsp;</td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=469"><span style="font-size:12px;">长春思学学习中心</span></a></td>
			<td style="width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=544"><span style="font-size:12px;">哈尔滨东方龙育学习中心</span></a></td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
		</tr>
		<tr height="32">
			<td height="32" style="height:32px;">
				<strong><span style="font-size:12px;">华北区</span></strong></td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=433">内蒙北方联合学习中心</a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=431">河北青年学习中心</a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=560">太原晟新学习中心</a></td>
			<td>
				&nbsp;</td>
		</tr>
		<tr height="32">
			<td height="32" style="height:32px;">
				<span style="font-size:12px;"><strong>华东区</strong></span></td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=302"><span style="font-size:12px;">泉州南洋学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=437"><span style="font-size:12px;">福州蓝桥学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=438"><span style="font-size:12px;">厦门哈德学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=357"><span style="font-size:12px;">合肥新航学习中心</span></a></td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=539"><span style="font-size:12px;">上海房地产学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=467"><span style="font-size:12px;">青岛新视点学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=432"><span style="font-size:12px;">德州方圆学心中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=441"><span style="font-size:12px;">苏州高盛学习中心</span></a></td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=542"><span style="font-size:12px;">南京中蔺学习中心</span></a></td>
			<td style="width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=541"><span style="font-size:12px;">无锡新世纪学习中心</span></a></td>
			<td style="width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=545"><span style="font-size:12px;">连云港华普学习中心</span></a></td>
			<td style="width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=546"><span style="font-size:12px;">南通上元学习中心</span></a></td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=549"><span style="font-size:12px;">泰州上元学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=557">阜阳信息工程学习中心</a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=559">怀远江淮学习中心</a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=571">南昌经济技术开发区英豪培训学校学习中心</a></td>
		</tr>
		<tr height="32">
			<td height="32" style="height:32px;">
				<span style="font-size:12px;"><strong>华南区</strong></span></td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=435"><span style="font-size:12px;">广西学中学学习中心</span></a></td>
			<td style="width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=538"><span style="font-size:12px;">珠海英迪学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=470"><span style="font-size:12px;">深圳跨世纪学习中心</span></a></td>
			<td style="width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=536"><span style="font-size:12px;">惠州孚澳学习中心</span></a></td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=466"><span style="font-size:12px;">汕头圆梦学习中心</span></a></td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
		</tr>
		<tr height="32">
			<td height="32" style="height:32px;">
				<span style="font-size:12px;"><strong>华中区</strong></span></td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=301"><span style="font-size:12px;">武汉弘立学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=468"><span style="font-size:12px;">吉安博乐学习中心</span></a></td>
			<td style="width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=547">湖南培源学习中心</a></td>
			<td style="width:143px;">
				&nbsp;</td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;">
				<strong>西北区</strong></td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=569">新疆科技职业技术学院学习中心</a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=570">兰州连邦职业培训学校学习中心</a></td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
		</tr>
		<tr height="32">
			<td height="32" style="height:32px;">
				<strong><span style="font-size:12px;">西南区</span></strong></td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=440"><span style="font-size:12px;">贵阳奥太孚格学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=534"><span style="font-size:12px;">广安世纪学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=535"><span style="font-size:12px;">四川协和学习中心</span></a></td>
			<td style="width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=568"><span style="font-size:12px;">昆明市西山方亚培训学校学习中心</span></a></td>
		</tr>
		<tr height="19">
			<td height="19" style="height:19px;width:143px;">
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=548"><span style="font-size:12px;">毕节银海学习中心</span></a></td>
			<td>
				<a href="http://rjxy.ouchn.edu.cn/newsny.asp?id=558">重庆华绣学习中心</a></td>
			<td>
				&nbsp;</td>
			<td>
				&nbsp;</td>
		</tr>
        </tbody>
    </table>
    </p>
</div>
    </div>
    </div>
</template>
<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "#xxzxfb",
};
</script>
<style lang="scss" scoped>
.xxzxfb{
    // width: 100%;
    // // margin-left: 30px;
    // // height: 493px;
    // height: 1000px;
    margin: -200px auto;
    // height: 3000px;
   }
    .top{
       /* float: right;
       display: flex; */
       height: 60px;
       border-bottom: 1px solid #cdd1d4;
       width: 100%;
       .right-title{
           display: flex;
           float: right;
           margin-top: 25px;
           width: 100%;
           font-size: 15px;
        }
    }
    .lcright-03 {margin: 10px 10px 81px 0;width: 715px;}
    .zy_about {line-height: 2em;width: 715px;}
    .zy_about p{ margin-bottom:10px;}
    .nr-z2 {color: #353535;font-size: 13px;padding-bottom:200px}
    .lcright-02 {border-bottom: 1px solid #dadada;height: 54px;position: relative;width: 725px;}
.main-04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z {color: #898989;font-size: 12px;}
</style>
