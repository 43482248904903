<template>
  <div class="lcright_8">
  <div class="lcright-01_8">
      <div class="lcright-02_8">
          <div class="main-04_8 linknav nav-z_8">
              <router-link to="/homeguidel">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">参加实验实训</font>
            </div>
        </div>
        <div class="lcright-03_8">
                	<div class="zy_about_8 nr-z2_8" v-for="h of homeList" :key="h.title">
                    	<p>
                            <p v-html="h.content"></p>
                            <!-- <p>
	参加校内实训：</p>
 <p>
	<img alt="" src="http://www.ssouchn.cn/UploadFiles/images/2019082353872805.jpg" style="width: 500px; height: 333px;" /></p> 
<p>
	拜访当地软件企业：</p> -->
<!-- <p>
	<img alt="" src="http://www.ssouchn.cn/UploadFiles/images/2019082353976081.jpg" style="width: 690px; height: 518px;" /></p> -->
</p>
                    </div>
                </div>
    </div>
</div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            img: '',
            homeList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getHomeList(){
      const res = await consult.getHomeList(6, 1)
      console.log(res,'home')
      const list = res.data.data.list.records
      this.homeList = list.map(home => {
        const {alwaysTitle, createTime, alwaysId, alwaysContent} = home
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getHomeList()
  }
  }
</script>

<style>
.lcright_8 {
  float: left; 
  width:1000px;
  margin-top: 200px;
 }
.lcright-01_8 {margin-right:10px;}
.lcright-02_8 {border-bottom: 1px solid #dadada;
     height: 54px;
     position: relative;
     width: 1000px;
    }
.main-04_8 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z_8 {color: #898989;font-size: 12px;}
.lcright-03_8 {margin: 10px 10px 81px 0;}
.zy_about_8 {line-height: 2em;}
.zy_about_8 p{ margin-bottom:10px;}
.nr-z2_8 {
          color: #353535;
          font-size: 13px;
          width: 1000px;
         }
</style>