import request from '@/utils/request'

export default {
  //根据id获取单个内容
  getConId(id) {
    return request({
      url: `api/consultCenter/getConsultById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getConList(page, limit, keyword = '') {
    return request({
      url: `api/consultCenter/getConsultPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },

  getEduList(page, limit, keyword = '') {
    return request({
      url: `api/educationCenter/getEducationPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },
  getEduId(id) {
    return request({
      url: `api/educationCenter/getEducationById/${id}`,
      method: 'get',
    })
  },

  getDynList(page, limit, keyword = '') {
    return request({
      url: `api/dynamicCenter/getDynamicPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },
  getDynId(id) {
    return request({
      url: `api/dynamicCenter/getDynamicById/${id}`,
      method: 'get',
    })
  },

  // 1专科 2本科
  getMajList(page, limit, keyword = '', level = 0) {
    return request({
      url: `api/majorCenter/getMajorPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getMajId(id) {
    return request({
      url: `api/majorCenter/getMajorInfo/${id}`,
      method: 'get',
    })
  },

  getTeaList(page, limit, keyword = '') {
    return request({
      url: `api/teacherCenter/getTeacherPage/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },
  getTeaId(id) {
    return request({
      url: `api/teacherCenter/getTeacherById/${id}`,
      method: 'get',
    })
  },

  getCouList(page, limit, keyword = '') {
    return request({
      url: `api/courseCenter/getCoursePageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },
  getCouId(id) {
    return request({
      url: `api/courseCenter/getCourseById/${id}`,
      method: 'get',
    })
  },

  getEntList(page, limit, keyword = '') {
    return request({
      url: `api/entranceCenter/getEntrancePageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },
  getEntId(id) {
    return request({
      url: `api/entranceCenter/getEntranceById/${id}`,
      method: 'get',
    })
  },

  getHomeList(page, limit, keyword = '', level = 13) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getHomeId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getYxjjList(page, limit, keyword = '', level = 1) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getYxjjId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getSchList(page, limit, keyword = '') {
    return request({
      url: `api/schoolOffice/getOfficePageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },
  getSchId(id) {
    return request({
      url: `api/schoolOffice/getOfficeInfoById/${id}`,
      method: 'get',
    })
  },

  getYzzcList(page, limit, keyword = '', level = 2) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getYzzcId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getJgszList(page, limit, keyword = '', level = 4) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getJgszId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getBxlcList(page, limit, keyword = '', level = 3) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getBxlcId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getOveList(page, limit, keyword = '', level = 6) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getOveId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getCerList(page, limit, keyword = '', level = 7) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getCerId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getCousList(page, limit, keyword = '', level = 8) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getCousId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getTeacList(page, limit, keyword = '', level = 9) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getTeacId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getSeacList(page, limit, keyword = '', level = 12) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getSeacId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getInfList(page, limit, keyword = '', level = 23) {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getInfId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },

  getEntId(id) {
    return request({
      url: `api/entranceCenter/getEntranceById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getEntList(page, limit, keyword = '') {
    return request({
      url: `api/entranceCenter/getEntrancePageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },

  getAlwId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getAlwList(page, limit, keyword = '', level = '21') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getMxmkId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getMxmkList(page, limit, keyword = '', level = '18') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getXsydId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getXsydList(page, limit, keyword = '', level = '20') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getLqId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getLqList(page, limit, keyword = '', level = '14') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getZsId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getZsList(page, limit, keyword = '', level = '15') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getBannerId(id) {
    return request({
      url: `api/slideshowCenter/getSlideshowById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getBannerList(page, limit, keyword = '') {
    return request({
      url: `api/slideshowCenter/getSlideshowPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },

  getNewsId(id) {
    return request({
      url: `api/pressCenter/getPressById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getNewsList(page, limit, keyword = '') {
    return request({
      url: `api/pressCenter/getPressPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },

  getNotId(id) {
    return request({
      url: `api/noticeCenter/getNoticeById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getNotList(page, limit, keyword = '') {
    return request({
      url: `api/noticeCenter/getNoticePageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },

  getDynId(id) {
    return request({
      url: `api/dynamicCenter/getDynamicById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getDynList(page, limit, keyword = '') {
    return request({
      url: `api/dynamicCenter/getDynamicPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword },
    })
  },

  getSqpxId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getSqpxList(page, limit, keyword = '', level = '10') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getSxjdId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getSxjdList(page, limit, keyword = '', level = '11') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getZswdId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getZswdList(page, limit, keyword = '', level = '16') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getXkjsId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getXkjsList(page, limit, keyword = '', level = '17') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getSzdwId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getSzdwList(page, limit, keyword = '', level = '24') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getKfjyId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getKfjyList(page, limit, keyword = '', level = '25') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getDkdzId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getDkdzList(page, limit, keyword = '', level = '26') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getJypgId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getJypgList(page, limit, keyword = '', level = '19') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getDowId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
  //获取讲分页列表
  getDowList(page, limit, keyword = '', level = '22') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },

  getInfoList(page, limit, level, keyword = '') {
    return request({
      url: `api/alwaysCenter/getAlwaysPageList/${page}/${limit}`,
      method: 'get',
      params: { keyword, level },
    })
  },
  getInfoId(id) {
    return request({
      url: `api/alwaysCenter/getAlwaysById/${id}`,
      method: 'get',
    })
  },
}
