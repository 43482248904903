<template>
    <div class="lcright8">
      <div class="lcright-018" v-for="e of entranceList" :key="e.title">
          <div class="lcright-028">
              <div class="main-048 linknav nav-z8">
                  <router-link to="/homeguide">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">{{e.title}}</font>
              </div>
          </div>
          <div class="lcright-038">
                	<div class="zy_about8 nr-z28">
                    	<p><p v-html="e.content">
	<!-- <a href="../UploadFiles/files/2019082240592797.PDF">国开财资【2018】5号关于规范国家开放大学实验学院、行业学院收费标准的通知.PDF</a> -->
    </p>
</p>
                    </div>
                </div>
      </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            entranceList:[
               {
                 title: '1',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getEntList(){
      const res = await consult.getEntList(4, 1)
      console.log(res,'entrance')
      const list = res.data.data.list.records
      this.entranceList = list.map(entrance => {
        const {entranceTitle, entranceId, entranceContent} = entrance
        return {
          title: entranceTitle.substring(0,4),
          id: entranceId,
          content: entranceContent,
        }
      })
    },
  },
  mounted() {
    this.getEntList()
  }
  }
</script>

<style>
.lcright8 {
    float: left; 
    width:1000px;
    margin-top: 200px;
   }
.lcright-018 {margin-right:10px;}
.lcright-028 {border-bottom: 1px solid #dadada;
       height: 54px;
       position: relative;
       width: 1000px;
      }
.main-048 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z8 {color: #898989;font-size: 12px;}
.lcright-038 {margin: 10px 10px 81px 0;
       /* width: 1000px;
       position: relative; */
      }
.zy_about8 {line-height: 2em;
     }
.zy_about8 p{ 
     margin-bottom:10px;
     margin-left: auto;
     }
.nr-z28 {
  color: #353535;
  font-size: 13px;
  width: 1000px;
  }
</style>