<template>
    <div class="bxlc">
        <div class="lcright-02">
                 <div class="main-04 linknav nav-z">
                  <router-link to="/index">首页</router-link>&nbsp;&gt;&nbsp;学院概况&nbsp;&gt;&nbsp;<font color="blue">办学历程</font>
                  </div> 
              </div>
    <div v-for="b of bxlcList" :key="b.title" class="content">
        <div v-html="b.content" class="content-title" >
    </div>
    </div>
    </div>
</template>
<script>
import consult from '@/api/yssz/consult'
export default {
  data() {
   return {
    bxlcList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ],
   }
},
methods: {
    async getBxlcList(){
      const res = await consult.getBxlcList(1, 1)
      console.log(res,'bxlc')
      const list = res.data.data.list.records
      this.bxlcList = list.map(bxlc => {
        const {alwaysTitle,createTime, alwaysId, alwaysContent} = bxlc
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getBxlcList()
  }
};
</script>
<style lang="scss" scoped>
.bxlc{
    // width: 100%;
    // height: 493px;
    margin: -200px auto;
    }
    // .top{
    //    /* float: right;
    //    display: flex; */
    //    height: 60px;
    //    border-bottom: 1px solid #cdd1d4;
    //    width: 100%;
    //    .right-title{
    //        display: flex;
    //        float: right;
    //        margin-top: 25px;
    //        width: 100%;
    //        font-size: 15px;
    //     }
    // }
    .lcright-02 {border-bottom: 1px solid #dadada;height: 54px;position: relative;width: 725px;}
.main-04 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z {color: #898989;font-size: 12px;}
</style>
