<template>
    <div class="lcright_6">
      <div class="lcright-01_6">
          <div class="lcright-02_6">
              <div class="main-04_6 linknav nav-z_6">
                  <router-link to="/homeguidel">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">网上学习</font>
              </div>
          </div>
          <div class="lcright-03_6">
                	<div class="zy_about_6 nr-z2_6" v-for="h of homeList" :key="h.title">
                    	<p>
                            <p v-html="h.content">
	<!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 国家开放大学学生的学习模式是&ldquo;有支持的自主学习&rdquo;。学生可根据课程安排和课程目标制订适合自己的学习计划，通过在线观看视频、在线讨论、虚拟课堂、BBS答疑、社区讨论、论坛发帖等多种形式开展学习，同时，在教师指导下完成课程作业和课程考核。<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 部分课程直接通过在线学习平台即可完成学习和形考，这部分课程所占比例会越来越高。 -->
</p>
</p>
                    </div>
                </div>
      </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            img: '',
            homeList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getHomeList(){
      const res = await consult.getHomeList(4, 1)
      console.log(res,'home')
      const list = res.data.data.list.records
      this.homeList = list.map(home => {
        const {alwaysTitle, createTime, alwaysId, alwaysContent} = home
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getHomeList()
  }
  }
</script>

<style>
.lcright_6 {
    float: left; 
    width:1000px;
    margin-top: 200px;
   }
.lcright-01_6 {margin-right:10px;}
.lcright-02_6 {border-bottom: 1px solid #dadada;
       height: 54px;
       position: relative;
       width: 1000px;
      }
.main-04_6 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z_6 {color: #898989;font-size: 12px;}
.lcright-03_6 {margin: 10px 10px 81px 0;}
.zy_about_6 {line-height: 2em;}
.zy_about_6 p{ margin-bottom:10px;}
.nr-z2_6 {
          color: #353535;
          font-size: 13px;
          width: 1000px;
          }
</style>