<template>
    <div class="lcright_05">
      <div class="lcright-01_05">
          <div class="lcright-02_05">
              <div class="main-04_05 linknav nav-z_05">
                  <router-link to="/homeguidel">首页</router-link>&nbsp;&gt;&nbsp;<font color="blue">参加考试</font>
              </div>
          </div>
          <div class="lcright-03_05">
                	<div class="zy_about_05 nr-z2_05" v-for="h of homeList" :key="h.title">
                    	<p>
                            <p v-html="h.content">
	<!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 课程考核一般包括形成性考核（测评）和终结性考试（测评），课程考核要把形成性考核（简称形考）和终结性考试（简称终考或考试）有机地结合起来，综合测量和评价学生的学习行为、学习过程和学习成就。学生的课程考核总成绩由形考成绩和终考成绩合成计算并记录。<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 形考是学习过程中的测评活动，一般有阶段性学习测验、教学实践活动、专题讨论、小组学习、学习记录等基本形式，按照&ldquo;统一要求、分级管理、分层负责、指导（辅导）教师评阅&rdquo;的要求组织实施。<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 参加考终考前必须完成选课、学习和报考环节，缺少任何一个环节均不能参加终考。终考有闭卷、开卷或者半开卷考试，部分课程的考试可采用听力考试、面试（口试）或网上考试等方式。应根据课程的性质、特点，结合各种考试方式的特点和适用范围，以及考试工作的可操作性，适当选择考试方式。 -->
</p>
</p>
                    </div>
                </div>
      </div>
  </div>
</template>

<script>
import consult from '@/api/yssz/consult'
  export default {
    data() {
        return {
            img: '',
            homeList:[
               {
                 title: '1',
                 date: '2022',
                 content: '',
                 id: '',
               },
            ], 
       }
    },
  methods: {
    async getHomeList(){
      const res = await consult.getHomeList(3, 1)
      console.log(res,'home')
      const list = res.data.data.list.records
      this.homeList = list.map(home => {
        const {alwaysTitle, createTime, alwaysId, alwaysContent} = home
        return {
          title: alwaysTitle,
          date: createTime.split('T')[0],
          id: alwaysId,
          content: alwaysContent,
        }
      })
    },
  },
  mounted() {
    this.getHomeList()
  }
  }
</script>

<style>
.lcright_05 {
    float: left; 
    width:1000px;
    margin-top: 200px;
   }
.lcright-01_05 {margin-right:10px;}
.lcright-02_05 {border-bottom: 1px solid #dadada;
       height: 54px;
       position: relative;
       width: 1000px;
      }
.main-04_05 {height: 22px;line-height: 22px;position: absolute;right: 4px;text-align: right;top: 25px;width: 500px;z-index: 1;}
.nav-z_05 {color: #898989;font-size: 12px;}
.lcright-03_05 {margin: 10px 10px 81px 0;}
.zy_about_05 {line-height: 2em;}
.zy_about_05 p{ margin-bottom:10px;}
.nr-z2_05 {
           color: #353535;
           font-size: 13px;
           width: 1000px;
          }
</style>