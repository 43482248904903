<template>
  <div class="container">
    <div class="topbox">
      <div class="top widths">
        <!-- <div class="time">2022年11月11日 星期五</div> -->
        <div class="top_l fl">
          <img src="../assets/imgs/index/ruanjian.png" />
        </div>
        <div class="top_r fr">
          <div class="time">{{ nowDate + ' ' + nowTime + ' ' + nowWeek }}</div>
          <form name="" method="post" action="/sousuo">
            <div class="txt">
              <input
                v-model="searchCount"
                style="background: none"
                type="text"
                id="k"
                name="key"
                onclick="this.value=''"
                value="通知公告"
                onblur="if(this.value==''){this.value='通知公告'}"
              />
            </div>
            <input @click="searchHandle" class="btn" value="" />
          </form>
        </div>
      </div>
    </div>
    <div class="navbox">
      <div class="nav widths">
        <ul>
          <li>
            <router-link to="/index" class="menu-button"
              ><span class="menu-label">首页</span></router-link
            >
          </li>
          <li>
            <router-link to="/about" class="menu-button"
              ><span class="menu-label">学院概况</span></router-link
            >
          </li>
          <li>
            <router-link to="/xljy" class="menu-button"
              ><span class="menu-label">学历教育</span></router-link
            >
          </li>
          <li>
            <router-link to="/fxljy" class="menu-button"
              ><span class="menu-label">非学历教育</span></router-link
            >
          </li>
          <li>
            <router-link to="/zhaosheng" class="menu-button"
              ><span class="menu-label">招生支持服务</span></router-link
            >
          </li>
          <li>
            <router-link to="/jiaoxue" class="menu-button"
              ><span class="menu-label">教学支持教务</span></router-link
            >
          </li>
          <li>
            <router-link to="/cooperate" class="menu-button"
              ><span class="menu-label">新媒体</span></router-link
            >
          </li>
          <li>
            <router-link to="/download" class="menu-button"
              ><span class="menu-label">资料下载</span></router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchCount: '',
      nowDate: '', // 当前日期
      nowTime: '', // 当前时间
      nowWeek: '', // 当前星期
      dialogTableVisible: false,
    }
  },
  mounted() {
    this.currentTime()
  },
  methods: {
    currentTime() {
      setInterval(this.getDate, 500)
    },
    getDate() {
      var _this = this
      let yy = new Date().getFullYear()
      let mm = new Date().getMonth() + 1
      let dd = new Date().getDate()
      let week = new Date().getDay()
      let hh = new Date().getHours()
      let ms =
        new Date().getSeconds() < 10
          ? '0' + new Date().getSeconds()
          : new Date().getSeconds()
      let mf =
        new Date().getMinutes() < 10
          ? '0' + new Date().getMinutes()
          : new Date().getMinutes()
      if (week == 1) {
        this.nowWeek = '星期一'
      } else if (week == 2) {
        this.nowWeek = '星期二'
      } else if (week == 3) {
        this.nowWeek = '星期三'
      } else if (week == 4) {
        this.nowWeek = '星期四'
      } else if (week == 5) {
        this.nowWeek = '星期五'
      } else if (week == 6) {
        this.nowWeek = '星期六'
      } else {
        this.nowWeek = '星期日'
      }
      // _this.nowTime = hh + ":" + mf + ":" + ms;
      _this.nowDate = yy + '年' + mm + '月' + dd + '日'
    },
    searchHandle() {
      this.$router.push({
        path: '/searchAll',
        query: {
          q: this.searchCount,
        },
      })
      console.log(this.searchCount)
    },
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.getDate) {
      console.log('销毁定时器')
      clearInterval(this.getDate) // 在Vue实例销毁前，清除时间定时器
    }
  },
}
</script>

<style lang="scss">
.top.widths {
  position: relative;
}
.top_l.fl {
  position: relative;
  img {
    width: 337px;
    display: block;
  }
}
.time {
  margin-top: -20px;
  text-align: center;
  // font-size: 13px;
  // left:0px;
  // position:absolute;
}

.topbox,
.navbox,
.noticebox,
.con,
.footbox {
  min-width: 1000px;
}
.topbox {
  background: url(../assets/imgs/index/top.jpg) repeat-x;
}
.top,
.topbox {
  height: 100px;
}
.top_l {
  padding: 25px 0px 0px 15px;
}
.top_r {
  background: url(../assets/imgs/index/search2.jpg) no-repeat;
  width: 306px;
  height: 54px;
  margin: 30px 30px 0px 0px;
}
.widths {
  width: 1000px;
  margin: 0px auto;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
//   .time{
//         margin-top: 10px;
//       }
.txt {
  float: left;
  height: 32px;
  line-height: 32px;
  padding: 10px 5px 2px 45px;
  width: 182px;
}
.txt input {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  color: #999;
  font-size: 14px;
  border: 0 none;
  height: 32px;
  line-height: 32px;
  outline: medium none;
  width: 186px;
  font-family: '微软雅黑', Microsoft Yahei;
}
.btn {
  border: medium none;
  float: left;
  height: 32px;
  float: left;
  margin: 2px 0px 0px 3px;
  display: inline;
  overflow: hidden;
  width: 70px;
  cursor: pointer;
  background: none;
  position: relative;
  left: 50px;
  top: 6px;
}
input:focus {
  color: #000;
}
.navbox {
  background: url(../assets/imgs/index/nav1.jpg) repeat;
}
.navbox,
.nav {
  height: 40px;
  line-height: 40px;
}
.nav li {
  float: left;
  width: 111px;
  text-align: center;
  background: url(../assets/imgs/index/navline1.jpg) no-repeat left center;
  background-size: auto 50%;
}
.nav li:first-child {
  background: none;
}
.nav li a {
  display: block;
  color: #ffffff;
  font-size: 14px;
}
.nav li a:hover {
  color: blue;
}
</style>
